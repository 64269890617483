import {
  Box,
  makeStyles,
  Typography,
  Link,
  Theme,
  useMediaQuery,
} from "@material-ui/core"
import TimerIcon from '@material-ui/icons/Timer';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@material-ui/lab"
import moment from "moment-timezone"
import { useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom"

import { LogInOrSignUpIndependent } from "../../components/modal/LogInOrSignUpIndependent";
import { fonts } from "../../shared/styles"
import { theme } from "../../shared/theme";
import { AzpEntryEventHistoryItem } from "../../types.results.reignite";
import { EventCourseMetadata } from "../../utils/types";
import { isLoggedIn } from "../../utils/isLoggedIn";
import { getNumberWithSuffix } from "../../utils/getNumberWithSuffix";

import { useSharedStyles } from "./styles"
import { EventsHistoryInfoModal } from "./EventsHistoryInfoModal";

const useStyles = makeStyles<Theme, { isAnonymousView: boolean }>(({ breakpoints }) => ({
  connector: {
    backgroundColor: '#81D4FA',
    minHeight: '50px',
    width: '4px',
  },
  container: {
    background: '#fafafa',
    borderRadius: '8px',
    padding: '8px',

    [breakpoints.up('sm')]: {
      borderRadius: '12px',
      padding: '12px',
    }
  },
  contentContainer: {
    background: '#fff',
    border: '1px solid #eee',
    borderLeftWidth: '6px',
    borderRadius: '4px',
    padding: '4px 8px',

    [breakpoints.up('sm')]: {
      borderRadius: '8px',
      padding: '8px 16px',
    }
  },
  eventCourseName: {
    color: '#757575',
    fontFamily: fonts.bold.fontFamily,
    fontSize: '12px',
    letterSpacing: '0.1px',
    lineHeight: '16px',

    [breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '22px',
    },

    '& span': {
      color: '#9e9e9e',
      fontFamily: fonts.default.fontFamily,
    },
  },
  nextEventContent: {
    background: '#fff',
    border: '4px solid #16A9E1',
    borderLeftWidth: '8px',
    borderRadius: '4px',
    marginTop: '4px',
    padding: '8px 8px 36px',

    '& p': {
      filter: 'none',
    },
  },
  nextEventHeader: {
    textAlign: 'center'
  },
  nextEventWrapper: {
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 0,
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 8px',
    padding: 0,
    width: '220px',

    [breakpoints.up('sm')]: {
      width: '280px',
    },
  },
  placeholderLeftSide: {
    '& .MuiTimelineContent-root': {
      padding: '0 8px 0 0',

      [breakpoints.up('sm')]: {
        padding: '0 16px 0 0',
      }
    },

    '&::before': {
      padding: '0 0 0 8px',

      [breakpoints.up('sm')]: {
        padding: '0 0 0 16px',
      }
    },
  },
  placeholderRightSide: {
    '& .MuiTimelineContent-root': {
      padding: '0 0 0 8px',

      [breakpoints.up('sm')]: {
        padding: '0 0 0 16px',
      }
    },

    '&::before': {
      padding: '0 8px 0 0',

      [breakpoints.up('sm')]: {
        padding: '0 16px 0 0',
      }
    },
  },
  resultLink: {
    color: '#9e9e9e',
    fontFamily: fonts.default.fontFamily,
    fontSize: '12px',
    letterSpacing: '0.17px',
    lineHeight: '16px',
    textDecoration: 'underline',

    [breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
    }
  },
  resultTime: ({ isAnonymousView }) => ({
    color: '#424242',
    fontFamily: fonts.default.fontFamily,
    fontSize: '20px',
    lineHeight: '24px',
    margin: '4px 0 0',
    filter: isAnonymousView ? 'blur(5px)' : 'none',

    [breakpoints.up('sm')]: {
      fontSize: '24px',
      lineHeight: '32px',
    }
  }),
  timeIcon: {
    height: '14px',
    paddingBottom: '4px',
    width: '18px',

    [breakpoints.up('sm')]: {
      height: '18px',
      width: '22px',
    }
  },
  timelineDot: {
    backgroundColor: '#16A9E1',
    border: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
    color: '#fff',
    fontFamily: fonts.bold.fontFamily,
    fontSize: '14px',
    letterSpacing: '0.15px',
    lineHeight: '20px',
    padding: '8px 12px',
    whiteSpace: 'nowrap',
  },
  timelineRoot: {
    margin: 0,
    padding: 0,
  },
  titleWrapper: {
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    marginBottom: '4px',
  },
}))

type EventsHistoryProps = {
  entryFirstName: string | null
  eventCourseName: EventCourseMetadata['eventCourseName'] | null
  eventCourseStartDateTime: EventCourseMetadata['eventCourseStartDateTime'] | null
  eventsHistory: AzpEntryEventHistoryItem[]
  isEventsHistoryLoading: boolean
  isProcessedByInsights: boolean
  masterId: number
}

const EventsHistory = ({
  entryFirstName,
  eventCourseName,
  eventCourseStartDateTime,
  eventsHistory,
  isEventsHistoryLoading,
  isProcessedByInsights,
  masterId,
}: EventsHistoryProps) => {
  const isAnonymousView = !isLoggedIn()
  
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles({ isAnonymousView })
  const sharedStyles = useSharedStyles()

  const [isSignInOpen, setIsSignInOpen] = useState(false)

  const onClickTimelineBox = () => {
    if (!isLoggedIn()) {
      setIsSignInOpen(true)
    }
  }

  if (isEventsHistoryLoading) {
    return (
      <Box className={sharedStyles.paper}>
        <Box className={sharedStyles.skeletonTitle} />

        <Box className={sharedStyles.skeletonContent} />
      </Box>
    )
  }

  if (!eventCourseName || !eventCourseStartDateTime || !isProcessedByInsights) {
    return null
  }
  
  const startDate = moment(eventCourseStartDateTime.timeInMillis).tz(eventCourseStartDateTime.timeZone) as moment.Moment
  const daysToGo = startDate.diff(moment(), 'days')

  return (
    <Box className={sharedStyles.paper}>
      <Box className={styles.titleWrapper}>
        <Typography className={sharedStyles.title}>
          {entryFirstName || 'Racer'}'s History in this event
        </Typography>

        <EventsHistoryInfoModal />
      </Box>

      <Box className={styles.container} onClick={onClickTimelineBox}>
        <Timeline align='alternate' className={styles.timelineRoot}>
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot className={styles.nextEventWrapper}>
                <Typography
                  className={`${styles.timelineDot} ${styles.nextEventHeader}`}
                >
                  {getNumberWithSuffix(eventsHistory.length + 1)} Race
                </Typography>

                <Box className={styles.nextEventContent}>
                  <Typography className={styles.eventCourseName}>
                    {eventCourseName}{daysToGo >= 0 && <> - <span>Next Event</span></>}
                  </Typography>

                  <Typography className={styles.resultTime}>
                    {daysToGo < 0 && <>No Result</>}
                    {daysToGo === 0 && <>Today</>}
                    {daysToGo > 0 && <>{daysToGo} Day{daysToGo > 1 && 's'} to go</>}
                  </Typography>
                </Box>
              </TimelineDot>

              {eventsHistory.length > 0 && <TimelineConnector className={styles.connector} />}
            </TimelineSeparator>

            <TimelineContent />
          </TimelineItem>

          {eventsHistory.map((item, index) => (
            <TimelineItem key={item.athlinksEventCourseId} className={index % 2 === 0 ? styles.placeholderLeftSide : styles.placeholderRightSide}>
              <TimelineSeparator>
                <TimelineDot className={styles.timelineDot}>
                  {getNumberWithSuffix(eventsHistory.length - index)} Race
                </TimelineDot>

                {index !== eventsHistory.length - 1 && <TimelineConnector className={styles.connector} />}
              </TimelineSeparator>

              <TimelineContent style={{ textAlign: 'left' }}>
                <Box className={styles.contentContainer}>
                  <Typography className={styles.eventCourseName}>
                    {item.raceName}
                  </Typography>

                  <Typography className={styles.eventCourseName}>
                    <span>{moment(item.eventStartDate).fromNow()}</span>
                  </Typography>

                  <Typography className={styles.resultTime}>
                    {isAnonymousView ? '00:00' : item.finishTime}<TimerIcon className={styles.timeIcon} />
                  </Typography>

                  <Link
                    component={ReactRouterLink}
                    to={`/event/${masterId}/results/Event/${item.athlinksEventId}/Course/${item.athlinksEventCourseId}/Bib/${item.bib}`}
                    className={styles.resultLink}
                  >
                    View {moment(item.eventStartDate).format('YYYY')} results
                  </Link>
                </Box>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Box>

      <LogInOrSignUpIndependent
        closeAction={() => setIsSignInOpen(false)}
        isMobile={isMobile}
        open={isSignInOpen}
      />
    </Box>
  )
}

export { EventsHistory }