import convertPaceUnitsToPaceType from './convertPaceUnitsToPaceType'
import convertPaceUnitsToTimeUnits from './convertPaceUnitsToTimeUnits'
import convertTimeUnitsToOldTimeUnit from './convertTimeUnitsToOldTimeUnit'
import convertPaceUnitsToDistanceUnits from './convertPaceUnitsToDistanceUnits'
import { IntervalMetadata } from './types'
import { DistanceUnit, IPace, PaceType } from '@eventops/athlinks-results-model'
import roundUpToTheNearestSecond from './roundUpToTheNearestSecond'
import { convertPace, UnitType } from '@eventops/athlinks-lib-units'
import convertPaceDistanceUnit from './convertPaceDistanceUnit'

export default function calculatePace({
  timeInMillis,
  unitType,
  interval
}: {
  timeInMillis: number
  unitType: UnitType
  interval?: IntervalMetadata
}) {
  if (!interval?.paceUnits || interval?.paceUnits === 'none' ) {
    return { value: '--:--', label: '' }
  }

  const pace: IPace = {
    distance: {
      distanceInMeters: interval.distance,
      distanceUnit: convertPaceUnitsToDistanceUnits(interval.paceUnits) as DistanceUnit
    },
    time: {
      timeInMillis: roundUpToTheNearestSecond(timeInMillis),
      timeUnit: convertTimeUnitsToOldTimeUnit(
        convertPaceUnitsToTimeUnits(interval.paceUnits)
      )
    },
    paceType: convertPaceUnitsToPaceType(interval.paceUnits) as PaceType
  }

  return convertPace(convertPaceDistanceUnit(pace, unitType))
}
