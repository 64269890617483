import { MapTrackingState } from '../model/mapTrackingState'
import { MapTrackingActionTypes } from '../actions/types'

const mapTrackingReducer = (state = MapTrackingState, action) => {
  if (action.type === MapTrackingActionTypes.MAP_CHECK) {
    return state
  }

  if (action.type === MapTrackingActionTypes.MAP_CHECK_COMPLETE) {
    return {
      ...state,
      mapPresent: true,
    }
  }

  if (action.type === MapTrackingActionTypes.MAP_CHECK_ERROR) {
    return {
      ...state,
      mapPresent: false,
    }
  }

  return state
}

export default mapTrackingReducer
