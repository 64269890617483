import { Theme, makeStyles } from '@material-ui/core';

import { colors } from '../../shared/styles';

const useStyles = makeStyles<Theme>(({ palette, breakpoints }) => ({
  aboutPageContent: {
    display: 'flex',
    gap: '48px',
    padding: '12px',

    [breakpoints.up('sm')]: {
      padding: '12px 24px 36px',
    }
  },
  aboutPageSummaryWrapper: {
    borderBottom: `1px solid ${palette.grey[100]}`
  },
  aboutPageDistancesSection: {
    display: 'flex',
    gap: '16px',
    marginTop: '12px',

    '& > div': {
      flex: '1 1 auto',
      maxWidth: '50%',
    },

    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      gap: '12px',
    }
  },
  aboutPageDistancesSectionLeftSide: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  aboutPageMobileSection: {
    borderTop: `1px solid ${palette.grey[100]}`,
    display: 'block',
    padding: '12px 0 24px 0',
    margin: '0 12px',

    [breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  aboutPageDesktopSection: {
    display: 'none',

    [breakpoints.up('sm')]: {
      display: 'block',
      flex: 0,
    }
  },
  aboutPageCourseSectionWrapper: {
    [breakpoints.up('sm')]: {
      borderTop: `1px solid ${palette.grey[100]}`,
      padding: '12px 0 36px',
      margin: '0 24px',
    }
  },
  aboutPageStickyFooter: {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    zIndex: 99,
    backgroundColor: 'white',
    borderTop: `1px solid ${colors.greyLine}`,
    padding: '10px',

    [breakpoints.up('sm')]: {
      display: 'none',
    }
  },
  masterPageWrapper: {
    backgroundColor: colors.lightGrey,
    WebkitTouchCallout:'none',
  },
  masterPageContainer: {
    borderBottom: `1px solid ${palette.grey[100]}`,
    margin: '45px auto 0',
    maxWidth: '960px',
    width: '100%',

    [breakpoints.up('sm')]: {
      margin: '50px auto 0',
      paddingTop: '25px',
    }
  },
}));

export { useStyles };
