import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {ControlFactory} from '../../utils/ControlFactory';
import { Button } from '../../components/shared/Button';
import {inIframe} from '../../utils/inIframe';
import { trackGoogleEvent } from '../../utils/googleEvents';
import _ from 'lodash';
import i18n from '../../utils/i18n';
import { LogInOrSignUpIndependent } from './LogInOrSignUpIndependent';

class LogInOrSignUpComponent extends Component {
  static propTypes = {
    t: PropTypes.func,
    firstSlideType: PropTypes.string, // 'follow-friends' | 'claim-results' | 'add-to-favorites'
  };

  state = {
    open: false,
    control: {}
  };

  componentDidUpdate(prevProps) {
    if(this.props.claimStatus !== prevProps.claimStatus ||
      this.props.isClaimed !== prevProps.isClaimed ||
      this.props.disabled !== prevProps.disabled ||
      this.props.alreadyClaimedInCourse !== prevProps.alreadyClaimedInCourse ||
      this.props.claimOverride !== prevProps.claimOverride) {
      this.setState({
        control: this.getControl(true)
      });
    }
  }

  /**
   * Return modal object. Will rebuild if modal doesn't exist for current locale
   * @return {*}
   */
  getControl = (regen = false) => { //TODO: ASK about lc
    const {
      isMobile
    } = this.props;
    let {control} = this.state;
    const lc = i18n.language;
    if(!control[lc] || regen) {
      control = _.assign({}, control, {[lc]: ControlFactory.get(this, {isMobile})});
    }
    return control;
  };

  handleRequestClose = () => {
    this.setState({
      open: false
    });
  };

  handleTouchTap = () => {
    this.setState({
      open: true
    });
  };

  render() {
    const {
      isMobile,
      firstSlideType,
      containerStyle = {display: 'contents', width:'100%'}
    } = this.props;

    const lc = i18n.language;
    return (
      <span id='log-in-or-sign-up' style={containerStyle}>
        <LogInOrSignUpIndependent
          firstSlideType={firstSlideType}
          title={null}
          separator={false}
          open={this.state.open}
          closeAction={this.handleRequestClose}
          isMobile={isMobile}
        />
        {this.state.control[lc] || this.getControl()[lc]}
      </span>
    );
  }
}

export const LogInOrSignUp = withTranslation()(LogInOrSignUpComponent);

export const SignUp = ({id, style = {}}) => {
  return (
    <a
      id={id}
      onClick={() => trackGoogleEvent('Authentication', 'Click', 'Sign Up')}
      href={window.kc.createRegisterUrl()}
      target={inIframe() ? 'signup' : null}
    >
      <Button text={'SIGN UP'} style={style} />
    </a>
  )
};
