import React from 'react';
import { styles } from './styles';
import { Link } from 'react-router-dom';
import { getResultsUrlForBib } from '../../utils/resultsHelpers';
import { getCourseDetail } from '../../utils/ERPUtil';
import { suggestionText } from './suggestionText';
import { hasRole } from '../../utils/isLoggedIn';
import { capitalize, size } from 'lodash';

/**
 * results - render:
 *  {bib} {name}
 *  {gender} {age} | {courseName}
 */

export function TypeaheadOption({
  option,
  value,
  isFocused,
  isHovered,
  isSelected,
  ignorePrivacySettings,
  isKiosk = false,
  eventMetadata,
}: {
  eventMetadata: {
    eventCourseMetadata: any[]
  }
  ignorePrivacySettings: boolean
  isFocused: boolean
  isHovered: boolean
  isKiosk: boolean
  isSelected: boolean
  option: {
    age: number
    azp?: string
    azpEntryId?: number
    azpEventId?: number
    azpTeamEntryId?: number
    bib: string
    entryId: number
    entryType?: 'INDIVIDUAL' | 'MEMBER' | 'TEAM'
    eventCourseId: number
    eventId: number
    gender: string
    masterEventId: number
    showPersonalData?: boolean
    ShowPersonalData?: boolean
    team?: string
    teamType?: string
  }
  value: string
}) {

  const {
    age, azp, azpEntryId, azpEventId, azpTeamEntryId, bib, entryId, eventCourseId, eventId, gender, masterEventId, team, teamType, entryType
  } = option

  const notPrivate = ignorePrivacySettings || option.ShowPersonalData || option.showPersonalData

  const hasAzp = !!azp && (!!azpEntryId || !!azpTeamEntryId) && !!azpEventId
  // TODO: remove role check when arp is available for all
  const useAzpRoute = hasAzp && hasRole('alpha-arp')

  const resultLink = getResultsUrlForBib(eventId, eventCourseId, bib, masterEventId, entryId)
  const userLink = useAzpRoute ? `/azp/${azp}/event/${azpEventId}/entry/${azpTeamEntryId || azpEntryId}` : resultLink

  const eventCourseDetail = getCourseDetail(eventCourseId, eventMetadata)
  const { eventCourseName } = eventCourseDetail

  if (!!size(option) && notPrivate) {
    return (
      <Link to={userLink}>
        <div
          className='row mx-0 dropdown-option'
          //@ts-ignore
          style={styles.typeaheadOptionContainer(isFocused, isHovered, isSelected, isKiosk)}
        >
          <div className='col-12 px-0 suggestion-text'>
            {suggestionText({ searchQuery: value, res: option, isFocused })}
          </div>
          <div className='col-12 px-0' style={styles.typeaheadOptionSpanWrap}>
            <span style={styles.typeaheadOptionGender}>
            {
                entryType && ['TEAM', 'MEMBER'].includes(entryType)
                  ? entryType === 'MEMBER'
                    ? team
                    : `${capitalize(teamType) || ''}${teamType ? ' ' : ''}Team`
                  :`${gender} ${age || ''}`
              }
            </span>
            <span>
              {eventCourseName}
            </span>
          </div>
        </div>
      </Link>
    );
  }

  return null;
}
