import { Button, Dialog, IconButton, Link, Typography } from '@material-ui/core'
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';

import { configs } from '../../configs';

import { useEventsHistoryInfoModalStyles } from './styles';

const EventsHistoryInfoModal = () => {
  const styles = useEventsHistoryInfoModalStyles()

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const toggleDialogOpenStatus = () => {
    setIsDialogOpen((prev) => !prev)
  }

  return (
    <div>
      <Button className={styles.aboutButton} size='small' onClick={toggleDialogOpenStatus}>
        About
        <InfoOutlined fontSize='small' />
      </Button>

      <Dialog open={isDialogOpen} PaperProps={{ className: styles.dialogPaper }}>
        <div className={styles.dialogContainer}>
          <div className={styles.dialogHeader}>
            <IconButton size='small' onClick={toggleDialogOpenStatus}>
              <CloseIcon />
            </IconButton>
          </div>

          <Typography className={styles.dialogTitle}>How do we gather Event History?</Typography>

          <Typography className={styles.dialogText}>This <span>Athlete's History in Event</span> was created by Athlinks' Insights. Insights goes beyond claimed Results to create a complete profile of an Athlete's entire History in a given Event. If you see issues, please see this linked KnowledgeBase article.</Typography>

          <div className={styles.dialogFooter}>
            <img
              alt='Athlinks Insights Logo'
              src={`${configs.cdnUrl}/svg/insights_logo.svg`}
            />

            <Link
              href='https://athlinks.zendesk.com/hc/en-us/articles/29723522277911-How-do-we-gather-Event-History'
              rel="noopener noreferrer"
              target='_blank'
            >
              <Button className={styles.viewArticleButton} variant='outlined'>View Article</Button>
            </Link>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export { EventsHistoryInfoModal }