import { makeStyles, Theme } from "@material-ui/core";

import { colors, fonts, loadingDark } from "../../shared/styles";

const useSharedStyles = makeStyles(() => ({
  paper: {
    background: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 1px 4px 0px rgba(149, 157, 165, 0.25)',
    padding: '12px 12px 20px 12px',
  },
  title: {
    color: '#424242',
    fontFamily: fonts.bold.fontFamily,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.15px',
    textTransform: 'uppercase',
  },
  skeletonTitle: {
    ...loadingDark,
    height: '20px',
    margin: '6px 0',
    width: '100%',
  },
  skeletonContent: {
    ...loadingDark,
    height: '14px',
    margin: '8px 0',
    width: '100%',
  },
}))

const useMainStyles = makeStyles(({ breakpoints, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '8px',

    [breakpoints.up('sm')]: {
      flexDirection: 'row',
      padding: '16px 0'
    }
  },
  leftSide: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '16px',

    [breakpoints.up('sm')]: {
      flex: '0 0 300px',
    }
  },
  rightSide: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: '16px',
  },
}))

const useAthleteCardStyles = makeStyles(({ breakpoints, palette }) => ({
  bibContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 0 0',

    [breakpoints.up('sm')]: {
      margin: '24px auto 0',
    }
  },
  bibLabel: {
    color: '#9e9e9e',
    fontFamily: fonts.heavy.fontFamily,
    fontSize: '12px',
    letterSpacing: '0.17px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    
    [breakpoints.up('sm')]: {
      fontSize: '14px',
      lineHeight: '20px',
    }
  },
  bibNumber: {
    color: '#424242',
    fontFamily: fonts.heavy.fontFamily,
    fontSize: '14px',
    letterSpacing: '0.17px',
    lineHeight: '20px',
  },
  bibNumberBox: {
    alignItems: 'center',
    background: 'linear-gradient(180deg, #16a9e1 0%, #16a9e1 4px, #fff 4px, #fff 100%)',
    border: '1px solid #E0E0E0',
    borderTop: 'none',
    borderRadius: '0 0 2px 2px',
    display: 'flex',
    justifyContent: 'center',
    padding: '8px 10px 4px',
    width: 'fit-content',
  },
  body: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',

    [breakpoints.up('sm')]: {
      display: 'block',
      margin: '24px 0 0',
    },
  },
  container: {
    alignItems: 'center',
    borderRadius: '16px',
    display: 'flex',
    gap: '16px',
    padding: '20px 24px',
    width: '100%',

    [breakpoints.up('sm')]: {
      flexDirection: 'column',
      gap: 0,
      justifyContent: 'center',
      padding: '24px',
    }
  },
  details: {
    color: '#9e9e9e',
    fontFamily: fonts.heavy.fontFamily,
    fontSize: '14px',
    letterSpacing: '0.17px',
    lineHeight: '20px',
  },
  detailsDivider: {
    margin: '0 8px',
  },
  detailsContainer: {
    justifyContent: 'center',
    display: 'flex',
  },
  displayName: {
    color: '#424242',
    fontFamily: fonts.heavy.fontFamily,
    fontSize: '20px',
    letterSpacing: '0.15px',
    lineHeight: '32px',
    textAlign: 'center',
  },
  skeletonDisplayName: {
    ...loadingDark,
    height: '24px',
    margin: '4px auto',
    width: '80%',
  },
  skeletonDetails: {
    ...loadingDark,
    height: '14px',
    margin: '3px auto',
    width: '60%',
  },
}))

const useAthletePhotoStyles = makeStyles<Theme, {
  isMember: boolean,
  isSkeletonActive: boolean
  size: { mobile: number; desktop: number }
}>(({ breakpoints, palette }) => ({
  container: {
    alignItems: 'center',
    background: '#eee',
    borderRadius: '50%',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    width: '100%',
  },
  initials: ({ isSkeletonActive, size }) => ({
    ...fonts.bold,
    color: colors.textColor,
    display: isSkeletonActive ? 'none' : 'block',
    fontSize: `${size.mobile / 2.5}px`,
    lineHeight: `${size.mobile / 2.5 + 8}px`,

    [breakpoints.up('sm')]: {
      fontSize: `${size.desktop / 2.5}px`,
      lineHeight: `${size.desktop / 2.5 + 8}px`,
    }
  }),
  picture: ({ isSkeletonActive }) => ({
    display: isSkeletonActive ? 'none' : 'block',
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  }),
  skeleton: {
    height: '100%',
    width: '100%',
    ...loadingDark,
  },
  wrapper: ({ isMember, size }) => ({
    border: `4px solid ${isMember ? palette.brandColor : '#9E9E9E'}`,
    borderRadius: '50%',
    height: size.mobile,
    padding: '4px',
    width: size.mobile,

    [breakpoints.up('sm')]: {
      height: size.desktop,
      width: size.desktop,
    }
  }),
}))

const useEventCardStyles = makeStyles(() => ({
  dateDetails: {
    color: '#9E9E9E',
    fontFamily: fonts.heavy.fontFamily,
  },
  locationDetails: {
    color: '#757575',
    fontFamily: fonts.bold.fontFamily,
  },
  detailsContainer: {
    alignItems: 'center',
    display: 'flex',

    '& p': {
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.17px',
    }
  },
  detailsDivider: {
    margin: '0 8px',
  },
  eventName: {
    fontSize: '18px',
    lineHeight: '24px',
  },
  title: {
    textTransform: 'none',
    fontFamily: fonts.heavy.fontFamily,
  }
}))

const useEventsHistoryInfoModalStyles = makeStyles(() => ({
  aboutButton: {
    color: '#0000008A',
    fontFamily: fonts.bold.fontFamily,
    fontSize: '14px',
    letterSpacing: '0.4px',
    textAlign: 'center',
    textTransform: 'none',

    '& svg': {
      marginLeft: '4px',
    }
  },
  dialogContainer: {
    padding: '12px 20px 20px',
  },
  dialogFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dialogPaper: {
    margin: '8px',
  },
  dialogTitle: {
    color: '#000000DE',
    fontFamily: fonts.bold.fontFamily,
    fontSize: '20px',
    lineHeight: '32px',
    margin: '4px 0',
  },
  dialogText: {
    color: '#616161',
    fontFamily: fonts.default.fontFamily,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',

    '& span': {
      fontFamily: fonts.bold.fontFamily,
      fontStyle: 'italic',
    }
  },
  viewArticleButton: {
    borderColor: '#16A9E180',
    color: '#16A9E1',
    fontFamily: fonts.default.fontFamily,
    fontSize: '14px',
    letterSpacing: '0.4px',
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
}))

const useRaceCardStyles = makeStyles(({ breakpoints }) => ({
  container: {
    padding: '12px 20px 20px 12px',

    [breakpoints.up('sm')]: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '14px 36px 22px 18px',
    }
  },
  title: {
    fontFamily: fonts.heavy.fontFamily,
    fontSize: '24px',
    lineHeight: '36px',
    textTransform: 'none',
  },
  details: {
    color: '#9E9E9E',
    fontFamily: fonts.heavy.fontFamily,
    fontSize: '14px',
    letterSpacing: '0.17px',
    lineHeight: '20px',

    '& span': {
      margin: '0 8px',
    }
  },
  regLink: {
    marginTop: '4px',

    '& a': {
      color: '#16a9e1',
      fontFamily: fonts.bold.fontFamily,
      fontSize: '14px',
      letterSpacing: '0.17px',
      lineHeight: '20px',
      marginTop: '4px',
    },

    [breakpoints.up('sm')]: {
      marginTop: 0,
    }
  }
}))

const useDivisionsCardStyles = makeStyles(({ breakpoints }) => ({
  divisionAthletesCount: {
    borderBottom: '1px solid #E0E0E0',
    color: '#424242',
    fontFamily: fonts.bold.fontFamily,
    fontSize: 24,
    letterSpacing: '0.4px',
    lineHeight: '24px',
    margin: '6px 0 8px',
    paddingBottom: '14px',
    textTransform: 'uppercase',
  },
  divisionAwardsContainer: {
    marginBottom: '8px',
  },
  divisionDivider: {
    background: '#E0E0E0',
    margin: '8px 0',
  },
  divisionItem: {
    background: '#FAFAFA',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
  },
  divisionLink: {
    marginTop: 'auto',

    '& a': {
      color: '#0277BD',
      fontFamily: fonts.default.fontFamily,
      fontSize: 12,
      letterSpacing: '0.17px',
      lineHeight: '16px',
    }
  },
  divisionName: {
    alignItems: 'center',
    background: '#FFF',
    border: '1px solid #E0E0E0',
    borderRadius: '2px',
    marginBottom: '8px',
    minWidth: '84px',
    padding: '4px 10px',
    width: 'fit-content',

    '& p': {
      color: '#424242',
      fontFamily: fonts.bold.fontFamily,
      fontSize: 14,
      letterSpacing: '0.4px',
      lineHeight: '24px',
      textAlign: 'center',
      textTransform: 'uppercase',
    }
  },
  divisionSectionTitle: {
    color: '#424242',
    fontFamily: fonts.default.fontFamily,
    fontSize: 12,
    letterSpacing: '0.4px',
    lineHeight: '24px',
    textTransform: 'uppercase',
  },
  itemsContainer: {
    display: 'grid',
    gap: '12px',
    gridTemplateColumns: 'repeat(2, 1fr)',
    marginTop: '4px',

    [breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    }
  },
  rankTypeContainer: {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',
  },
  rankTypeLabel: {
    color: '#757575',
    fontFamily: fonts.default.fontFamily,
    fontSize: 10,
    letterSpacing: '0.17px',
    lineHeight: '14px',
  },
  rankTypeChip: {
    borderColor: '#757575',
    fontSize: '10px',
    lineHeight: '18px',
    height: 'auto',
    color: '#757575',
  }
}))

export {
  useMainStyles,
  useAthleteCardStyles,
  useAthletePhotoStyles,
  useDivisionsCardStyles,
  useEventCardStyles,
  useEventsHistoryInfoModalStyles,
  useRaceCardStyles,
  useSharedStyles,
}