import {textStyles} from '../shared/megabloks-styles';

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  gridList: {
    width: '100%',
    overflowY: 'auto',
    textAlign: 'left'
  },
  centering: {
    top: '50%',
    left: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
    position: 'absolute'
  },
  lightbox: {
    shield: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
      zIndex: 99999
    },
    container: {
      position: 'absolute',
      left: 16,
      right: 16,
      top: 16,
      bottom: 16,
      backgroundColor: 'rgba(0,0,0,0.25)'
    },
    leftScrollButtonContainer: {
      left: 0,
      top: 0,
      bottom: 132,
      width: 100,
      position: 'absolute'
    },
    leftScrollButton: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 100,
      height: 100
    },
    leftScrollButtonIcon: {
      width: 80,
      height: 80
    },
    imageContainer: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      position: 'absolute',
      top: 60,
      bottom: 132,
      left: 100,
      right: 100
    },
    image: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      position: 'absolute',
      top: 0,
      bottom: 24,
      left: 24,
      right: 24
    },
    actionButtonContainer: {
      position: 'absolute',
      right: 24,
      top: 0,
    },
    actionButton: {
      width: 24,
      height: 24,
      padding: 0,
    },
    actionButtonIcon: {
      width: 16,
      height: 16
    },
    label: Object.assign({}, textStyles.subhead1Grey, {
      left: 100,
      top: 24,
      right: 100,
      height: 36,
      overflow: 'hidden',
      verticalAlign: 'middle',
      position: 'absolute',
      backgroundColor: 'rgba(0,0,0,0.5)',
      color: 'white',
      padding: '8px 24px',
      boxSizing: 'border-box',
    }),
    videoContainer: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      position: 'absolute',
      top: 60,
      bottom: 132,
      left: 100,
      right: 100
    },
    video: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      maxWidth: '800px',
      transform: 'translateX(-50%) translateY(-50%)',
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    closeButton: {
      position: 'absolute',
      top: 16,
      right: 16,
      width: 50,
      height: 50
    },
    closeButtonIcon: {
      width: 30,
      height: 30,
      WebkitFilter: 'drop-shadow(16px 16px 20px black)',
      MozFilter: 'drop-shadow(16px 16px 20px black)',
      filter: 'drop-shadow(16px 16px 20px black)'
    },
    rightScrollButtonContainer: {
      right: 0,
      top: 0,
      bottom: 132,
      width: 100,
      position: 'absolute'
    },
    rightScrollButton: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 100,
      height: 100
    },
    rightScrollButtonIcon: {
      width: 80,
      height: 80
    },
    thumbContainer: {
      position: 'absolute',
      boxSizing: 'border-box',
      padding: 8,
      left: 16,
      right: 16,
      bottom: 16,
      height: 100,
      overflow: 'scroll',
      whiteSpace: 'nowrap',
      textAlign: 'center'
    },
    thumb: {
        width: 84,
        height:84,
        display: 'inline-block',
        margin: '0 16px',
        boxSizing: 'border-box',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        borderColor: 'white',
        borderStyle: 'solid',
        cursor: 'pointer'
      }
  },
  grid: {
    imageTile: (isCertificate = false) => ({
      width: '100%',
      height: '100%',
      position: 'relative',
      transform: 'translateY(-50%)',
      top: '50%',
      cursor: 'pointer',
      backgroundRepeat: 'no-repeat',
      backgroundSize: isCertificate ? 'contain' : 'cover',
      backgroundPosition: 'center top',
      overflow: 'hidden'
    }),
    tileImage: {
      width: '100%',
      height: 'auto',
      visibility: 'hidden',
      overflow: 'hidden'
    },
    playButton: {
      position: 'absolute',
      transform: 'translateY(-50%) translateX(-50%)',
      top: '50%',
      left: '50%',
      width: 80,
      height: 80,
      cursor: 'hand'
    },
    moreButton: Object.assign({}, textStyles.subhead1Grey, {
      backgroundColor: 'rgba(0,0,0,0.5)',
      color: 'white',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      fontSize: '5em',
      verticalAlign: 'middle',
      textAlign: 'center',
      cursor: 'hand'
    }),
    moreTileContainer: {
      position: 'relative',
      display: 'block',
      height: '100%',
      overflow: 'hidden'
    },
    moreTileEffects: {
      filter: 'blur(10px)',
      WebkitFilter: 'blur(10px)',
      MozFilter: 'blur(50px)'
    },
    titleBackground: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 10%,rgba(0,0,0,0) 100%)'
  },
  mobileLightbox: {
    container: {
            position: 'absoloute',
            top: 16,
            bottom: 16,
            left: 16,
            right: 16
          },
    image: {
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    },
    rightScrollButton: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 10,
      width: 60,
      height: 60,
      boxSizing: 'border-box',
      padding: 0
    },
    rightScrollButtonIcon: {
      width: 60,
      height: 60,
      padding: 0
    },
    leftScrollButton: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: 10,
      width: 60,
      height: 60,
      boxSizing: 'border-box',
      padding: 0
    },
    leftScrollButtonIcon: {
      width: 60,
      height: 60,
      padding: 0
    },
    closeButton: {
      position: 'absolute',
      top: 16,
      right: 16,
      width: 60,
      height: 60,
      boxSizing: 'border-box',
      padding: 0
    },
    closeButtonIcon: {
      width: 60,
      height: 60,
      padding: 0
    },
    label: Object.assign({}, textStyles.body1Dark, {
      position: 'absolute',
      bottom: '2em',
      color: 'white',
      lineHeight: 0,
      width: '100%',
      textAlign: 'center',
      filter: 'drop-shadow(16px 16px 16px black)'
    }),
    video: {
      maxWidth: '100%',
      maxHeight: '100%',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)'
    },
  }
};

export default styles;
