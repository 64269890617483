import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import nl from './nl.json'
import ru from './ru.json'

const locales = {
  en,
  es,
  fr,
  nl,
  ru,
}

export default locales