import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) => ({
  infoText: {
    color: palette.brandColor,
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textDecoration: 'underline',
  },
  wrapper: {
    fontSize: 18,
    paddingTop: '3em',
    textAlign: 'center',
    width: '100%',
  }
}))

export default useStyles