import { Trans, useTranslation } from 'react-i18next'
import React, { CSSProperties } from 'react'
import { summaryStyles } from './styles'
import { Weather } from '../../weather/Weather'
import _ from 'lodash'
import { UnitType } from '@eventops/athlinks-lib-units'
import { styles } from './styles'

export const RaceSummary = ({
  distance,
  unitType,
  startTime,
  event,
  altTime = '',
  isGunTime = false,
  thirdParty
}: {
  distance: string
  unitType: string
  startTime: string[]
  event: any
  altTime: string
  isGunTime: boolean
  thirdParty: boolean
}) => {
  const { t } = useTranslation()
  const alternateTimeMessage = isGunTime ? t('Chip time') : t('Gun time')
  const timeStart = _.get(startTime, '[0]', '')
  const timePeriod = _.get(startTime, '[1]', '')
  const timeZone = _.get(startTime, '[2]', '')
  const distanceLabel = unitType === UnitType.METRIC ? t('Kilometers') : t('Miles')
  const styleLabelColumn = summaryStyles.labelColumn as CSSProperties

  return (
    <div style={summaryStyles.summaryContainer}>
      <div style={{ ...styles.cardSectionTitle, textTransform: 'uppercase' }}>
        {t('Race Summary')}
      </div>
      <div>
        {
          startTime && thirdParty &&
          <div style={summaryStyles.row}>
            <div className='col-5 px-0' style={styleLabelColumn}>
              {
                isGunTime
                  ? t('Gun Start Time')
                  : t('Chip Start Time')
              }
            </div>
            <div className='col-7' style={summaryStyles.valueColumn}>
              {timeStart}
              <span style={summaryStyles.valueUnits}>
                {` ${timePeriod} ${timeZone}`}
              </span>
            </div>
          </div>
        }
        <div style={summaryStyles.row}>
          <div className='col-5 px-0' style={styleLabelColumn}>
            {t('Distance')}
          </div>
          <div className="col-7" style={summaryStyles.valueColumn}>
            <Trans>
              {{distance}}
              <span style={summaryStyles.valueUnits}> {distanceLabel}</span>
            </Trans>
          </div>
        </div>
        {
          altTime && altTime !== '0:00' && thirdParty &&
          <div style={summaryStyles.row}>
            <div className='col-5 px-0' style={styleLabelColumn}>
              {alternateTimeMessage}
            </div>
            <div className='col-7' style={summaryStyles.valueColumn}>
              {altTime}
            </div>
          </div>
        }
        <div style={summaryStyles.lastRow}>
          <div className='col-5 px-0' style={styleLabelColumn}>
            {t('Weather')}
          </div>
          <div className='col-7' style={Object.assign({}, summaryStyles.valueColumn, {lineHeight: '2em'})}>
            <Weather event={event} type='resultsSummary'/>
          </div>
        </div>
      </div>
    </div>
  )
}
