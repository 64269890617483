import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie'
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import EmailIcon from 'react-icons/lib/io/email';
import SuccessIcon from 'react-icons/lib/io/android-checkmark-circle'
import {connectStream} from '../../lib/bastetjs/utils/connectStream';
import { configs } from '../../configs';
import { getResultsUrlForBib } from '../../utils/resultsHelpers';
import { colors } from '../../shared/styles';
import { styles } from './styles';
import { validateEmail } from '../../shared/util';
import { sendKioskEmail, getKioskEmailStream, resetKioskEmailStream } from '../../data/KioskStreams';
import SendingSpinner from '../../json/sending_spinner.json';
import { PrivacyPolicyInPage } from '../../components/control/PrivacyPolicyInPage';
import { TermsOfServiceInPage } from '../../components/control/TermsOfServiceInPage';
import _ from 'lodash';
import { IndividualKioskPrint } from './IndividualKioskPrint';
import QRCode from 'qrcode'
import {trackGoogleEvent} from  '../../utils/googleEvents';
import RaisedButton from '../../components/button/RaisedButton';
import isOnCourse from '../../utils/isOnCourse'
import hasNoResult from '../../utils/hasNoResult'

export class IndividualKioskShareComponent extends Component {
  static propTypes = {
    masterEvent: PropTypes.object,
    result: PropTypes.object,
    kioskEmail: PropTypes.object,
    t: PropTypes.func
  };

  state = {
    emailInputText: '',
    emailStatus: {},
    sendingEmail: false
  };

  componentWillUnmount() {
    resetKioskEmailStream();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      result = {},
      t
    } = this.props;
    const { entryId = 0 } = result;
    const nextResult = nextProps.result || {};

    if(nextProps.kioskEmail.success) {
      this.setState({
        emailStatus: {error: false, success: true, message: t('Email Sent')},
        sendingEmail: false
      })
    }
    else if (nextProps.kioskEmail.error) {
      this.setState({
        emailStatus: {error: true, message: t('Email Failed, Try Again?')},
        sendingEmail: false
      })
    }

    if(result.bib !== nextResult.bib || (entryId !== 0 && entryId !== nextResult.entryId)){
      this.setState({
        emailStatus: {error: false, message: ''},
        sendingEmail: false
      })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.emailInputText === nextState.emailInputText
      || !_.isEqual(this.props, nextProps);
  }

  getDateFormatted(dateObj) {
    return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()}`;
  }

  getEmailData = () => {
    const {
      eventMetadata,
      masterEvent,
      result
    } = this.props;

    const {
      eventId = '',
      eventCourseId = '',
      bib = '',
      entryId = '',
      thirdPartyEntryId = ''
    } = result;

    const irpUrl = `${configs.bragiUrl}${getResultsUrlForBib(eventId, eventCourseId, bib, masterEvent.masterEventID, entryId)}`;
    const azpUrl = `${configs.bragiUrl}/azp/ctlive/event/${eventId}/entry/${thirdPartyEntryId}`
    const onCourse = isOnCourse(result)
    const noResult = hasNoResult(result)
    const eventInfoIndex = _.findIndex(masterEvent.eventRaces, function(o) { return o.raceID === eventId; });
    const eventInfo = masterEvent.eventRaces[eventInfoIndex];
    const eventDateRaw = new Date(eventInfo.raceDate);
    const eventDate = this.getDateFormatted(eventDateRaw);
    const todayDateRaw = new Date();
    const todayDate = this.getDateFormatted(todayDateRaw);

    return {
      ctLiveEntryId: thirdPartyEntryId,
      ContactAttributes: {
        SubscriberAttributes: {
          EventName: eventMetadata.eventName,
          EventID: eventId,
          MasterEventID: masterEvent.masterEventID,
          IndividualResultPageURL: (onCourse || noResult) && thirdPartyEntryId ? azpUrl : irpUrl,
          StartDate: eventDate,
          InsertDate: todayDate
        }
      }
    };
  };

  sendEmail = () => {
    this.setState({
      emailStatus: {error: false, message: 'Sending Email...'},
      sendingEmail: true
    });
    trackGoogleEvent('Kiosk', 'Share', 'Email')
    sendKioskEmail(this.getEmailData());
  };

  checkEmailValidity = () => {
    const {
      t
    } = this.props;
    const { emailInputText } = this.state;

    if(validateEmail(emailInputText)) {
      this.sendEmail();
    }
    else {
      this.setState({
        emailStatus: {
          error: true,
          message: t('This email appears to be invalid, please edit and try again.')
        }
      });
    }
  };

  renderQRCode() {
    const {
      masterEvent,
      result
    } = this.props;

    const {
      eventId = '',
      eventCourseId = '',
      bib = '',
      entryId = '',
      thirdPartyEntryId = ''
    } = result;

    const irpUrl = `${configs.bragiUrl}${getResultsUrlForBib(eventId, eventCourseId, bib, masterEvent.masterEventID, entryId, null, null, ['source=kiosk'])}`;
    const azpUrl = `${configs.bragiUrl}/azp/ctlive/event/${eventId}/entry/${thirdPartyEntryId}`
    const onCourse = isOnCourse(result)
    const noResult = hasNoResult(result)

    QRCode.toDataURL((onCourse || noResult) && thirdPartyEntryId ? azpUrl : irpUrl).then((url) => {
      const qrCode = new Image()
      qrCode.height = 150
      qrCode.width = 150
      qrCode.src = url
      const qrImagePlaceholder = document.getElementById('qrImagePlaceholder');
      if (qrImagePlaceholder) {
        while (qrImagePlaceholder && qrImagePlaceholder.firstChild) {
          qrImagePlaceholder.removeChild(qrImagePlaceholder.firstChild);
        }
        qrImagePlaceholder.appendChild(qrCode)
      }
    })
  }

  render() {
    const {
      result,
      isLightMode,
      isMobile,
      masterEvent,
      print,
      t
    } = this.props;

    const { emailStatus, sendingEmail } = this.state;
    const hasRegEmail = result.isValidEmail;
    const regEmailDisabled = emailStatus.success || sendingEmail;
    const hasIntlString = !!emailStatus.message;
    const emailButtonText = hasIntlString ? emailStatus.message
      : t('Email Result');
    const showPrivacyAndTerms = hasRegEmail || print.printerSet
    const onCourse = isOnCourse(result)
    const noResult = hasNoResult(result)

    // TODO: Create outer wrapper for Email/Print Buttons and TOS
    const ppStyles = {
      marginLeft: '.5em',
      marginRight: '.5em'
    }
    const privacyPolicyComponent = <PrivacyPolicyInPage style={ppStyles} />
    const termsOfServiceComponent = <TermsOfServiceInPage style={{marginLeft: '.5em'}} />
    const termskey = 'By clicking this button, you agree to Athlinks’ '
    + '<0/> and <1/>.'
    this.renderQRCode()
    const rowStyles = {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row'
    }

    return (
      <div>
        <div className='row' style={styles.kioskEmailContainer(isLightMode)}>
          <div id="qrImagePlaceholder" style={styles.qrCode}></div>
          <div className='col-sm-9 col-md-12' style={styles.actionsContainer}>
            <div style={rowStyles}>
              {hasRegEmail && <KioskEmailButton
                messageId={emailButtonText}
                isDisabled={regEmailDisabled || onCourse || noResult}
                isMobile={isMobile}
                isRegEmailButton={true}
                hasIntlString={hasIntlString}
                sendingEmail={sendingEmail}
                emailStatus={emailStatus}
                onClickHandler={()=> !regEmailDisabled && this.sendEmail()}/>}
              <IndividualKioskPrint
                isLightMode={isLightMode}
                masterEvent={masterEvent}
                result={result}
              />
            </div>
          </div>
        </div>
        <div className='col-12'>
        {showPrivacyAndTerms && <div style={styles.emailInfoMessage(isLightMode)}>
          <Trans
            i18nKey={termskey}
            components={[privacyPolicyComponent, termsOfServiceComponent]}
            style={{
              color: isLightMode ? colors.greySubText : colors.white,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              paddingTop: '5px'
            }}>
              By clicking this button, you agree to Athlinks’ {privacyPolicyComponent} and {termsOfServiceComponent}.
            </Trans>
        </div>}
      </div>
    </div>
    );
  };
}


const mapStateToProps = (state) => ({
  dispatch: undefined,
  print: state.print
});

export const IndividualKioskShare = connect(mapStateToProps)(connectStream({
  kioskEmail: getKioskEmailStream,
})(IndividualKioskShareComponent));

const KioskEmailButton = (
  {
    messageId,
    isRegEmailButton,
    onClickHandler,
    isDisabled,
    isMobile,
    sendingEmail,
    emailStatus
  }) => {
    const btnStyle = {
      paddingRight: isMobile ? '0px' : '12.5px'
    }
    return (
      <div style={btnStyle}>
        <EmailButton
          label={messageId}
          isDisabled={isDisabled}
          sendingEmail={sendingEmail}
          emailStatus={emailStatus}
          isRegEmailButton={isRegEmailButton}
          onClickHandler={onClickHandler}
        />
      </div>
    );
  };

const EmailButton = ({label, isDisabled, isRegEmailButton, onClickHandler, sendingEmail, emailStatus}) => {
  const buttonProps = { style: styles.emailIcon, size: 40 }
  const staticIcon = !!emailStatus.success ? <SuccessIcon {...buttonProps}  /> : <EmailIcon {...buttonProps} />
  const activeIcon = sendingEmail ? <Lottie
    options={{
      animationData: SendingSpinner
    }}
    width={40}
    height={50}
    style={{
      position: 'absolute',
      marginLeft: 0,
      left: 18,
      top: -2,
    }}
  /> : staticIcon
  return(<RaisedButton
    label={label}
    labelColor={colors.white}
    style={isRegEmailButton
      ? styles.kioskRegEmailButton(isDisabled)
      : styles.kioskManualEmailButton(isDisabled)}
    disabled={isDisabled}
    labelStyle={styles.kioskEmailButtonLabel}
    onClick={onClickHandler}
    backgroundColor={colors.blue}
    icon={activeIcon}
  />)

};