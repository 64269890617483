import { MediaState } from '../model/mediaState';
import { ResultActionTypes as Action } from '../resultsClient/types';

const mediaReducer = (state = MediaState, action) => {
  switch(action.type) {
    case Action.GET_MEDIA_ERROR:
      return {
        ...state,
        errorMessage: 'Failed to get media',
        isLoading: false,
        wasStopped: false
      };
    case Action.GET_MEDIA:
      return {
        ...state,
        errorMessage: '',
        isLoading: true,
        wasStopped: false,
        args: action.args
      };
    case Action.GET_MEDIA_STOP:
      return MediaState;
    case Action.GET_MEDIA_UPDATE:
      return {
        ...state,
        media: action.results,
        isLoading: false,
        errorMessage: '',
        wasStopped: false
      }
    default:
      return state;
  }
}

export default mediaReducer
