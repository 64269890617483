import { useHistory } from 'react-router-dom'
import useStyles from './styles'

type IProps = {
  divisionId?: number
  eventId: number
  masterId: number
  message: string
  raceId: number
}

const ZeroState = ({
  divisionId,
  eventId,
  masterId,
  message,
  raceId,
}: IProps) => {
  const styles = useStyles()
  const history = useHistory()
  
  return <div className={styles.wrapper}>
    {message}
    <br />
    Check Results (
    <span
      className={styles.infoText}
      onClick={() => {
        history.push(`/event/${masterId}/results/Event/${eventId}/Course/${raceId}${
          divisionId ? `/Division/${divisionId}` : ''
        }`)
      }}
    >
      Here
    </span>
    ) or adjust your filter.
  </div>
}

export default ZeroState