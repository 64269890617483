import { useMediaQuery } from "@material-ui/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { AthlinksAds } from "../../components/shared/Banner";
import { Promo } from "../../components/IRP/body/IRPPromo";
import { theme } from "../../shared/theme";

import { useMissingData } from "./hooks/useMissingData";

type EventSponsorsProps = {
  eventId: number | null
}

const EventSponsors = ({ eventId }: EventSponsorsProps) => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { sponsors } = useMissingData({ eventId })

  const athlinksAdItems = useMemo(() => {
    if (Array.isArray(sponsors) && !sponsors.length) {
      return AthlinksAds.adsForPage('IndividualResults')
    }

    return []
  }, [sponsors])

  return (
    <Promo
      adUnits={athlinksAdItems}
      isMobile={isMobile}
      sponsors={sponsors || []}
      t={t}
    />
  )
}

export { EventSponsors }