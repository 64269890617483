import { Box, Typography } from "@material-ui/core"

import { AzpEventEntry } from "../../types.results.reignite"
import { profileImageUrl } from "../../utils/profileImageUrl"

import { useAthleteCardStyles, useSharedStyles } from "./styles"
import { AthletePhoto } from "./AthletePhoto"

type AthleteCardProps = {
  entry: AzpEventEntry | null
  isLoadingEntry: boolean
}

const AthleteCard = ({
  entry,
  isLoadingEntry,
}: AthleteCardProps) => {
  const avatarSize = { mobile: 82, desktop: 120 }

  const styles = useAthleteCardStyles()
  const sharedStyles = useSharedStyles()

  if (isLoadingEntry) {
    return (
      <Box className={`${sharedStyles.paper} ${styles.container}`}>
        <AthletePhoto
          isLoading
          imageUrl={null}
          size={avatarSize}
        />

        <Box className={styles.body}>
          <Box className={styles.skeletonDisplayName} />
          <Box className={styles.skeletonDetails} />
        </Box>
      </Box>
    )
  }

  if (!entry) {
    return (
      <Box className={`${sharedStyles.paper} ${styles.container}`}>
        <Typography>
          Athlete not found
        </Typography>
      </Box>
    )
  }

  const displayName = entry.displayName || 'Unknown Racer'
  const imageUrl = (!entry.private && entry.racerId && profileImageUrl(entry.racerId)) || null
  const locality = entry.location?.locality || null
  const region = entry.location?.region || null

  return (
    <Box className={`${sharedStyles.paper} ${styles.container}`}>
      <AthletePhoto
        displayName={displayName}
        imageUrl={imageUrl}
        isMember={!!entry.racerId}
        size={avatarSize}
      />

      <Box className={styles.body}>
        <Typography className={styles.displayName}>
          {displayName}
        </Typography>

        {!entry.private && (
          <Box className={styles.detailsContainer}>
            <Typography className={styles.details}>
              {entry.gender} {entry.age}
            </Typography>

            {!!(locality && region) && (
              <Typography className={styles.details}>
                <span className={styles.detailsDivider}>|</span>
                {locality}, {region}
              </Typography>
            )}
          </Box>
        )}

        {!entry.private && !!entry.bib && (
          <Box className={styles.bibContainer}>
            <Typography className={styles.bibLabel}>Bib</Typography>

            <Box className={styles.bibNumberBox}>
              <Typography className={styles.bibNumber}>{entry.bib}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export { AthleteCard }
