import { IndividualKioskResultsContent, Result } from './IndividualKioskResultsContent'
import React from 'react'
import { Loading } from '../shared/Loading'
import { useGetIndividualResultQuery } from '../../api/reigniteApi'
import { CardHeader } from './CardHeader'
import { IndividualKioskContentHeader } from './IndividualKioskContentHeader'
import { IndividualKioskShare } from './IndividualKioskShare'
import { makeStyles, Theme } from '@material-ui/core'
import { colors } from '../../shared/styles'
import { CtLiveApiStatus, EventMetadata, MasterEvent } from '../../utils/types'
import transformReigniteEntryIntervalTime from '../../utils/transformReigniteEntryIntervalTime'

const useStyles = makeStyles<Theme, { isLightMode: boolean }>(() => ({
  card: ({ isLightMode }) => ({
    boxShadow: colors.cardShadow,
    backgroundColor: isLightMode ? colors.white : colors.darkBlue4,
    padding: '1.25rem',
    minWidth: '23em',
    borderRadius: 4,
    maxWidth: 969
  }),
  resultContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export function IndividualKioskContentReignite({
  eventCourseName,
  eventMetadata,
  masterEvent,
  eventDate,
  isLightMode,
  isMobile,
  bib,
  entryId,
  eventCourseId,
  eventId,
  onBack = () => { },
  t
}: {
  eventCourseName: string
  eventMetadata: EventMetadata
  masterEvent: MasterEvent
  eventDate: string
  isLightMode: boolean
  isMobile: boolean
  bib: string
  entryId: number
  eventCourseId: number
  eventId: number
  onBack: () => void
  t: any
}) {
  const styles = useStyles({ isLightMode })

  const { data: result, isFetching: isLoading } = useGetIndividualResultQuery(
    {
      eventId,
      eventCourseId,
      bib,
      entryId
    },
    {
      pollingInterval: 45_000, // poll every 45 seconds
      refetchOnMountOrArgChange: 60, // cache for 60 seconds
    }
  )

  if (isLoading) return <Loading centered={true} />

  const mappedResult: Result = {
    ...result,
    bib: result?.bib || '',
    status: CtLiveApiStatus.Confirmed, // TODO
    displayName: result?.displayName || '',
    isValidEmail: !!result?.id, // TODO
    thirdPartyEntryId: +(result?.id ?? 0),
    eventId,
    eventCourseId,
    intervals: result?.intervals.map((interval) => ({
      ...interval,
      intervalFull: interval.full || interval.name?.toUpperCase() === 'FULL COURSE',
      divisions: interval.divisions.map((division) => ({
        ...division,
        totalInBracket: division.totalAthletes,
        bracketType: division.type === 'overall'
          ? 'OPEN'
          : division.type === 'gender'
            ? 'SEX'
            : 'OTHER',
      })),
      intervalRaceType: interval.raceType,
      timeWithPenalties: !interval.divisions.length
        ? {
          timeInMillis: 0
        }
        : transformReigniteEntryIntervalTime({
          chipTimeInMillis: interval.chipTimeInMillis,
          gunTimeInMillis: interval.gunTimeInMillis,
          raceType: interval.raceType,
          rankType: interval.divisions[0].rankType,
        }),
    })) || []
  }

  return (
    <div className={`row mx-0 ${styles.resultContainer}`}>
      <CardHeader
        eventMetadata={eventMetadata}
        eventDate={eventDate}
        isLightMode={isLightMode}
        onBack={onBack}
        isMobile={isMobile}
        t={t}
      />
      <div className={`col-sm-10 col-md-8 ${styles.card}`}>
        <IndividualKioskContentHeader
          bibNumber={mappedResult.bib}
          isLightMode={isLightMode}
          isLoading={isLoading}
          isMobile={isMobile}
          title={mappedResult.displayName}
        />
        <IndividualKioskResultsContent
          eventCourseName={eventCourseName}
          isLightMode={isLightMode}
          isMobile={isMobile}
          result={mappedResult}
        />
        <IndividualKioskShare
          /*// @ts-ignore */
          t={t}
          eventMetadata={eventMetadata}
          isLightMode={isLightMode}
          isMobile={isMobile}
          masterEvent={masterEvent}
          result={mappedResult}
        />
      </div>
    </div>
  )
}
