import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {styles as bodyStyles} from '../body/styles';
import RankUtils from '../../../utils/races/RankUtils';
import { makeStyles, Typography } from '@material-ui/core';
import { textStyles, colors } from '../../../shared/styles';

const useRankStyles = makeStyles(() => ({
  rankingsContainer: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
  },
  rankLabel: {
    ...textStyles.sectionHead,
    padding: '0 4px',
  },
  rankValueContainer: {
    padding: '2px 4px 0',
    marginTop: '6px',
    borderTop: `1px solid ${colors.greyLine}`,
  },
  rankValue: {
    color: colors.textColor,
    fontFamily: 'LatoBold',
    fontSize: '24px',
    textWrap: 'nowrap',

    '& span': {
      ...textStyles.body1Grey,
      fontFamily: 'LatoRegular',
      fontSize: '12px',
    },
  }
}))

export const HeroCardAllDivisions = (props) => {
  const {
    rankings,
    title,
    isUnranked,
    t
  } = props;

  const styles = useRankStyles();

  if (!rankings || rankings.length === 0) return null;

  if (isUnranked) {
    return (
      <div style={bodyStyles.cardSectionTitle}>
        {title ? t('{{title}}: Unranked', {title}) : t('Ranking: Unranked')}
      </div>
    );
  }

  const filteredRankings = rankings.filter(rank => !!rank.label);

  return (
    <div>
      <div style={bodyStyles.cardSectionTitle}>
        {title || t('Ranking')}
      </div>

      <div className={styles.rankingsContainer}>
        {filteredRankings.map((rank) => (
          <HeroCardDivisionComponent ranking={rank} />
        ))}
      </div>
    </div>
  );
};

HeroCardAllDivisions.propTypes = {
  rankings: PropTypes.arrayOf(PropTypes.shape({
    rank: PropTypes.any,
    total: PropTypes.any,
    label: PropTypes.string
  })).isRequired,
  title: PropTypes.string,
  isUnranked: PropTypes.bool,
  t: PropTypes.func
};

HeroCardAllDivisions.defaultProps = {
  rankings: []
};



const HeroCardDivisionComponent = ({ ranking }) => {
  const { rank } = ranking

  const styles = useRankStyles();

  const parsedInt = !isNaN(parseInt(rank)) ? parseInt(rank) : false;
  const hasRank = rank && parsedInt && !RankUtils.isDnf(parsedInt) && !RankUtils.isNoRank(parsedInt);

  return (
    <div>
      <Typography className={styles.rankLabel}>{ranking.label}</Typography>

      <div className={styles.rankValueContainer}>
        {hasRank ? (
          <Typography className={styles.rankValue}>{ranking.rank}<span> / {ranking.total}</span></Typography>
        ) : (
          <Typography className={styles.rankValue}>--</Typography>
        )}
      </div>
    </div>
  );
};

export const HeroCardDivision = withTranslation()(HeroCardDivisionComponent);
