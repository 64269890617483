import { Box, useMediaQuery } from '@material-ui/core';
import { useState } from 'react';

import { theme } from '../../shared/theme';
import { EventType } from '../../utils/types';
import { heroImageUrl } from '../../utils/heroImageUrl';

import { useStyles } from './styles';

interface HeroImageProps {
  ctliveEventId?: string;
  isCustomHeroImage?: boolean;
  isLoading: boolean;
  eventType?: EventType;
}

const HeroImage = ({
  ctliveEventId,
  isCustomHeroImage,
  isLoading,
  eventType = EventType.Running,
}: HeroImageProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles({ isImageLoaded, withCustomHeroImage: isCustomHeroImage });

  const imgUrl = isLoading ? null : heroImageUrl(eventType, isCustomHeroImage && ctliveEventId);

  return (
    <>
      {!isImageLoaded && <Box className={styles.heroImageSkeleton} />}

      <img
        alt='Hero pic'
        className={`${styles.heroImage} ${isMobile ? styles.heroImageMobile : styles.heroImageDesktop}`}
        src={imgUrl}
        onLoad={() => setIsImageLoaded(true)}
      />
    </>
  );
};

export { HeroImage };

export type { HeroImageProps };
