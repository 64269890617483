import React from 'react'
import { withTranslation } from 'react-i18next'
import IndividualKioskResultsComponent from './IndividualKioskResultsComponent'
import useReigniteResults from '../../lib/firebase/useReigniteResults'
import { EventMetadata, MasterEvent } from '../../utils/types'

type Props = {
  bib: string
  cancelSelection: () => void
  isLightMode: boolean
  isMobile: boolean
  masterEvent: MasterEvent
  t: any
  eventId: number
  eventCourseId: number
  eventCourseName: string
  eventDate: string
  eventMetadata: EventMetadata
  entryId: number
  mode: string
}

const PureIndividualKioskResults = (props: Props) => {
  const isEventLinked = props.eventMetadata?.azp === 'ctlive' && !!props.eventMetadata?.azpEventId
  const athlinksEventId = props.eventId
  const usingReigniteResults = useReigniteResults(athlinksEventId, isEventLinked)

  return <IndividualKioskResultsComponent
    {...props}
    usingReigniteResults={usingReigniteResults}
  />
}

export const IndividualKioskResults = withTranslation()(PureIndividualKioskResults)
