import { colors, fonts, textStyles } from '../../shared/styles';

export const defaultStyles = {
  display: 'inline-block',
  borderRadius: 3,
  textTransform: 'uppercase',
  cursor: 'pointer',
};

export const filterButtonStyles = {
  ...defaultStyles,
  fontSize: 14,
  padding: '3px 5px',
  margin: 3,
  minWidth: 12,
};

const athlinksButtonStyles = {
  ...defaultStyles,
  fontSize: 12,
  fontFamily: 'ProximaNovaSemibold',
  padding: 4,
  border: '2px solid #16A9E1',
  textAlign: 'center',
};

export const styles = {
  LanguageSwitcherStyles: {
    selectFieldStyle: {
        border:'1px solid #dddddd',
        width: 'auto',
        height: 'inherit',
        fontSize: '12px',
        marginTop: '-3px',
        marginLeft: '15px',
    },
    underlineStyle: {
        borderBottom: 'none'
    },
    labelStyle: {
        lineHeight: '24px',
        paddingLeft: '10px',
        paddingRight: '24px',
        top: '0px',
        color: '#9b9b9b'
    },
    iconStyle: {
        top: '0px',
        fill:'#a5a5a5'
    },
    menuItemInner: {
        fontSize:12,
        margin:0
    }
  },
  bib: {
    minWidth: 40,
    padding: 3,
    fontWeight: 'bold',
    fontSize: '13px',
    border: '1px solid lightgrey',
    textAlign: 'center',
    borderTop: '4px solid '+colors.blue
  },
  badge: (size, style) => ({
    display:'inline-block',
    cursor: 'pointer',
    width: size,
    height: size,
    borderRadius: '50%',
    textAlign:'center',
    lineHeight:size+'px',
    paddingTop:1,
    ...style
  }),
  VRBadge: {
    textTransform: 'uppercase',
    fontSize: 10,
    color:'#16A9E1',
    letterSpacing: '.5px',
    fontFamily: 'ProximaNovaBold',
    border: '1px solid' + colors.blue,
    height: '16px',
    borderRadius: 20,
    textAlign: 'center',
    width: 'fit-content',
    paddingLeft: '6px',
    paddingRight: '6px'
  },
  VRTextLink: {
    textTransform: 'uppercase',
    display: 'flex',
    fontSize: 10,
    color:'#16A9E1',
    letterSpacing: '.5px',
    fontFamily: 'ProximaNovaBold',
    height: 12,
    marginTop: 3,
    textAlign: 'center',
    alignItems: 'center'
  },
  VRBadgeIcon: {
    fontSize: 12,
    marginLeft: '3px'
  },
  VRBadgeIcon2: {
    marginTop: '-2px',
    color: colors.blue
  },
  VRToolTip: {
    display: 'inline',
    paddingLeft: 4
  },
  bannerMinStyle: (maxWidth, maxHeight) => ({
    width: '100%',
    maxWidth,
    maxHeight,
    boxSizing: 'border-box',
    overflow: 'hidden'
  }),
  datePicker: {
      container: {
        position:'relative',
        zIndex:0
      },
      wrap: (value) => ({
        position: 'absolute',
        bottom: 8,
        right: 0,
        zIndex: 2,
        display: 'inline-block',
        padding: 4,

        opacity: (value) ? 1 : 0,
        pointerEvents: (value) ? 'auto' : 'none' ,
        transition: 'opacity 0.3s',
        cursor: 'pointer',
    }),
    closeIcon: {
        width: 18,
        height: 18,
        display: 'inline-block',
    }
  },
  dateRange: {
      container: (expanded) => ({
        marginTop: (expanded) ? -20 : 0,
        transition: 'margin-top 0.2s 0.2s',
      })
  },
  withCheckbox: {
    toggleStyle : (enabled) => ({
        maxHeight: (enabled) ? 200 : 0,
        //overflow: 'hidden',
        pointerEvents: (enabled) ? 'auto' : 'none',
        paddingBottom: (enabled) ? 20 : 0,
        marginBottom: (enabled) ? 20 : 0,
        borderBottom: (enabled) ? '1px solid #ededed' : 'none',
        opacity: (enabled) ? 1 : 0,
        transition: (enabled) ? 'all 0.5s ease-in-out, opacity 0.3s 0.2s' : 'all 0.5s 0.2s ease-in-out, opacity 0.2s',
      })
  },
  numberInput: {
    fontFamily: 'ProximaNovaRegular'
  },
  checkBoxNumber: {
    position: 'relative',
    height: 20
  },
  multiSelect: {
    container: {
        overflowY: 'auto',
        maxHeight: 300
    },
    buttonStyle : {
        display: 'block',
        borderRadius: 3,
        textTransform: 'uppercase',
        cursor: 'pointer',
        fontSize: 14,
        padding: '3px 5px',
        margin: 3,
        minWidth: 12,
    },
    blueStyle: {
        backgroundColor: '#16a9e1',
        color: '#f7f9fa'
    },
    whiteStyle: {
        color: '#4a4a4a',
        backgroundColor: '#fff'
    },
  },
  button: {
      defaultStyles: {
        display: 'inline-block',
        borderRadius: 3,
        textTransform: 'uppercase',
        cursor: 'pointer',
      },
      types: {
        blue: {
          backgroundColor: '#014661',
          ...filterButtonStyles,
          color: '#f7f9fa',
          border:'1px solid #014661'
        },
        white: {
          ...filterButtonStyles,
          backgroundColor: '#ffffff',
          color: '#014661',
          border: '1px solid #0c465f'
        },
        disabled: {
          backgroundColor: '#f9f9f9',
          color: '#a2a2a2',
          border: '1px solid #a2a2a2'
        },
        blueAthlinks: {
          ...athlinksButtonStyles,
          backgroundColor: '#16A9E1',
          color: '#ffffff'
        },
        whiteAthlinks: {
          ...athlinksButtonStyles,
          backgroundColor: '#ffffff',
          color: '#16A9E1'
        },
        blueAthlinksXL: {
          fontSize: 17,
          fontFamily: 'ProximaNovaRegular',
          textTransform: 'capitalize',
          backgroundColor: '#16A9E1',
          color: '#ffffff',
          padding: '14px 60px'
        }
      }
  },
  locationElement: {
    inputStyle: {
        padding: '12px',
        width: '240px',
        minWidth: '240px',
        boxSizing: 'border-box',
        outline: 'none',
        border: 0,
        borderBottom: '1px solid lightgrey'
    },
    containerStyle: (expanded, wantsRange) => ({
        marginTop: (expanded) ? -6 : 0,
        marginBottom: (expanded && wantsRange) ? 10 : 0,
        transition: 'all 0.3s',
    })
  },
  dropDown: {
    labelStyle: (isDisabled) => ({
      ...fonts.default,
      color: (isDisabled) ? colors.greyLine : colors.grey,
      fontSize: 14,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '100%'
    }),
    valueStyle: (isDisabled) => ({
      ...fonts.default,
      color: (isDisabled) ? colors.grey : colors.textColor,
      fontSize: 16,
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis'
    }),
    fieldStyle: (hide) => (
      hide ? {display: 'none'} : {width: '100%'}
    )
  },
  renderLabel: {
        color: '#d0d0d0',
        fontSize: 9,
        marginBottom: 10
  },
  searchField: {
    container: {
      position: 'relative'
    },
    textField: (style) => ({
      ...style,
      fontFamily:'ProximaNovaRegular',
      fontSize: 15,
      paddingLeft: 28,
      width: '100%'
    }),
    inputStyle: (style, darkScheme) => ({
      ...style,
      fontFamily: 'ProximaNovaRegular',
      fontSize:15,
      color: darkScheme ? 'white' : 'auto'
    }),
    underlineStyle: {
      width:'100%',
      boxSizing:'border-box'
    },
    img: (topVal = 9) => ({
      position:'absolute',
      fill: 'white',
      left: 8,
      top: topVal,
      width: 15
    })
  },
  message: {
    wrapper: (isMobile) => ({
      marginTop: 15,
      borderTop: isMobile ? 'none' : '1px solid '+ colors.greyLine,
      paddingTop: 20,
    }),
    title: {
      fontSize: 11,
      color: colors.grey,
    },
    buttonContainer: {
      marginTop: 40,
      textAlign: 'right',
    },
    button: {
      display: 'inline-block',
      padding: '9px 20px 11px',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      textAlign: 'center',
      ...fonts.default,
      fontSize: 18,
      lineHeight: '1em',
      borderRadius: 2,
    },
    buttonClear: (isMobile) => ({
      ...fonts.bold,
      backgroundColor: '#ffffff',
      color: colors.textColor,
      width: isMobile ? 105 : 130,
      fontSize: 14,
      textTransform: 'uppercase',
    }),
    buttonSend: (isMobile) => ({
      backgroundColor: colors.blue,
      color: '#ffffff',
      width: isMobile ? 105 : 250,
      marginLeft: 15,
      textTransform: 'capitalize',
    }),
    input: {
      padding: '0 5px',
      ...fonts.default,
      fontSize: 16,
    }
  },
  athletesType: {
    activeButtonStyle: (isMobile) => ({
      ...buttonStyle(isMobile),
      color: '#fff',
      backgroundColor: colors.blue,
    }),
    unActiveButtonStyle: (isMobile) => ({
      ...buttonStyle(isMobile),
      color: colors.blue,
      backgroundColor: 'transparent',
    }),
    container: (isMobile) => ({
      textAlign: isMobile ? 'center' : '',
      maxWidth: isMobile ? '250px' : '100%'
    })
  },
  filters: {
    main: (isMobile) => ({
      color: colors.grey,
      fontSize: 14,
      width: '100%',
      margin: '',
    }),
    headerRow: {
      padding: '8px 0 10px',
      marginBottom: 15,
      borderBottom: '1px solid '+ colors.greyLine,
    },
    button: {
      border: 'none',
      backgroundColor: 'transparent',

      display: 'inline-block',
      float: 'right',
      ...textStyles.textLink2Blue,
      lineHeight: '1em',
      padding: '3px 0 0 5px',
    },
    raceType: {
      letterSpacing: '0px',
      fontSize: 12
    }
  },
  filterComponent: {
    equalizerStyle: {
      float: 'right',
      border: '1px solid #9B9B9B',
      padding: 5,
      top: 12,
      color: '#9B9B9B'
    },
    main: {
      fontSize: 14,
      marginBottom: 20
    },
    headerRow: {
      textAlign: 'left',
      width: '100%',
      color: '#9B9B9B',
      borderBottom: '1px solid '+ colors.greyLine,
      padding: '15px 0',
      marginBottom: 20
    },
    button: {
      border: 'none',
      backgroundColor: 'transparent',

      display: 'inline-block',
      float: 'right',
      marginTop: 5,
      ...textStyles.textLink2Blue,
      padding: '4px 0',
    },
    toggleStyle: (enabled) => ({
      maxHeight: (enabled) ? 900 : 0,
      pointerEvents: (enabled) ? 'auto' : 'none',
      opacity: (enabled) ? 1 : 0,
      transition: (enabled) ? 'all 0.5s ease-in-out, opacity 0.3s 0.2s' : 'all 0.5s 0.2s ease-in-out, opacity 0.2s',
    })
  },
  typeFilter: {
    checkBox: {
      fontSize: 14,
      paddingLeft: 14,
      margin: '0 0 5px'
    }
  },
  typeAhead: {
    container: (width) => ({
      display:'inline-block',
      width,
      position:'relative'
    }),
    iconStyle: {
      display: 'inline-block',
      position: 'absolute',
      top: 0,
      right: 0,
    },
    dropdown: (dropdownTop, dropdownHeight, dropdownStyle) => ({
      position:'absolute',
      left:0,
      top: dropdownTop,
      right: 0,
      zIndex: 3,
      overflowY: 'scroll',
      height: dropdownHeight,
      ...dropdownStyle
    }),
    dropdownMsg: {
      padding: 5,
      borderBottom: '1px solid lightgrey'
    },
    timePicker: {
      selectField: {
        width:100,
        textAlign:'center',
        marginRight:10
      }
    },
    infiniteScrollStyle: (dropdownTop, dropdownHeight, dropdownStyle) => ({
      position:'absolute',
      left:0,
      top: dropdownTop,
      right: 0,
      zIndex: 3,
      textAlign: 'left',
      overflowY: 'auto',
      height: dropdownHeight,
      ...dropdownStyle
    })
  },
  followButton: {
    textAlign: 'right'
  },
  slider: {
    numberHandleContainer: (offset) => ({
      ...fonts.default,
      left: `${offset}%`,
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
      cursor: 'pointer',
      padding: 5,
      lineHeight: '1em',
      borderRadius: 3,
      background: colors.darkBlue
    }),
    textStyles: {
      color: colors.lightGrey,
      fontSize: 11,
      pointerEvents: 'none'
    },
    renderLabel: {
      ...fonts.default,
      color: colors.grey,
      fontSize: 12,
      margin: '0 0 20px'
    },
    labelContainer: {
      padding: '8px 12px 0'
    }
  },
  signUpBanner: {
    wrap: (style) => ({
      background: 'linear-gradient(180deg, #07668B 0%, #014661 100%)',
      color: '#ffffff',
      ...style
    }),
    container: (isMobile) => ({
      paddingTop: isMobile ? 17 : 27,
      paddingBottom: isMobile ? 18 : 27,
    }),
    col: {
      display: 'flex',
      justifyContent: 'center'
    },
    signupMsg: {
      fontSize: 28,
      textTransform: 'capitalize',
      marginBottom: 15,
      marginTop: 0
    },
    signupDescription: {
        fontFamily: 'ProximaNovaSemibold',
        marginBottom: 0
    },
    signupButton: {
      display: 'flex',
      alignItems: 'flex-end',
      paddingBottom: 12
    },
    signupLabel: {
      textTransform: 'capitalize',
      fontSize: 18,
      fontFamily: 'ProximaNovaRegular'
    },
    title: (isMobile) => ({
      fontSize: isMobile ? 21 : 28,
      textTransform: 'capitalize',
      marginBottom: isMobile ? 0 : 9,
      marginTop: 0,
      fontFamily: 'ProximaNovaRegular',
      fontWeight: 'normal',
    }),
    body: (isMobile) => ({
      fontSize: isMobile? 12 : 16,
      fontFamily: 'ProximaNovaRegular',
      fontWeight: 'normal',
      marginBottom: 0,
      marginTop: 0,
      padding: isMobile ? 0 : 0
    }),
    buttonWrapper: (isMobile) => {
      if(isMobile) return {
        width: '100%',
        paddingTop: 20,
        textAlign: 'center'
      };
      else return {
        display: 'flex',
        alignItems: 'flex-end',
        paddingBottom: 12,
        marginLeft: 'auto',
      }
    }
  },
  quickLinks: {
    titleRow: {
      backgroundColor: colors.lightGrey2,
      padding: '4px 10px 2px 54px',
      ...textStyles.sectionHead,
      textAlign: 'left'
    },
    linkContainer: {
      padding: '7px 0',
    },
    link: {
      display: 'block',
      padding: '8px 10px 5px 54px',
      fontSize: 14,
      lineHeight: '1em',
      color: colors.blue,
      textAlign: 'left',
      cursor: 'pointer'
    },
    link404: {
      padding: '8px 10px 5px 10px',
      fontSize: 14,
      color: colors.blue,
      cursor: 'pointer'
    }
  },
  mobileHeader: {
    searchOpenStyle: (showSearchField) => ({
      position: 'relative',
      top: 3,
      right: 20,
      display: 'inline-block',
      width: 19,
      opacity: (showSearchField) ? 0 : 1,
      transition: 'all 0.2s',
      pointerEvents: (showSearchField) ? 'none' : 'auto',
    }),
    searchFieldStyle: (showSearchField) => ({
      position: 'absolute',
      left: (showSearchField) ? 0 : '110%',
      display: 'inline-block',
      width: '100%',
      opacity: (showSearchField) ? 1 : 0,
      transition: 'all 0.4s',
    }),
    main: {
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1030,
      width: '100%',
      backgroundColor: colors.darkBlue4,
      minHeight: 45,
      boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.24)'
    },
    logo: {
      position:'relative',
      zIndex:11,
      backgroundColor: colors.darkBlue4
    },
    searchContent: (showSearchField) => ({
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      border: showSearchField ? '1px solid rgba(255,255,255,0.25)' : 'none',
      borderLeft: 'none',
      height: '32px',
      marginTop: 7,
      marginRight: 30,
      paddingRight: 'auto',
      backgroundColor: showSearchField ? 'rgba(0,0,0,0.3)' : 'rgba(0,0,0,0)',
      borderRadius: '0px 4px 4px 0px'
    }),
    closeBacking: {
      position: 'absolute',
      right: 20,
      top: 13,
      width: 20,
      borderRadius: '50%',
      zIndex: 50,
      height: 20,
      backgroundColor: 'rgba(255,255,255,0.25)'
    },
    clearIcon: {
      zIndex: 50,
      height: 15,
      width: 20,
      paddingBottom: 5
    },
    navParent: {
      position: 'relative'
    },
    navContainer: (showNav) => ({
      position: 'absolute',
      zIndex: 10,
      width: '100%',
      background: '#fff',
      boxShadow: '0 5px 10px -5px #444',
      top: (showNav) ? '0px' : -500,
      transition: 'all 0.4s',
    }),
    searchContextStyle: {
      transition: 'all 0.8s'
    },
    searchContextStyleHidden: {
      opacity: 0
    }
  },
  liveHeader: {
    container: {
      backgroundColor: colors.orange
    },
    live: {
      display: 'inline-block',
      verticalAlign: 'top',
      marginLeft: 20,
      paddingTop: 5,
      paddingBottom: 7,
      fontSize: 16,
      lineHeight: '20px',
    },
    link: {
      display: 'block',
      paddingTop: 4,
      color: '#fff'
    }
  },
  getStartedBanner: {
    joinDiv: {
      backgroundColor: '#ebebeb',
      padding: '30px 50px',
      minHeight: '150px',
      marginTop: 20
    },
    h2: {
      color: '#838383',
      fontSize: 24,
      paddingBottom: 5,
      fontFamily: 'ProximaNovaRegular,helvetica,arial,sans-serif',
      margin: 0
    },
    joinSpan: {
      fontSize: '13px',
      float: 'left',
      width: '70%',
      lineHeight: '20px',
      color: '#838283'
    },
    getStarted: {
      float: 'right',
      fontFamily: 'ProximaNovaRegular,helvetica,arial,sans-serif',
      backgroundColor: '#16A9E1',
      color: 'rgb(255, 255, 255)',
      bottom: 0,
      marginBottom: 27,
      right: 40,
      width: 200,
      lineHeight: 2,
      textAlign: 'center',
      padding: 5,
      fontSize: 19
    }
  },
  getSuggestInput: {
    descContainer: (isFocused, isHovered) => ({
      cursor: 'pointer',
      padding:'5px',
      textAlign:'left',
      backgroundColor: isFocused? colors.blue : isHovered ? '#EFEFEF':  'white',
      borderBottom:'1px solid lightgrey'
    }),
    fetchGeoSuggestWrap: (width) => ({
      width,
      display:'inline-block',
      position: 'relative'
    }),
    typeAheadInput: {
      paddingRight:25,
      color: '#014661'
    },
    typeAheadDropDown: {
      border: '1px solid lightgrey',
      boxShadow: '0 0 9px 5px rgba(0,0,0,.05)',
      backgroundColor:'white'
    },
    loadingLocationContainer:{
      backgroundColor: colors.white,
      height: '30px',
      width: '100%',
      position: 'absolute',
      top: 38,
      left: 0,
      zIndex: 10
    },
    loadingLocation: {
      width: 24,
      height: 24,
      position: 'absolute',
      right: 0,
      top: 0
    }
  },
  summarySearch: {
    container: {
      color: colors.textColor,
      cursor: 'initial',
      ...fonts.default,
      fontSize: 18,
      padding: '1em',
      textAlign: 'center',
      textTransform: 'none',
      transition: 'all 0.3s',
      width: '100%',
    },
    errorContainer: {
      color: colors.textColor,
      cursor: 'initial',
      ...fonts.default,
      fontSize: 18,
      padding: '1em',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      textTransform: 'none',
      transition: 'all 0.3s',
      width: '100%',
      height: '100%'
    },
    alternateErrorContainer: {
      color: colors.textColor,
      cursor: 'initial',
      ...fonts.default,
      fontSize: 18,
      padding: '1em',
      paddingTop: '3em',
      textAlign: 'center',
      textTransform: 'none',
      transition: 'all 0.3s',
      width: '100%',
    },
    button: {
      borderColor: colors.error,
      borderRadius: 3,
      color: colors.error,
      cursor: 'pointer',
      ...fonts.default,
      fontSize: 16,
      fontWeight: 'bold',
      margin: '.5em',
      padding: '.25em 3em',
      textTransform: 'uppercase',
      width: 'initial',
      backgroundColor: colors.white,
    }
  },
};

const buttonStyle = (isMobile) => ({
  display: 'inline-block',
  width: isMobile ? '50%' : '130px',
  padding: '6px 20px 4px',
  ...fonts.heavy,
  textTransform: 'uppercase',
  fontSize: 13,
  lineHeight: '1em',
  cursor: 'pointer',
  border: '2px solid '+ colors.blue,
})
;
