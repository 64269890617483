import React  from 'react'
import { colors } from '../../shared/styles'
import { makeStyles, Theme } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

type IndividualKioskContentHeaderProps = {
  title: string
  bibNumber: string
  isLoading: boolean
  isMobile: boolean
  isLightMode: boolean
}

const useStyles = makeStyles<Theme, { isLightMode: boolean, isMobile: boolean }>(() => ({
  resultHeaderContainer: ({ isLightMode }) => ({
    textAlign: 'center',
    width: '100%',
    margin: 0,
    marginBottom: '1.125rem',
    borderBottom: `1px solid ${isLightMode ? colors.greyLine : colors.softBlueLine}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20
  }),
  resultSubtitle: ({ isLightMode }) => ({
    color: isLightMode ? colors.black : colors.white,
    fontSize: '0.875rem',
    margin: 0
  }),
  bibContainer: ({ isLightMode }) => ({
    width: 95,
    height: 45,
    marginRight: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `5px solid ${colors.lightBlue5}`,
    borderRadius: 4,
    fontFamily: 'LatoBold',
    lineHeight: 1.2,
    color: isLightMode ? colors.darkType : colors.white,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    borderTop: `15px solid ${colors.lightBlue5}`,
    boxSizing: 'content-box'
  }),
  resultHeading: ({ isMobile, isLightMode }) => ({
    color: isLightMode ? colors.darkType : colors.white,
    margin: 0,
    fontSize: `${isMobile ? 2.5 : 2.6}rem`,
    lineHeight: `${isMobile ? 2.5 : 3.125}rem`,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: 'ProximaNovaBold'
  })
}))

const loader = (width: string, height: number, isLightMode: boolean) => (
  <span
    style={{
      width,
      height,
      backgroundColor: isLightMode ? '#f4f4f4' : colors.darkGrey4,
      display: 'inline-block',
    }}
  />
)

export function IndividualKioskContentHeader({
  title,
  bibNumber,
  isLoading,
  isMobile,
  isLightMode
} : IndividualKioskContentHeaderProps) {
  const classes = useStyles({ isLightMode, isMobile })
  const { t } = useTranslation()

  return (
    <div
      className={classes.resultHeaderContainer}>
      {isLoading
        ? <div className={classes.resultSubtitle}> {loader('30%', 25, isLightMode)} </div>
        : <div className={classes.resultSubtitle}>
          <div className={classes.bibContainer}>{bibNumber || t('no bib')}</div>
        </div>
      }
      <h1
        className={classes.resultHeading}>
        {isLoading ? loader('40%', 50, isLightMode) : title}
      </h1>
    </div>
  )
}
