import React from 'react'
import { Link } from 'react-router-dom'
import { useIsWithLaurel } from '../../hooks/useIsWithLaurel'
import { withTranslation } from 'react-i18next'
import { convertTime } from '@eventops/athlinks-lib-units'
import { verifyGender } from '../../utils/ERPUtil'
import { getTokenRacerId, isLoggedIn } from '../../utils/isLoggedIn'
import { getResultsUrlForBib, getBracketRank } from '../../utils/resultsHelpers'
import toCapitalCase from '../../utils/toCapitalCase'
import { styles } from './styles'
import { configs } from '../../configs'
import { AthlinksClaimedFlame } from '../shared/AthlinksClaimedFlame'
import { RowArrow } from '../shared/RowArrow'
import { VRBadgeLink } from '../shared/VRBadge'

type IntervalResultsRowMobileProps = {
  row: any
  index: number
  eventId: number
  eventCourseId: number
  masterId: number
  isVirtual: boolean
  bracketName: string
  t: (key: string) => string
}

const IntervalResultsRowMobile: React.FC<IntervalResultsRowMobileProps> = ({
  row,
  index,
  eventId,
  eventCourseId,
  masterId,
  isVirtual,
  bracketName,
  t,
}) => {
  const {
    bib,
    entryId,
    entryStatus,
    age,
    gender,
    displayName,
    time,
    racerId,
    locality,
    thirdPartyEntryId,
  } = row

  const isWithLaurel = useIsWithLaurel({
    eventCourseId,
    athlinksEventId: eventId,
    thirdPartyEntryId,
    isLive: true,
  })

  const showGender = row.entryType?.toLowerCase() !== 'team'
  const irpUrlBib = getResultsUrlForBib(
    eventId,
    eventCourseId,
    bib,
    masterId,
    entryId
  )
  const racerName = (
    <span style={styles.mobile.name}>
      {toCapitalCase(displayName) || 'Unknown Racer'}
    </span>
  )

  const hasStatusDNForDQ = entryStatus === 'DNF' || entryStatus === 'DQ'
  const timeToShow = convertTime({
    timeInMillis: time.timeInMillis,
    // @ts-ignore
    timeUnit: 'h',
  }).value
  const bibText = bib || ''
  const showSeparator = bib && (showGender || age || locality)
  const ageGenderLocationText = showGender
    ? `${verifyGender(age, gender, t)} ${age || ''}`
    : locality || ''
  let bracketRank = getBracketRank(bracketName, row)
  bracketRank = bracketRank === 999999 ? '--' : bracketRank

  const tokenRacerId = getTokenRacerId()
  const claimed = !!racerId

  //  @ts-ignore
  let actionOrTimeLabel: ReactElement = timeToShow
  if (isVirtual) {
    if (
      claimed &&
      isLoggedIn() &&
      racerId === tokenRacerId &&
      timeToShow === '0:00'
    ) {
      actionOrTimeLabel = (
        <span style={{ fontFamily: 'LatoBold', fontSize: 13 }}>
          {t('Post Time')}
        </span>
      )
    } else if (
      claimed &&
      isLoggedIn() &&
      racerId !== tokenRacerId &&
      timeToShow === '0:00'
    ) {
      actionOrTimeLabel = (
        <span style={{ fontFamily: 'LatoBold', fontSize: 13 }}>--</span>
      )
    } else if (!claimed && timeToShow === '0:00') {
      actionOrTimeLabel = (
        <span style={{ fontFamily: 'LatoBold', fontSize: 13 }}>--</span>
      )
    } else if (!isLoggedIn() && timeToShow === '0:00') {
      actionOrTimeLabel = (
        <span style={{ fontFamily: 'LatoBold', fontSize: 13 }}>
          {t('Post Time')}
        </span>
      )
    }
  }

  return (
    <div key={`result-row-${index}`}>
      <Link
        to={irpUrlBib}
        style={styles.tableRow(true, claimed) as React.CSSProperties}
        key={index}
      >
        <div className='row mx-0' style={styles.mobile.userRow}>
          <div
            className='col-sm-2 col-2'
            style={styles.mobile.rowIndex as React.CSSProperties}
          >
            {bracketRank}
          </div>
          <div className='col-sm-6 col-6 p-0'>
            <div style={{ paddingLeft: '6px' }}>
              <span>{racerName}</span>
              <br />
              {isWithLaurel && (
                <img
                  src={`${configs.cdnUrl}/svg/laurel_icon_small.svg`}
                  alt='Laurel Wreath Left Side'
                  style={{ marginRight: '10px' }}
                />
              )}

              <span style={styles.mobile.age}>{`${bibText}`}</span>
              {showSeparator ? (
                <span style={styles.mobile.separator}>{'|'}</span>
              ) : (
                ''
              )}
              <span style={styles.mobile.age}>
                {`${ageGenderLocationText}`}
              </span>
            </div>
          </div>
          <div className='col-sm-3 col-2 p-0'>
            <div style={styles.mobile.time as React.CSSProperties}>
              {hasStatusDNForDQ ? entryStatus : actionOrTimeLabel}
              {
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}
                >
                  {isVirtual === true ? (
                    <VRBadgeLink hideToolTip={true} />
                  ) : null}
                </div>
              }
              {racerId ? (
                <AthlinksClaimedFlame
                  width='14px'
                  height='14px'
                  // @ts-ignore
                  style={styles.claimFlame.mobile}
                />
              ) : null}
            </div>
          </div>
          <div className='col-sm-1 col-1' style={styles.rowArrowColumn.mobile}>
            <RowArrow />
          </div>
        </div>
      </Link>
    </div>
  )
}

export default withTranslation()(IntervalResultsRowMobile)
