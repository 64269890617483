import { EventResultsComponent } from './EventResultsComponent'
import { EventResultsProps } from '../../utils/types'
import { useGetRaceDivisionIntervalResultsQuery } from '../../api/reigniteApi'
import transformReigniteIntervalResults from '../../utils/transformReigniteIntervalResults'
import useSse from '../../lib/firebase/useSse'
import { ReigniteEventSseResults } from './ReigniteEventSseResults'
import { useEffect, useMemo, useState } from 'react'
import { RbiResults } from '../../types.results.reignite'

export function ReigniteRaceDivisionIntervalResults(
  props: EventResultsProps & {
    athlinksEventId: number
    divisionId: number
    eventCourseId: number
    intervalId: number
    from: number
    isLive: boolean
    limit: number
  }
) {
  const {
    athlinksEventId,
    divisionId,
    eventCourseId,
    intervalId,
    from,
    isLive,
    limit,
  } = props

  const usingSse = useSse(athlinksEventId) && isLive

  const {
    currentData: data,
    isError,
    isFetching,
    refetch,
  } = useGetRaceDivisionIntervalResultsQuery(
    {
      eventId: athlinksEventId,
      eventCourseId,
      divisionId,
      intervalId,
      from,
      limit,
    },
    {
      pollingInterval: isLive && !usingSse
        ? 30_000
        : undefined,
      skip: !eventCourseId || !divisionId || !intervalId,
    }
  )

  const [lastSuccessfulFetch, setLastSuccessfulFetch] = useState<RbiResults | null>(null)

  useEffect(() => {
    if (data && !isError) {
      setLastSuccessfulFetch(data)
    }
  }, [data, isError])

  const isLoading = isFetching && !lastSuccessfulFetch

  const mapped = useMemo(() => {
    return lastSuccessfulFetch && lastSuccessfulFetch.results?.length
      ? [
        {
          eventId: athlinksEventId,
          eventCourseId,
          bracketName: lastSuccessfulFetch.division.name,
          interval: {
            intervalFull: lastSuccessfulFetch.interval.full,
            intervalName: lastSuccessfulFetch.interval.name,
            intervalResults: transformReigniteIntervalResults({
              division: lastSuccessfulFetch.division,
              interval: lastSuccessfulFetch.interval,
              results: lastSuccessfulFetch.results,
              source: lastSuccessfulFetch.source,
            }),
          },
          thirdParySystemRaceId: lastSuccessfulFetch.race.id,
          totalAthletes: lastSuccessfulFetch.division.totalAthletes,
        },
      ]
      : []
  }, [athlinksEventId, eventCourseId, lastSuccessfulFetch])

  return (
    <>
      {usingSse && <ReigniteEventSseResults
        athlinksEventId={athlinksEventId}
        divisionId={divisionId}
        eventCourseId={eventCourseId}
        intervalId={intervalId}
        refetch={refetch}
      />}
      <EventResultsComponent
        {...props}
        fetchEventResultsError={isError && !lastSuccessfulFetch}
        eventResults={{
          data: mapped,
          isLoading,
        }}
        isFetchingEventResults={isFetching}
        refetch={refetch}
      />
    </>
  )
}
