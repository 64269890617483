import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import useEventMetadata from '../../hooks/useEventMetadata'
import { _date, isInFuture } from "../../utils/date";
import { trackGoogleEvent } from "../../utils/googleEvents";
import { DateTime } from "../shared/DateTime";

import { useStyles } from "./styles";

interface LearnMoreOrRegisterButtonProps {
  CTLiveLink: string | null;
  realURL?: string;
  startTime: string | number
  athlinksId: number
}

export const LearnMoreOrRegisterButton = ({
  CTLiveLink,
  realURL,
  startTime, // duplicate (eventMetadata.eventStartDateTime.timeInMillis)
  athlinksId
}: LearnMoreOrRegisterButtonProps) => {
  const { data: eventMetadata, isLoading } = useEventMetadata(athlinksId)
  const isRegPublished = eventMetadata.registration?.published ?? true

  const { t } = useTranslation();
  const styles = useStyles();

  const isInFutureVal = isInFuture(startTime);

  const showRegisterButton = CTLiveLink && isInFutureVal && isRegPublished;
  const showLearnMoreButton = !showRegisterButton && !!realURL;

  if ((!showRegisterButton && !showLearnMoreButton) || isLoading) {
    return null;
  }

  return (
    <Box className={styles.container}>
      {showRegisterButton && (
        <a
          href={CTLiveLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackGoogleEvent('Race', 'Click', 'Register')}
          className={styles.registerButton}
        >
          {t('Register')}
          <br />
          <Typography component='span' className={styles.buttonNote}>
            <DateTime
              date={_date(startTime)}
              showTime={false}
              icon={null}
            />
          </Typography>
        </a>
      )}

      {showLearnMoreButton && (
        <a
          href={realURL}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => trackGoogleEvent('Advertisements', 'Click', 'Learn More')}
          className={styles.learnMoreButton}
        >
          {t('LEARN MORE')}
        </a>
      )}
    </Box>
  );
};