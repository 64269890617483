import { useMemo } from "react"

import { LearnMoreOrRegisterButton } from "../components/learnMoreOrRegisterButton/LearnMoreOrRegisterButton"

type HookOptions = {
  ctLiveRegLink?: string
  eventId: number | null
  learnMoreLink?: string
  startTime: string | number
  thirdPartyRegLink?: string
}

const useLearnMoreOrRegisterButton = ({
  ctLiveRegLink,
  eventId,
  learnMoreLink,
  startTime,
  thirdPartyRegLink,
}: HookOptions) => {
  const isExternalURL = !!learnMoreLink && /^https?:\/\//.test(learnMoreLink);
  const validLearnMoreLink = isExternalURL ? learnMoreLink : 'http://' + learnMoreLink;
  
  const learnMoreRegisterButton = useMemo(() => {
    if (!eventId) {
      return null
    }

    return (
      <LearnMoreOrRegisterButton
        CTLiveLink={thirdPartyRegLink || ctLiveRegLink || null}
        realURL={validLearnMoreLink}
        startTime={startTime}
        athlinksId={eventId}
      />
    )
  }, [ctLiveRegLink, eventId, startTime, thirdPartyRegLink, validLearnMoreLink])

  return { learnMoreRegisterButton }
}

export { useLearnMoreOrRegisterButton }