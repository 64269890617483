import { useMemo } from 'react';
import { useGetMasterEventMetadataQuery } from '../api/reigniteApi'

interface UseShouldDisplayAwardsTabProps {
  masterId: number;
}

export function useShouldDisplayAwardsTab({ masterId }: UseShouldDisplayAwardsTabProps): { shouldDisplayAwardsTab: boolean; isLoading: boolean } {
  const { data: masterEventMetadata, isLoading } = useGetMasterEventMetadataQuery(masterId, { 
    skip: !masterId 
  });

  const shouldDisplayAwardsTab = useMemo(() => {
    return !!masterEventMetadata?.events?.some(event =>
      event?.races?.some(race =>
        race?.divisions?.some(division =>
          division?.awards?.publish && division?.awards?.depth > 0
        )
      )
    )
  }, [masterEventMetadata])

  return { shouldDisplayAwardsTab, isLoading }
}