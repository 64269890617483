import { FC } from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { useGetRosterQuery } from '../../api/reigniteApi';
import useEventMetadata from '../../hooks/useEventMetadata';
import { useStyles } from './styles';
import { RosterResults } from './RosterResults';
import { SearchResult } from './SearchResult';
import { usePushHistory } from './usePushHistory';
import { useSearchParams } from './useSearchParams';
import { useEventCourseIdentifiers } from './useEventCourseIdentifiers';
import { EventRosterSearch } from './EventRosterSearch';
import { TimerInfo } from '../eventResults/EventResultsComponent';
import { useGetEventResultsQuery } from '../../api/resultsApi'
import ZeroState from '../../components/zeroState/ZeroState'
import { Typography } from '@material-ui/core'
import { useMainStyles } from '../eventAwards/styles'

type Props = {
  races: any[]
  isMobile: boolean
  intl: any
  roster?: {
    page: number
    pageSize: number
    results: any[]
    total: number
  }
  eventResults: any
  error?: any
  isLoading: boolean
  eventCourse: any
  eventCourseId: number
  eventId: number
  eventName: string
  masterId: number
  divisionId?: number
  t?: (message: string, values?: Record<string, any>) => string
  timer?: any
  azp: 'ctlive'
  azpEventId: number | string
}

export const EventRosterViewComponent = ({
  races,
  isMobile,
  intl,
  roster,
  eventResults,
  error,
  isLoading,
  eventCourse,
  eventCourseId,
  eventId,
  eventName,
  masterId,
  divisionId,
  t,
  timer,
  azp,
  azpEventId,
}: Props) => {
  const { term } = useSearchParams()
  const styles = useStyles()
  const mainStyles = useMainStyles()
  const pushHistory = usePushHistory({ masterId, eventId, eventCourseId })

  return (
    <div className={styles.pageViewWrapper}>
      <EventRosterSearch
        masterEventId={masterId}
        eventId={eventId}
        currentFilters={{ eventId, eventCourseId, divisionId }}
        events={races || []}
        pushHistory={pushHistory}
      />

      {!!error && <div>Error</div>}

      {!roster &&
        !error &&
        Array.from({ length: 3 }).map((_, i) => (
          <div key={i} className={styles.loader} />
        ))}

      {roster && (
        <div className='row'>
          <div className='col-12'>
            <Typography className={mainStyles.eventName}>
              {eventName}
            </Typography>
            <Typography className={mainStyles.raceName}>
              {eventCourse?.courseName}
            </Typography>
            {!!roster?.total || !eventResults.length ? (
              <SearchResult
                intl={intl}
                isLoading={isLoading}
                roster={roster}
                term={term}
              />
            ) : (
              <ZeroState
                divisionId={divisionId}
                eventId={eventId}
                masterId={masterId}
                message="We don't have official Rosters for selected Division(s) in Race."
                raceId={eventCourseId}
              />
            )}

            <RosterResults
              azp={azp}
              azpEventId={azpEventId}
              isLoading={isLoading}
              isMobile={isMobile}
              roster={roster}
            />

            <TimerInfo
              timer={timer}
              eventId={eventId}
              isCTLive={true} // this prop will be removed when this component is refactored
              affiliateID={+azpEventId}
              isMobile={isMobile}
              t={t}
            />
          </div>
        </div>
      )}
    </div>
  )
}

type PureProps = {
  intl: any
  isMobile: boolean
  races: any[]
}

const PureEventRosterViewComponent = (props: PureProps) => {
  const { races } = props
  const { eventCourseId, divisionId, eventId, masterId } =
    useEventCourseIdentifiers(props)
  const { page, term, pageSize } = useSearchParams()

  const { data, error, isLoading } = useGetRosterQuery({
    eventCourseId,
    page,
    pageSize,
    divisionId,
    term,
  })

  // see if there's any data at all in the given race
  const {
    data: eventResults,
    error: eventResultsError,
    isLoading: isLoadingEventResults,
  } = useGetEventResultsQuery({
    athlinksEventId: eventId,
    eventCourseId,
    from: 0,
    limit: 1,
  })

  const eventMetadata = useEventMetadata(eventId)

  const eventCourses =
    races?.find((x) => x.raceID === eventId)?.eventCourses || []
  const eventCourse = eventCourses.find(
    (x: any) => x.eventCourseID === eventCourseId
  )

  return (
    <EventRosterViewComponent
      {...props}
      azp={eventMetadata.data.azp}
      azpEventId={eventMetadata.data.azpEventId}
      error={error || eventResultsError}
      eventCourse={eventCourse}
      eventCourseId={eventCourseId}
      eventId={eventId}
      eventName={eventMetadata.data.eventName}
      isLoading={isLoading || isLoadingEventResults}
      masterId={masterId}
      divisionId={divisionId}
      roster={data}
      eventResults={eventResults || []}
    />
  )
}

export const EventRosterView = compose(injectIntl)(PureEventRosterViewComponent) as FC<
  Omit<Props, 'intl'>
>
