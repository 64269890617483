import React  from 'react'
import { IndividualKioskContentHeader } from './IndividualKioskContentHeader'
import { IndividualKioskResultsContent, Result } from './IndividualKioskResultsContent'
import { IndividualKioskShare } from './IndividualKioskShare'
import { CardHeader } from './CardHeader'
import { Loading } from '../shared/Loading'
import { useGetIrpResultQuery } from '../../api/resultsApi'
import { EventMetadata, MasterEvent } from '../../utils/types'
import { makeStyles, Theme } from '@material-ui/core'
import { colors } from '../../shared/styles'

const useStyles = makeStyles<Theme, { isLightMode: boolean }>(() => ({
  card: ({ isLightMode }) => ({
    boxShadow: colors.cardShadow,
    backgroundColor: isLightMode ? colors.white : colors.darkBlue4,
    padding: '1.25rem',
    minWidth: '23em',
    borderRadius: 4,
    maxWidth: 969
  }),
  resultContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

type Interval = {
  intervalName: string
  chipTime: { timeInMillis: number }
  gunTime: { timeInMillis: number }
  mostRecentInterval: boolean
  brackets: {
    bracketName: string
    bracketRankType: string
  }[]
}

export function IndividualKioskContent({
  eventCourseName,
  eventMetadata,
  masterEvent,
  eventDate,
  isLightMode,
  isMobile,
  bib,
  entryId,
  eventCourseId,
  eventId,
  onBack = () => {},
  t
}: {
  eventCourseName: string
  eventMetadata: EventMetadata
  masterEvent: MasterEvent
  eventDate: string
  isLightMode: boolean
  isMobile: boolean
  bib: string
  entryId: number
  eventCourseId: number
  eventId: number
  onBack: () => void
  t: any
}) {
  const styles = useStyles({ isLightMode })

  const { data: result, isFetching: isLoading } = useGetIrpResultQuery(
    {
      eventId,
      eventCourseId,
      bib,
      id: entryId
    },
    {
      pollingInterval: 45_000, // poll every 45 seconds
      refetchOnMountOrArgChange: 60, // cache for 60 seconds
    }
  )

  if (isLoading) return <Loading centered={true} />

  const mappedResult = {
    ...result,
    status: result?.entryStatus,
    intervals: result?.intervals.map((x: Interval) => ({
      ...x,
      name: x.intervalName,
      chipTimeInMillis: x.chipTime?.timeInMillis,
      gunTimeInMillis:  x.gunTime?.timeInMillis,
      divisions: x.brackets.map(x => ({
        ...x,
        name: x.bracketName,
        rankType: x.bracketRankType
      }))
    }))
  } as Result

  return (
    <div className={`row mx-0 ${styles.resultContainer}`}>
      <CardHeader
        eventMetadata={eventMetadata}
        eventDate={eventDate}
        isLightMode={isLightMode}
        onBack={onBack}
        isMobile={isMobile}
        t={t}
      />
      <div className={`col-sm-10 col-md-8 ${styles.card}`}>
        <IndividualKioskContentHeader
          bibNumber={mappedResult.bib}
          isLightMode={isLightMode}
          isLoading={isLoading}
          isMobile={isMobile}
          title={mappedResult.displayName}
        />
        <IndividualKioskResultsContent
          eventCourseName={eventCourseName}
          isLightMode={isLightMode}
          isMobile={isMobile}
          result={mappedResult}
        />
        <IndividualKioskShare
          /*// @ts-ignore */
          t={t}
          eventMetadata={eventMetadata}
          isLightMode={isLightMode}
          isMobile={isMobile}
          masterEvent={masterEvent}
          result={mappedResult}
        />
      </div>
    </div>
  )
}
