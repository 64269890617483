import { EventSummarySearchState } from '../model/eventSummarySearchState';
import { ResultActionTypes } from '../resultsClient/types';

const eventSummarySearchReducer = (state = EventSummarySearchState, action) => {
  switch (action.type) {
    case ResultActionTypes.GET_EVENT_SUMMARY:
      return {
        ...state,
        errorMessage: '',
        isLoading: true,
        results: [],
      };
    case ResultActionTypes.GET_EVENT_SUMMARY_COMPLETE:
      return {
        ...state,
        errorMessage: '',
        isLoading: false,
        results: action.eventSummary,
      };
    case ResultActionTypes.GET_EVENT_SUMMARY_ERROR:
      return {
        ...state,
        errorMessage: 'The event summary search operation was unsuccessful',
        isLoading: false
      };
    default:
      return state;
  }
};

export default eventSummarySearchReducer;
