import {distanceConverter} from '../../../lib/anuket';
import {Component} from 'react';
import PropTypes from 'prop-types';
import {convertPace, convertTime, convertCalendarTime, UnitType } from '@eventops/athlinks-lib-units';
import _ from 'lodash';
import { Typography } from '@material-ui/core';

import {IndividualPhotoGrid} from '../IndividualPhotoGrid';
import {IRPRaceDetails} from './IRPRaceDetails';
import {HeroCardAllDivisions} from '../heroCard/AllDivisions';
import {AthleteCard} from '../heroCard/AthleteCard';
import {isLoggedIn, isAdmin, getTokenRacerId} from '../../../utils/isLoggedIn';
import {FriendsRacing} from '../../ELP/friendsRacing/FriendsRacing';
import {EventResultsStreams} from '../../../data/EventResultsStreams';
import {AthlinksAds} from '../../shared/Banner';
import {getResultsUrl} from '../../../utils/resultsHelpers';
import {styles, splitTable} from './styles';
import {getGender} from '../heroCard/helpers';
import { fetchFollowers } from '../../../data/ProfileStreams'
import { isAFriend } from '../../../shared/util';
import {
  fetchAthlete,
} from '../../../data/AthleteStreams';
import { fetchPendingClaims, ClaimStatus } from '../../../data/UnclaimedSearchStreams'
import {ClaimResultButton} from '../../control/ClaimResult';
import {SocialShare} from '../../shared/SocialShare';
import { OpenGraphTags } from '../../../shared/OpenGraphTags';
import { StandardHeaderTags } from '../../../shared/StandardHeaderTags';
import { textStyles } from '../../../shared/styles';
import {
  getBracketResults,
  getFullInterval,
  getLatestScoredInterval,
  getTime,
  isDNF,
  isDQ,
  isUnranked,
  shouldShowRankings,
  resolveClaimButtonProps,
  getOgTags,
  showPace,
  getTimeLabel
} from '../../../utils/IRPUtil';
import {isGunTime} from '../../../utils/ERPUtil';
import {RaceSummary} from './IRPRaceSummary';
import {EventHeader} from './IRPEventHeader';
import {FinalTime} from './IRPFinalTime';
import {Promo} from './IRPPromo';
import {
  hasResultInEventCourse,
  getClaimStatusMessage,
  getClaimStatusDescription,
  getPendingClaimForResult
} from '../../../utils/ClaimUtil';
import { fetchAthleteRaces } from '../../../data/AthleteStreams'
import {HelpIcon, RightChevronWhite} from '../../shared/icons';
import {Tooltip} from '../../tooltip/Tooltip';
import {Loading} from '../../shared/Loading';
import {VRPostTimeModal, mapLinkToTrackerName, isValidActivityUrl} from './VRPostTimeModal';
import {setGlobalUnitTypeToMetric, setGlobalUnitTypeToImperial, setGlobalUnitTypeToDefault } from '../../../utils/setGlobalUnitType';
import {ClaimActionTypes as ClaimAction} from '../../../actions/types';
import {configs} from '../../../configs'
import {mapCheckAction} from '../../../actions/mapTrackingAction'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import { getDisplayLocationString } from '../../../resultsClient/getDisplayLocationString';
import { ResultActionTypes } from '../../../resultsClient/types';
import { DistanceTypeId } from '../../../utils/types'
import AdOverlay from '../../adOverlay/AdOverlay';
import { RaceNameHeader } from './IRPRaceNameHeader'; 
import { distanceTypeToUnitType } from '../../../utils/distanceTypeToUnitType'
import convertPaceDistanceUnit from '../../../utils/convertPaceDistanceUnit'
import { IRPAwardSection } from './IRPAwardSection';

const shouldShowOnMobile = () => window.innerWidth < 600;

export class IRPBodyContainerComponent extends Component {
  static propTypes = {
    awards: PropTypes.array,
    event: PropTypes.object,
    result: PropTypes.object,
    isMobile: PropTypes.bool,
    friends: PropTypes.object,
    sponsorLogos: PropTypes.array,
    image: PropTypes.object.isRequired,
    pendingClaims: PropTypes.object,
    certificate: PropTypes.object,
    entryDetail: PropTypes.object,
    t: PropTypes.func,
    useSse: PropTypes.bool,
    isLive: PropTypes.bool,
  };

  static defaultProps = {
    awards: [],
    splitsContent: {isLoading: true},
    isMobile: false,
    sponsorLogos: [],
    certificate: {fetching: true},
    entryDetail: {fetching: true},
  };

  constructor(props, context) {
    super(props, context);
    this.state = {};
    AthlinksAds.init();
  }

  componentDidMount() {
    const {
      athleteRaces,
      dispatch,
      result: {
        racerId,
        entryId,
        eventCourseId,
      },
    } = this.props;

    dispatch(mapCheckAction(eventCourseId))

    const loggedInId = getTokenRacerId();

    if (racerId){
      if (entryId) {
        EventResultsStreams.setLegacyEntryResult(racerId, entryId);
      }
      fetchAthlete(racerId);
    }

    if (loggedInId) {
      fetchFollowers('followers', loggedInId);
    }

    fetchPendingClaims();

    if (athleteRaces?.fetching && !_.isEmpty(this.props.result)) {
      fetchAthleteRaces(getTokenRacerId());
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {result} = this.props;
    const {racerId, entryId} = result;

    const nextResults = nextProps && nextProps.result;

    if(racerId && nextResults
               && nextResults.racerId
               && entryId
               && nextResults.entryId
               && entryId !== nextResults.entryId) {
      EventResultsStreams.setLegacyEntryResult(nextProps.result.racerId,  nextProps.result.entryId);
    }

    if (!_.isEqual(nextProps.result, this.props.result)) {
      if (typeof this.state.recalcPending === 'function') {
        const cb = this.state.recalcPending
        this.setState({recalcPending: false})
        setTimeout(cb)
      }
    }

    // we call this so we can tell if the user has a result for this event course
    if(!this.props.athleteRaces && (nextProps.athleteRaces || {}).fetching) {
      fetchAthleteRaces(getTokenRacerId());
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !_.isEqual(this.props, nextProps)
  }

  isMetricUnitType = () => this.props.unitType === UnitType.METRIC
  isImperialUnitType = () => this.props.unitType === UnitType.IMPERIAL
  getDistanceMeters = () => this.props.race.meters

   getStartTime = () => {
    const {
      result: {racerStartTime, thirdPartyEntryId}
    } = this.props;

    if (!racerStartTime)
      return null

    const {timeInMillis, timeZone} = racerStartTime;
    if (timeInMillis > 0 && timeZone && thirdPartyEntryId) {
      const time = convertCalendarTime(racerStartTime, {capitalize: true, timeFormat: 'h:mm:ss'});
      if(time.time) {
        return [time.time, time.meridiem, time.timeZone];
      }
    }
    return null;
  };

  getFinalTime = () => {
    const {
      result,
      result: {status: entryStatus},
      t
    } = this.props;

    if(isDQ(entryStatus)) {
      return t('disqualified');
    }
    else if(isDNF(entryStatus)) {
      return t('did not finish');
    }

    const finalInterval = getLatestScoredInterval(result.intervals);
    return getTime(finalInterval, isGunTime(finalInterval.brackets))
  };

  /**
   * Get latest rankings.
   * Wait for results to load. We find latest
   * interval that has ranks and use those values
   * @return {*}
   */
  getLatestRankings = () => {
    const {
      result
    } = this.props;

    const latestInterval = getLatestScoredInterval(result.intervals);
    if (!latestInterval || _.isEmpty(latestInterval.brackets)) {
      return [];
    }
    else {
      return getBracketResults(latestInterval.brackets);
    }
  };

  getRankingTitle = () => {
    const {
      result: {intervals, isLoading, isLive},
      t
    } = this.props;

    if(!isLoading && isLive) {
      const interval = getLatestScoredInterval(intervals);
      const {intervalId: id, intervalName: name} = interval;
      const intervalName = id === 'finish' ? t('Finish') : name;

      return `${t('Ranking {{interval}}', {interval: intervalName})}`;
    }

    return t('Ranking');
  };

  claimCallback = async (isClaimed) => {
    const {
      result
    } = this.props;

    this.props.dispatch({
      type: ClaimAction.AFTER_CLAIM_INDIVIDUAL_RESULTS_UPDATE,
      racerId: isClaimed ? getTokenRacerId() : 0
    })

    await EventResultsStreams.fetchEntryResultByEventId(result);
    fetchAthleteRaces(getTokenRacerId(), true);
    fetchPendingClaims();
  };

  onImperialViewClicked = _.flow(
    () => this.isImperialUnitType() ? setGlobalUnitTypeToDefault : setGlobalUnitTypeToImperial,
    (action) => action(this.props.dispatch)
  )

  onMetricViewClicked = _.flow(
    () => this.isMetricUnitType() ? setGlobalUnitTypeToDefault : setGlobalUnitTypeToMetric,
    (action) => action(this.props.dispatch)
  )

  refreshResult = ({ticks, brackets}) => {
    const newResult = JSON.parse(JSON.stringify(this.props.result))
    const latestInterval = getLatestScoredInterval(newResult.intervals)
    latestInterval.brackets = brackets
    if (latestInterval.chipTime) {
      latestInterval.chipTime.timeInMillis = ticks
    }
    if (latestInterval.gunTime) {
      latestInterval.gunTime.timeInMillis = ticks
    }
    if (latestInterval.timeWithPenalties) {
      latestInterval.timeWithPenalties.timeInMillis = ticks
    }
    if (latestInterval.pace?.time) {
      latestInterval.pace.time.timeInMillis = ticks
    }

    // hack alert
    // optimistic UI update in anticipation of the next result pipeline update
    this.props.dispatch({
      type: ResultActionTypes.GET_INDIVIDUAL_RESULTS_UPDATE,
      result: newResult,
    })
  }

  renderMapTracking = () => {

    const {
      result: {eventId, eventCourseId, thirdPartyEntryId},
    } = this.props;

    const mapUrl = `${configs.mapTrackingUrl}/map?event-id=${eventId}` +
       `&event-course-id=${eventCourseId}&entry-id=${thirdPartyEntryId}` +
       `&referrer=IRP`

    return (
      <div style={styles.mapTrackingContainer}>
        <div className="row">
          <div className='col-6' style={{paddingTop: 10}}>
            COURSE PROGRESS
          </div>
          <div className='col-6'>
            <a style={styles.viewMapBtn} target='_blank' rel="noopener noreferrer" href={mapUrl}>
              View On Map
            </a>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      athleteId,
      athleteRaces,
      certificate,
      event = {},
      eventMetadata,
      entryDetail,
      friends,
      image,
      isMobile,
      media,
      mapTracking: {mapPresent},
      race,
      result,
      result: {status: entryStatus},
      sponsorLogos,
      pendingClaims,
      isLive, 
      t
    } = this.props;

    if (!result || !pendingClaims || !eventMetadata || !event || !athleteRaces ||
      result.fetching || result.loading ||
      pendingClaims.fetching || pendingClaims.loading ||
      eventMetadata.fetching || eventMetadata.loading ||
      event.loading || event.fetching ||
      athleteRaces.loading || athleteRaces.fetching || result.isAzpEntryLoading) {
      return <Loading centered={true} />
    }

    if (!result?.entryId // results.athlinks.com backwards-compatibility
      && !result?.id // reignite-api uses id in lieu of entryId
      && !result?.bib
    ) {
      return <Typography style={styles.athleteNotFound}>Athlete not found.</Typography>
    }

    const {intervals = []} = result;
    const pendingClaim = getPendingClaimForResult(pendingClaims, result)
    const endTimeEpoch = eventMetadata?.data?.eventEndDateTime?.timeInMillis ?
      parseInt(eventMetadata?.data?.eventEndDateTime?.timeInMillis) : undefined
    const startTimeEpoch = eventMetadata?.data?.eventStartDateTime?.timeInMillis ?
      parseInt(eventMetadata?.data?.eventStartDateTime?.timeInMillis) : undefined

    const usingGunTime = isGunTime(getLatestScoredInterval(result.intervals).brackets || []);
    const latestTime = this.getFinalTime();

    const loggedIn = isLoggedIn();
    const tokenRacerId = loggedIn ? getTokenRacerId() : 0;
    const isFriend = friends && !friends.fetching && isAFriend(friends.list, result.racerId);
    const isMe = loggedIn ? (!!pendingClaim || tokenRacerId === result.racerId) : false

    const claimStatus = pendingClaim
      ? pendingClaim.claimProcessingStatus
      : isMe ? ClaimStatus.claimed
      : undefined
    const claimedResultInEventCourse = athleteRaces && result && hasResultInEventCourse(athleteRaces.races, result.eventCourseId)

    const gender = result && getGender(result.gender, t);

    const resultLocation = result && getDisplayLocationString(result.locality, result.region, result.country);
    const resultsUrl = getResultsUrl(event.masterId, event.athlinksId, this.props.race.eventCourseId);
    const entryIsTeam = result.entryType === 'TEAM';
    const showRankings = shouldShowRankings(entryStatus);
    const latestInterval = getLatestScoredInterval(intervals);
    const shouldShowPace = showPace(latestInterval.pace);

    const latestIntervalName = latestInterval.intervalName;
    const timeLabel = getTimeLabel(result, usingGunTime, t);

    const fullInterval = getFullInterval(result.intervals);
    const altTime = convertTime(usingGunTime
      ? {...fullInterval.chipTime, timeUnit: 'h'}
      : {...fullInterval.gunTime, timeUnit: 'h'}
    ).value;

    const pace = shouldShowPace
      ? convertPace(convertPaceDistanceUnit(latestInterval.pace, this.props.unitType))
      : { value: 0, label: '' };

    const adUnits = AthlinksAds.adsForPage('IndividualResults');
    const {ogTitle, ogDescription, ogImage} = getOgTags(result, certificate, image, t);
    const isImperialUnitType = this.isImperialUnitType()
    const isMetricUnitType = this.isMetricUnitType()
    const buttonsProps = {
      event,
      isMobile
    };

    const eventCourseMetadata = eventMetadata.data?.eventCourseMetadata?.find(
        x => x.eventCourseId === result.eventCourseId
    )
    const metadataIntervals = eventCourseMetadata?.metadata?.intervals || []
    const distanceUnit = metadataIntervals[0]?.distanceUnit || 'mi'
    const distanceType = !isMetricUnitType && !isImperialUnitType
      ? DistanceTypeId[distanceUnit]
      : isImperialUnitType ? DistanceTypeId.mi : DistanceTypeId.km

    const distance = distanceConverter.distanceTo(
      this.getDistanceMeters(),
      DistanceTypeId.m,
      distanceType
    ).toFixed(1)

    const claimButtonProps = resolveClaimButtonProps(athleteId, result, isMobile, isLive,
      event, resultLocation, loggedIn, isMe, claimStatus, claimedResultInEventCourse, this.claimCallback, latestTime, t);

    const displayMapLink = mapPresent
    const learnMoreLink = {
      name:'Learn More',
      link: 'https://athlinks.zendesk.com/hc/en-us/articles/360045124774-VIRTUAL-RACE-FAQ',
      target: '_blank',
    }
    const LearnMoreBox = withStyles({
      root: (isMobile) => ({
        height: 70,
        background: '#16A9E1',
        width: '100%',
        color: '#FFFFFF',
        fontFamily: 'ProximaNovaBold',
        fontSize: isMobile ? 14 : 16,
        marginBottom: 20,
        borderRadius: 3,
        paddingLeft: isMobile ? 15 : 20,
        paddingTop: 15,
        paddingBottom: 15,
        lineHeight: '20px',
        boxShadow: '0 4px 7px 0 rgba(0,0,0,0.10)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        transition: 'all 0.25s ',
        '&:hover': {
          background: '#0092C9',
          boxShadow: '0 7px 12px 0 rgba(0,0,0,0.15)',
          border: 'none',
        },
      }),
    })(Box);

    const anonAndNoTime = !loggedIn && `${latestTime}`.trim() === '0:00' && claimButtonProps.isClaimed
    const isVirtualAndClaimedByMe = result.isVirtual === true &&
      !this.state.recalcPending &&
      !!claimStatus && claimStatus !== ClaimStatus.claimable &&
      isMe
    const timePostingAllowedByDate = (startTimeEpoch === endTimeEpoch) || !endTimeEpoch || endTimeEpoch > Date.now()
    const allowedAndShowDate = timePostingAllowedByDate && (startTimeEpoch !== endTimeEpoch || !endTimeEpoch)
    const showPostTimeModal = (anonAndNoTime || isVirtualAndClaimedByMe)

    const validDetailURI = isValidActivityUrl(entryDetail?.DetailUri?.trim()) ? entryDetail?.DetailUri?.trim() : false
    const validDetailURILabel = validDetailURI ? mapLinkToTrackerName(validDetailURI) : null
    const hasNonEmptyResult = !_.isEmpty(result)

    return (
      <div>
        <AdOverlay
          isMobile={isMobile}
          athlinksEventId={event.athlinksId}
          sponsorLogos={sponsorLogos}
          isLive={isLive}
        />
        <div style={styles.columnsWrapper(isMobile)}>
          {
            // Left column cards
          }
          <div style={styles.leftColumn}>
            <div style={styles.cardContainer}>
              <AthleteCard
                hideAddFriend={loggedIn && tokenRacerId === result.racerId}
                gender={gender}
                result={result}
                athleteId={result.racerId}
                homeTown={resultLocation}
                friends={friends}
                isFriend={isFriend}
                isMe={isMe}
                isMobile={isMobile}
                event={event}
                shouldShowFriends={shouldShowOnMobile()}
                isLoggedIn={loggedIn}
                isPublicProfile={result.isProfilePublic}
              />
            </div>
            
            {
              result.teamRoster &&
                <div style={styles.cardContainer}> 
                  <h3 style={splitTable.title}>Relay Team Members</h3>
                  {result.teamRoster.map(({ age, bib, displayName, gender }) => {
                    const bibDisplay = !bib ? '' : <span>Bib {bib} </span>
                    const genderDisplay = gender === 'NOT SPECIFIED' ? '' : <span> {gender}</span>
                    const ageDisplay = age === 0 ? '' : <span> {age}</span>
                    return (
                      <div>
                        <span style={{ fontFamily: 'ProximaNovaBold' }}>{displayName} </span>
                        {bibDisplay}
                        {(genderDisplay || ageDisplay) &&
                          <span style={{ color: 'rgb(155, 155, 155)' }}>
                            |{genderDisplay}{ageDisplay}
                          </span>
                        }
                      </div>
                    )
                  })}
                </div>
            }

            {
              shouldShowOnMobile() &&
              <FriendsRacing
                type="IRP"
                isMobile={isMobile}
                event={event}
                athleteId={result.racerId}
              />
            }
            {
              !isMobile &&
              <Promo
                sponsors={sponsorLogos}
                adUnits={adUnits}
                isMobile={isMobile}
                t={t}
              />
            }
          </div>
          {
            // Right/main column cards
          }
          <div style={styles.rightColumn}>
            <div className="col-12" style={styles.cardContainer} id='event-header-irp'>
              <EventHeader resultsUrl={resultsUrl} event={event} t={t} />
            </div>

            <IRPAwardSection
              awards={this.props.awards}
              eventCourseId={result.eventCourseId}
              eventId={result.eventId}
              masterId={event.masterId}
            />

            {
              result.isVirtual === true ?
                <a
                  target="_blank" rel="noopener noreferrer"
                  href={learnMoreLink.link}
                  name={learnMoreLink.name}>
                  <LearnMoreBox>
                    <div style={{flexDirection: 'column'}}>
                      <div>This is an Athlinks Virtual Race</div>
                      <div style={styles.VRBannerSubtext(isMobile)}>Claim your race and post your time</div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                      <div style={styles.VRBannerButtonText(isMobile)}>
                        Learn More
                      </div>
                      <div style={styles.VRBannerChevron(isMobile)}>
                        <RightChevronWhite/>
                      </div>
                    </div>
                  </LearnMoreBox>
                </a> : null
            }
            {displayMapLink ? this.renderMapTracking() : null}

            <div style={styles.cardContainer}>
              <div className="row">
                <RaceNameHeader
                  raceName={result.raceName}
                  isVirtualRace={result.isVirtual}
                  validDetailUri={validDetailURI}
                  validDetailUriLabel={validDetailURILabel}
                  onClickImperialView={this.onImperialViewClicked}
                  onClickMetricView={this.onMetricViewClicked}
                  isMetricTypeSelected={isMetricUnitType}
                />

                <div className="col-12 col-sm-6">
                  <div style={isMobile ? styles.cardSectionTitle : { ...styles.cardSectionTitle, margin: '0 0 13px' }}>
                    {t('Result')}
                  </div>

                  {latestTime !== 0 && latestTime !== '0:00' &&
                  <div>
                    <FinalTime
                      time={latestTime}
                      timeLabel={timeLabel}
                      pace={pace.value}
                      showPace={showRankings && shouldShowPace}
                      isMobile={isMobile}
                      isVirtual={result.isVirtual}
                      minAllowedTicks={race.minAllowedTicks}
                      maxAllowedTicks={race.maxAllowedTicks}
                      raceEndDate={race.raceEndDate}
                      paceUnits={pace.label}
                      raceName={result.raceName}
                      latestIntervalName={latestIntervalName}
                      courseType={this.props.courseType}
                      t={t}
                    />
                  </div>
                    }
                  <div style={styles.VRLinkContainer}>
                    {isAdmin() && entryDetail?.EntryUniqueID && (
                      <div>
                        <a href={`/vr/${entryDetail.EntryUniqueID}`} target="_blank" rel="noopener noreferrer">Link to VIP</a>
                      </div>
                    )}
                    {showPostTimeModal && (
                      <div>
                        {timePostingAllowedByDate ? (
                          <div>
                            <VRPostTimeModal
                              claimStatus={claimStatus}
                              eventCourseId={result.eventCourseId}
                              entryDetail={entryDetail}
                              maxAllowedTicks={result.maxAllowedTicks}
                              minAllowedTicks={result.minAllowedTicks}
                              onUpdate={this.refreshResult}
                              isMobile={isMobile}
                              result={result}
                              />
                            {allowedAndShowDate && (
                              <div style={{
                                ...textStyles.sectionHead,
                                margin: 8,
                                textAlign: 'center'
                              }}>
                                Posting Ends {new Date(endTimeEpoch).toLocaleString()}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div style={{
                            ...textStyles.sectionHead,
                            margin: 8,
                            textAlign: 'center'
                          }}>
                          Posting Ended
                          </div>
                        )
                      }
                    </div>
                    )}
                  </div>

                  {
                    showRankings &&
                    <div style={isMobile ? {marginBottom: 35} : null} id="hero-card-all-division">
                      <HeroCardAllDivisions
                        title={this.getRankingTitle()}
                        rankings={this.getLatestRankings()}
                        isUnranked={isUnranked(entryStatus)}
                        t={t}
                      />
                    </div>
                  }
                </div>
                <div className="col-12 col-sm-6" style={!isMobile ? {paddingLeft: 40} : {}} id="race-summary">

                  <RaceSummary
                    altTime={altTime}
                    distance={distance}
                    unitType={distanceTypeToUnitType(distanceType)}
                    startTime={this.getStartTime(result)}
                    event={event}
                    isGunTime={usingGunTime} // TODO: do we need the athlete confirmed check?
                    thirdParty={!!result.thirdPartyEntryId}
                  />
                  <div className='row'>
                    <div className="col-9" id='claim-result'>
                      {
                        !entryIsTeam && hasNonEmptyResult &&
                        <div>
                          <ClaimResultButton {...claimButtonProps} />
                          {
                            claimStatus && claimStatus !== ClaimStatus.claimed &&
                            <div style={styles.claimDescription}>
                              {getClaimStatusMessage(claimStatus, t)}
                              <Tooltip
                                icon={<HelpIcon style={styles.claimTooltipIcon}/>}
                                tip={{
                                  id: `${claimStatus}-tooltip`,
                                  content: getClaimStatusDescription(claimStatus, t)
                                }}
                                style={styles.claimTooltip}
                              />
                            </div>
                          }
                        </div>
                      }
                    </div>
                    <div className="col-3 px-0">
                      <StandardHeaderTags
                        title={ogTitle}
                        description={ogDescription}
                      />
                      <OpenGraphTags
                        ogType='website'
                        title={ogTitle}
                        description={ogDescription}
                        {...ogImage}
                      />
                      <SocialShare
                        buttonsProps={buttonsProps}
                        isMobile={isMobile}
                        useFbIcon={true}
                        location="IRP"
                        socialShareIconStyle={{padding: '7px 12px 11px 13px'}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Timing Card */}
            <div style={styles.cardContainer}>
              {
                <div style={{backgroundColor: 'white'}}>
                  <h3 style={splitTable.title}>
                    {t('Race Splits')}
                  </h3>
                  <IRPRaceDetails
                    entryStatus={result.status}
                    intervals={result.intervals}
                    isLoading={this.props.isLoading}
                    isMobile={isMobile}
                    t={t}
                  />
                </div>
              }
            </div>
            {
              hasNonEmptyResult && latestTime !== 0 && latestTime !== '0:00' && certificate.url ?
              <IndividualPhotoGrid
                event={{photoServiceType: 'Free'}}
                result={result}
                isMobile={isMobile}
                certificateUrl={certificate.url}
              /> :
              <div style={styles.cardContainer}>
                <NoCert t={t} isLoading={certificate.fetching} error={certificate.error} />
              </div>
            }
            {
              hasNonEmptyResult &&
              <IndividualPhotoGrid
                event={event}
                result={result}
                isMobile={isMobile}
                media={media}
              />
            }
            {
              isMobile &&
              <Promo
                sponsors={sponsorLogos}
                adUnits={adUnits}
                isMobile={isMobile}
                t={t}
              />
            }
          </div>
        </div>
      </div>
    );
  }

}

const NoCert = ({t, isLoading, error}) => {
  return (
    <div>
      <h3 style={splitTable.title}>
        {t('Finisher Certificate')}
      </h3>
      {
        !isLoading ?
        <div style={{textAlign: 'center', marginTop: 25, height: 70, color: '#9b9b9b'}}>
          {t(error)}
        </div> :
        <div style={{width: '100%', marginTop: 25, height: 70}}>
          <Loading noTimeout={true} />
        </div>
      }
    </div>
  );
};
