import React, { useEffect, useCallback } from 'react'
import { IndividualKioskContent } from './IndividualKioskContent'
import { trackGoogleEvent } from '../../utils/googleEvents'
import { IndividualKioskContentReignite } from './IndividualKioskContentReignite'
import { EventMetadata, MasterEvent } from '../../utils/types'

type IndividualKioskResultsProps = {
  bib: string
  cancelSelection: () => void
  isLightMode: boolean
  isMobile: boolean
  masterEvent: MasterEvent
  t: any
  usingReigniteResults: boolean
  eventId: number
  eventCourseId: number
  eventCourseName: string
  eventDate: string
  eventMetadata: EventMetadata
  entryId: number
  mode: string
}

const IndividualKioskResultsComponent: React.FC<IndividualKioskResultsProps> = ({
   bib,
   cancelSelection,
   isLightMode,
   isMobile,
   masterEvent,
   t,
   usingReigniteResults,
   eventId,
   eventCourseId,
   eventCourseName,
   eventDate,
   eventMetadata,
   entryId,
   mode
}) => {
  const secondsInactive = React.useRef(0)

  const resetInactivity = useCallback(() => {
    secondsInactive.current = 0
  }, []);

  const trackKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Backspace') {
      resetInactivity();
      cancelSelection();
    }
  }, [cancelSelection, resetInactivity]);

  const startInactivityTimer = useCallback(() => {
    return setInterval(() => {
      secondsInactive.current += 1;
      if (secondsInactive.current >= 20) {
        cancelSelection();
      }
    }, 1000);
  }, [cancelSelection]);

  const addEventListeners = useCallback(() => {
    window.addEventListener('keypress', resetInactivity);
    window.addEventListener('click', resetInactivity);
    window.addEventListener('mousemove', resetInactivity);
    window.addEventListener('keydown', trackKeyDown);
  }, [resetInactivity, trackKeyDown]);

  const removeEventListeners = useCallback(() => {
    window.removeEventListener('keypress', resetInactivity);
    window.removeEventListener('click', resetInactivity);
    window.removeEventListener('mousemove', resetInactivity);
    window.removeEventListener('keydown', trackKeyDown);
  }, [resetInactivity, trackKeyDown]);

  useEffect(() => {
    trackGoogleEvent('Kiosk', 'Visit', `${eventId}-${eventCourseId}-${bib}`);

    let interval: NodeJS.Timeout | undefined;
    if (mode !== 'dev') {
      interval = startInactivityTimer();
    }

    addEventListeners();

    return () => {
      if (interval) clearInterval(interval);
      removeEventListeners();
    };
  }, [eventId, eventCourseId, bib, mode, startInactivityTimer, addEventListeners, removeEventListeners]);

  return (
    <section id='sectionId'>
      {usingReigniteResults ? (
        <IndividualKioskContentReignite
          eventCourseName={eventCourseName}
          bib={bib}
          entryId={entryId}
          eventCourseId={eventCourseId}
          eventId={eventId}
          eventDate={eventDate}
          eventMetadata={eventMetadata}
          isLightMode={isLightMode}
          isMobile={isMobile}
          masterEvent={masterEvent}
          onBack={cancelSelection}
          t={t}
        />
      ) : (
        <IndividualKioskContent
          eventCourseName={eventCourseName}
          bib={bib}
          entryId={entryId}
          eventCourseId={eventCourseId}
          eventId={eventId}
          eventDate={eventDate}
          eventMetadata={eventMetadata}
          isLightMode={isLightMode}
          isMobile={isMobile}
          masterEvent={masterEvent}
          onBack={cancelSelection}
          t={t}
        />
      )}
    </section>
  );
};

export default IndividualKioskResultsComponent;
