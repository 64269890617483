import React from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { hideModal, goToPage } from '../../actions/modalActions'
import { addEventWizardModal as style } from './addEventStyles'
import { RightChevronRegular } from '../shared/icons'
import { DateEntry } from '../shared/DateEntry/DateEntry';
import { EventDetails } from '../shared/event/EventDetails';
import { ResultsLink } from '../shared/event/ResultsLink';
import { isInFuture } from '../../utils/date';
import { createEvent } from '../../data/CreateEventApi'

const mapStateToProps = () => ({
  dispatch: undefined
});

class WizardComponent extends React.Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    nextClicked: PropTypes.func,
    nextDisabled: PropTypes.bool
  };

  state = {
    date: undefined,
    resultsLink: undefined
  };

  componentDidMount() {
    this.props.updateProps({
      nextClicked: this.nextClicked
    })
  }

  nextClicked = () => {
    const { city, masterEventID, name, stateProvID } = this.props.event;
    const { date, resultsLink } = this.state;

    createEvent({
      MasterEventId: masterEventID,
      Name: name,
      Url: resultsLink,
      StartDateTime: date,
      StateProvId: stateProvID,
      City: city
    });
  };

  updateParent = () => {
    const { nextDisabled, updateProps } = this.props;
    const { date, resultsLink } = this.state;
    const shouldDisable = !date || !resultsLink;

    if (nextDisabled !== shouldDisable) {
      updateProps({ nextDisabled: shouldDisable });
    }
  };

  dateCallback = (date) => {
    this.setState({ date }, this.updateParent);
  };

  resultsLinkCallback = (resultsLink) => {
    this.setState({ resultsLink }, this.updateParent);
  };

  render() {
    const { date, resultsLink } = this.state;
    const { event, isMobile } = this.props;

    return (
      <div style={style.container(isMobile)}>
        <div className='row' style={style.detailsRow}>
          <div className={`col-12 ${isMobile ? 'p-0' : 'pl-0'}`}>
            <EventDetails
              date={false}
              event={event}
              location={true}
            />
          </div>
        </div>
        <div className='row' style={style.dateLinkRow}>
          <DateEntry
            calendarEnabled={!isMobile}
            callback={this.dateCallback}
            date={date}
            invalidator={isInFuture}
            invalidOverride={!date && !!resultsLink}
          />
          <ResultsLink
            callback={this.resultsLinkCallback}
            isMobile={isMobile}
          />
        </div>
      </div>
    )
  }
}
export const AddEventWizard = compose(
  connect(mapStateToProps),
  withTranslation()
)(WizardComponent);

export class WizardFooterComponent extends React.Component {
  static propTypes = {
    isMobile: PropTypes.bool,
    nextClicked: PropTypes.func,
    nextDisabled: PropTypes.bool
  };

  handleNext = () => {
    const { dispatch, nextClicked } = this.props;

    nextClicked();
    dispatch(goToPage(1));
  };

  render() {
    const {
      nextDisabled = true,
      dispatch,
      isMobile,
      t
    } = this.props;
    const footerStyle = isMobile ? style.footerMobile : {};
    const footerClass = isMobile ? '' : 'row align-items-end justify-content-end';
    const nextText = isMobile ? t('Request') : t('Request Results');

    return (
      <div style={ footerStyle }>
        <div className={footerClass} style={isMobile ? {} : {marginRight: 30}}>
          <button
            disabled={false}
            onClick={() => dispatch(hideModal())}
            style={style.cancelButton}
            tabIndex={-1}
          >
            {t('Cancel')}
          </button>
          <button
            disabled={nextDisabled}
            onClick={this.handleNext}
            style={style.nextButton(nextDisabled, isMobile)}
          >
            <div style={ style.nextButtonInner }>
              <div style={ style.nextButtonText(isMobile) }>
                {nextText}
              </div>
              <RightChevronRegular fill='#fff' height={14} style={ style.rightChevron(isMobile) } width={14}/>
            </div>
          </button>
        </div>
      </div>
    )
  }
}

export const WizardFooter = compose(
  connect(mapStateToProps),
  withTranslation()
)(WizardFooterComponent);

const WizardHeaderComponent = ({ t }) => {
  return (
    <div style={style.headerContainer}>
      <div className='row justify-content-center' style={ style.modalTitle }>
        {t('Add Missing Results')}
      </div>
      <div className='row justify-content-center' style={ style.modalSubtitle }>
        {t('Add the details of the event and link to all results. We\'ll do the rest.')}
      </div>
    </div>
  )
};

export const WizardHeader = withTranslation()(WizardHeaderComponent);
