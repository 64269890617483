import { Box, Snackbar } from "@material-ui/core"
import { useState } from "react"
import { isLoggedIn } from "../../utils/isLoggedIn"
import { FilledStarIcon, OutlinedStarIcon } from "../shared/icons"
import { RemoveConfirmationDialog } from "./RemoveConfirmationDialog"
import { LogInOrSignUpIndependent } from "../modal/LogInOrSignUpIndependent"
import {
  useGetFavoritesQuery,
  useAddFavoriteMutation,
  useRemoveFavoriteMutation
} from "../../api/reigniteApi"

type FavoriteButtonProps = {
  eventId: number
  isMobile: boolean
}

const FavoriteButton = ({
  eventId,
  isMobile,
}: FavoriteButtonProps) => {
  const isUserLoggedIn = isLoggedIn()

  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false)
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const [snackbarOptions, setSnackbarOptions] = useState({
    visible: false,
    message: '',
  })

  const { data: favoriteEventIds = [] } = useGetFavoritesQuery(undefined, {
    skip: !isUserLoggedIn
  })
  const [addFavorite] = useAddFavoriteMutation()
  const [removeFavorite] = useRemoveFavoriteMutation()

  const handleSnackbarOptions = (action: 'added' | 'removed' | 'closed') => {
    switch (action) {
      case 'added':
        setSnackbarOptions({
          visible: true,
          message: 'Event added to favorites',
        })
        break
      case 'removed':
        setSnackbarOptions({
          visible: true,
          message: 'Event removed from favorites',
        })
        break
      case 'closed':
        setSnackbarOptions((prev) => ({ ...prev, visible: false }))
        break
    }
  }

  const handleFavoriteClick = async () => {
    if (!isUserLoggedIn) {
      setIsAuthDialogOpen(true)
      return
    }

    const isFavorited = favoriteEventIds.includes(eventId)
    
    if (isFavorited) {
      setIsConfirmationDialogOpen(true)
    } else {
      try {
        await addFavorite(eventId).unwrap()
        handleSnackbarOptions('added')
      } catch (error) {
        setSnackbarOptions({
          visible: true,
          message: 'Failed to add favorite. Please try again.',
        })
      }
    }
  }

  const onSubmitRemoveFavorite = async () => {
    try {
      await removeFavorite(eventId).unwrap()
      handleSnackbarOptions('removed')
    } catch (error) {
      setSnackbarOptions({
        visible: true,
        message: 'Failed to remove favorite. Please try again.',
      })
    }
    setIsConfirmationDialogOpen(false)
  }

  const isFavorited = favoriteEventIds.includes(eventId)

  return (
    <>
      <Box marginRight='8px' onClick={handleFavoriteClick} style={{ cursor: 'pointer' }}>
        {isFavorited ? <FilledStarIcon /> : <OutlinedStarIcon />}
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={snackbarOptions.visible}
        autoHideDuration={2500}
        onClose={() => handleSnackbarOptions('closed')}
        message={snackbarOptions.message}
      />

      <RemoveConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClickClose={() => setIsConfirmationDialogOpen(false)}
        onClickSubmit={onSubmitRemoveFavorite}
      />

      <LogInOrSignUpIndependent
        firstSlideType='add-to-favorites'
        isMobile={isMobile}
        open={isAuthDialogOpen}
        closeAction={() => setIsAuthDialogOpen(false)}
      />
    </>
  )
}

export { FavoriteButton }
