import { makeStyles, Theme, Typography } from "@material-ui/core"

import { VRBadge } from "../../shared/VRBadge"
import { fonts } from "../../../shared/styles"

const measurementTypeButton = {
  cursor: 'pointer',
  outline: 'none',
  border: 'none',
  padding: '6px 0',
  width: '2.9375rem',
  transition: 'all 0.2s',
  ...fonts.default,
}

const useRaceNameHeaderStyles = makeStyles<Theme, { isMetricTypeSelected: boolean }>(({ palette }) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '0 16px',
    gap: '8px',
    marginBottom: '16px',
  },
  badgesContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '4px',
  },
  raceNameText: {
    color: palette.grey[800],
		fontSize: 20,
    lineHeight: '24px',
    letterSpacing: 0.4,
		...fonts.bold,
  },
  metricTypeButtonsContainer: {
    height: 'fit-content',
  },
  imperialTypeButton: ({ isMetricTypeSelected }) => ({
    ...measurementTypeButton,
    borderRadius: '6px 0 0 6px',
    border: isMetricTypeSelected ? `1px solid ${palette.grey[300]}` : `1px solid ${palette.brandColor}`,
    borderRight: 'none',
    background: isMetricTypeSelected ? '#fff' : palette.brandColor,
    color: isMetricTypeSelected ? palette.grey[700] : '#fff',

    '&:hover': {
      background: isMetricTypeSelected ? palette.grey[200] : palette.brandColor,
    },
  }),
  metricTypeButton: ({ isMetricTypeSelected }) => ({
    ...measurementTypeButton,
    borderRadius: '0 6px 6px 0',
    border: isMetricTypeSelected ? `1px solid ${palette.brandColor}` : `1px solid ${palette.grey[300]}`,
    borderLeft: 'none',
    background: isMetricTypeSelected ? palette.brandColor : '#fff',
    color: isMetricTypeSelected ? '#fff' : palette.grey[700],

    '&:hover': {
      background: isMetricTypeSelected ? palette.brandColor : palette.grey[200],
    },
  }),
}))

type RaceNameHeaderProps = {
  raceName?: string
  isVirtualRace: boolean
  validDetailUri?: string
  validDetailUriLabel?: string
  isMetricTypeSelected: boolean
  onClickImperialView: () => void
  onClickMetricView: () => void
}

const RaceNameHeader = ({
  raceName,
  isVirtualRace,
  validDetailUri,
  validDetailUriLabel,isMetricTypeSelected,
  onClickImperialView,
  onClickMetricView,
}: RaceNameHeaderProps) => {
  const styles = useRaceNameHeaderStyles({ isMetricTypeSelected })

  return (
    <div className={styles.container}>
      <div>
        <Typography className={styles.raceNameText}>{raceName}</Typography>

        <div className={styles.badgesContainer}>
          {isVirtualRace && <VRBadge />}

          {validDetailUri && (
            // @ts-ignore
            <VRBadge style={{ borderColor: 'red', color: 'white', 'backgroundColor': 'red' }}>
              <a style={{ color: 'white' }} href={validDetailUri} target="_blank" rel="noopener noreferrer">View on {validDetailUriLabel}</a>
            </VRBadge>
          )}
        </div>
      </div>

      <div className={styles.metricTypeButtonsContainer}>
        <button className={styles.imperialTypeButton} onClick={onClickImperialView}>MI</button>
        <button className={styles.metricTypeButton} onClick={onClickMetricView}>KM</button>
      </div>
    </div>
  )
}

export { RaceNameHeader }