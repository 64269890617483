import { useEffect } from "react"
import { useGetAzpEventLinkQuery } from "../../api/reigniteApi"
import { Loading } from "../../components/shared/Loading"
import { useMissingData } from "../athleteRacePage/hooks/useMissingData"
import { useShouldDisplayAwardsTab } from '../../hooks/useShouldDisplayAwardsTab'

const AzpAwardResolver = (props: any) => {
  const { azp, azpEventId, azpRaceId, history } = props

  const { data: eventLink, error: eventLinkError, isLoading: isEventLinkLoading } = useGetAzpEventLinkQuery({
    azp,
    azpEventId,
  })

  const { masterId } = useMissingData({ eventId: eventLink?.athlinksEventId || null })

  const { shouldDisplayAwardsTab, isLoading: isMasterMetaLoading } = useShouldDisplayAwardsTab({ masterId })
  
  useEffect(() => {
    if (isMasterMetaLoading || !eventLink || eventLink.races.length === 0 || !masterId) return

    const athlinksEventCourseId = eventLink.races.find(race => race.id === +azpRaceId)?.athlinksEventCourseId
    if (athlinksEventCourseId && shouldDisplayAwardsTab) {
      // Valid awards url must have course id (future could change it to default first course if only eventId provided) 
      const redirectUrl = `/event/${masterId}/awards/Event/${eventLink.athlinksEventId}/Course/${athlinksEventCourseId}`
      history.push(redirectUrl)
    } else {
      let modalTitle = `Race Not Found`
      let modalBody = [`${azp} Race ID ${azpEventId} not found.`, 'Try finding Race Name in Awards Filter.'] 
      if (athlinksEventCourseId && !shouldDisplayAwardsTab) {
        modalTitle = 'No Awards Configured'
        modalBody = ['No awards configured for any event course in master event.']
      }

      // Could not find valid course Id or no awards configured, default to display ERP for now
      history.push(`/Events/${eventLink.athlinksEventId}`, {
        showModal: true,
        modalTitle,
        modalBody,
      })
    }
  }, [eventLink, masterId, shouldDisplayAwardsTab, isMasterMetaLoading])

  if (eventLinkError || (!isEventLinkLoading && (!eventLink || eventLink.races.length === 0))) {
    // Load event search if Event ID not found
    history.push(`/search/events`, {
      showModal: true,
      modalTitle: `Event Not Found`,
      modalBody: [`${azp} Event ID ${azpEventId} not found.`, 'Try Searching by Event Name.']
    })
    return null
  }

  return <Loading centered={true} />
}

export default AzpAwardResolver
