import { ChangeEvent, useCallback, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

import { InputWithFilters, SelectedFiltersItem } from "../../components/inputWithFilters/InputWithFilters"
import { DropDown } from "../../components/shared/Elements"
import { formatEventDate } from "../../utils/formatDate"
import useEventMetadata from '../../hooks/useEventMetadata'

enum FilterKey {
  Event = 'event',
  Race = 'race',
  Division = 'division',
}

type Event = {
  raceID: number
  raceDate: string
  eventCourses: { eventCourseID: number; courseName: string }[]
}

type AwardsFilterProps = {
  currentFilter: {
    eventId: number
    raceId: number
    divisionId?: number
  }
  events: Event[]
  masterId: number
}

const AwardsFilter = ({
  currentFilter,
  events,
  masterId
}: AwardsFilterProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [formValues, setFormValues] = useState(currentFilter)
  const eventMetadata = useEventMetadata(formValues.eventId)

  const divisions = useMemo(
    () => (eventMetadata.data.eventCourseMetadata || []).find((x) => x.eventCourseId === formValues.raceId)?.metadata?.divisions || [],
    [eventMetadata.data.eventCourseMetadata, formValues.raceId]
  )

  const applyFilters = (values: typeof currentFilter) => {
    const { eventId, raceId, divisionId } = values

    history.push(`/event/${masterId}/awards/Event/${eventId}/Course/${raceId}${divisionId ? `/Division/${divisionId}` : ''}`)
  }

  const deleteFilter = (filterValue: string) => {
    const value = filterValue as FilterKey

    let newValues = { ...formValues }

    if (value === FilterKey.Division) {
      newValues = { ...formValues, divisionId: undefined }
    }

    applyFilters(newValues)
  }

  const findEventById = useCallback((eventId: number) => (
    events.find((event) => event.raceID === eventId)
  ), [events])

  const findRaceById = useCallback((eventId: number, raceId: number) => {
    const event = findEventById(eventId)

    return (event?.eventCourses || []).find((race) => race.eventCourseID === raceId)
  }, [findEventById])

  const onChangeEvent = (e: ChangeEvent<HTMLSelectElement>) => {
    const eventId = e.target.value as unknown as number

    const eventRaces = findEventById(eventId)?.eventCourses

    if (eventRaces?.length) {
      setFormValues({ eventId, raceId: eventRaces[0].eventCourseID })
    }
  }

  const eventOptions = useMemo(() => (
    events.map((event) => ({
      text: formatEventDate(event.raceDate),
      value: event.raceID,
    }))
  ), [events])

  const raceOptions = useMemo(() => {
    const races = findEventById(formValues.eventId)?.eventCourses || []

    return races.map((race) => ({
      text: race.courseName,
      value: race.eventCourseID,
    }))
  }, [findEventById, formValues.eventId])

  const divisionOptions = useMemo(() => {
    const options = divisions.map((division) => ({
      text: division.name,
      value: division.id
    }))

    return [{ text: 'All', value: 0 }, ...options]
  }, [divisions])

  const filterChips = useMemo<SelectedFiltersItem[]>(() => {
    const currentEvent = findEventById(currentFilter.eventId)
    const currentRace = findRaceById(currentFilter.eventId, currentFilter.raceId)

    if (!currentEvent || !currentRace) {
      return []
    }

    const chips: SelectedFiltersItem[] = [
      { label: formatEventDate(currentEvent.raceDate), value: FilterKey.Event },
      { label: currentRace.courseName, value: FilterKey.Race },
    ]

    if (currentFilter.divisionId) {
      const division = divisions.find((division) => division.id === currentFilter.divisionId)

      if (division) {
        chips.push({ label: division.name, value: FilterKey.Division, isRemovable: true })
      }
    }

    return chips
  }, [currentFilter.divisionId, currentFilter.eventId, currentFilter.raceId, divisions, findEventById, findRaceById])

  return (
    <InputWithFilters
      selectedFilters={filterChips}
      onClickApply={() => applyFilters(formValues)}
      onClickDeleteFilter={deleteFilter}
      onOpenFilters={() => setFormValues(currentFilter)}
      renderFilters={() => (
        <>
          <DropDown
            id='eventId'
            label={t('Event')}
            value={formValues.eventId}
            disabled={false}
            onChange={onChangeEvent}
            options={eventOptions}
            className={undefined}
            inputStyle={undefined}
          />
  
          <DropDown
            id='raceId'
            label={t('Race')}
            value={formValues.raceId}
            disabled={false}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormValues((prev) => ({ ...prev, raceId: Number(e.target.value), divisionId: 0 }))}
            options={raceOptions}
            className={undefined}
            inputStyle={undefined}
          />
  
          <DropDown
            id='divisionId'
            label={t('Division')}
            value={formValues.divisionId || 0}
            disabled={false}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setFormValues((prev) => ({ ...prev, divisionId: Number(e.target.value)  }))}
            options={divisionOptions}
            className={undefined}
            inputStyle={undefined}
          />
        </>
      )}
    />
  )
}

export { AwardsFilter }