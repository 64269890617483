function detectWindowLocation(x) {
  if (typeof x === 'string' && (x.trim().length === 0 || x.startsWith('/'))) return window.location.origin + x
  return x
}

export const configs = {
  athleteApiUrl: detectWindowLocation(process.env.REACT_APP_ATHLETE_API_URL),
  athleteApiWSUrl: detectWindowLocation(process.env.REACT_APP_ATHLETE_API_WS_URL),
  ATHLINKS_API_HOST: detectWindowLocation(process.env.REACT_APP_ATHLINKS_API_HOST),
  bragiUrl: detectWindowLocation(process.env.REACT_APP_BRAGI_URL),
  cdnUrl: detectWindowLocation(process.env.REACT_APP_CDN_URL),
  chronotrackApiUrl: detectWindowLocation(process.env.REACT_APP_CHRONOTRACK_API_URL),
  chronotrackRegisterUrl: detectWindowLocation(process.env.REACT_APP_CHRONOTRACK_REGISTER_URL),
  customHeroImageUrl: detectWindowLocation(process.env.REACT_APP_CUSTOM_HERO_IMAGE_URL),
  fbAppId: process.env.REACT_APP_FB_APP_ID,
  finisherCertificateUrl: detectWindowLocation(process.env.REACT_APP_FINISHER_CERTIFICATE_URL),
  followingApiBaseUrl: detectWindowLocation(process.env.REACT_APP_FOLLOWING_API_BASE_URL),
  googleApiKey: 'AIzaSyCeb-t1WCXp7PpuCIfCUtusR-41n_xZMRg',
  heroImageUrl: detectWindowLocation(process.env.REACT_APP_HERO_IMAGE_URL),
  hotjarAuthenticated: 466948,
  hotjarUnauthenticated: 736393,
  imgixStaticUrl: detectWindowLocation(process.env.REACT_APP_IMGIX_STATIC_URL),
  imgixPhotosUrl: detectWindowLocation(process.env.REACT_APP_IMGIX_PHOTOS_URL),
  keycloakClientId: 'www',
  keycloakRealm: 'athlinks',
  keycloakUrl: detectWindowLocation(process.env.REACT_APP_KEYCLOAK_URL),
  longPollingTime: 60000 * 5,
  mapS3BucketName: process.env.REACT_APP_MAP_S3_BUCKET_NAME,
  mapTrackingUrl: detectWindowLocation(process.env.REACT_APP_MAP_TRACKING_URL),
  numEventWideLeaderboardResultsPerPage: 3,
  numResultsPerPage: 50,
  recaptchaKey: '6LfZ4nEUAAAAAPddWCAmh_deDwQ81bW-XqMlAhVY',
  resultsApiUrl: detectWindowLocation(process.env.REACT_APP_RESULTS_API_URL),
  rtkQueryMaxRetries: 2,
  ROLLBAR_KEY: process.env.REACT_APP_ROLLBAR_KEY,
  searchCharLimit: 74,
  shortPollingTime: 1000,
  useMockPrinter: window.location.search.includes('useMockPrinter') ?? false,
  WEATHER_API_HOST: detectWindowLocation(process.env.REACT_APP_WEATHER_API_HOST),
};

export const env = window.location.href.startsWith('https://www.athlinks.com')
  ? 'production'
  : window.location.href.startsWith('https://stage.athlinks.com')
    ? 'stage'
    : 'dev';