import { colors } from '../../shared/styles'

const defaultStyles = {
  footer: {
    backgroundColor: 'transparent',
  },
  footerContainer: (isMobile) => ({
    paddingTop: '60px',
    paddingBottom: '50px',
    textAlign: (isMobile) ? 'center' : 'left',
    borderTop: '1px solid '+ colors.greyLight,
  }),

  col_logo: (isMobile) => ({
    marginBottom: 20,
    paddingTop: 3,
    textAlign: (isMobile) ? 'center' : 'left',
  }),

  col_logo_a: {
    display: 'inline-block',
  },
  customNavMobile: { 
    margin:0, 
    display: 'flex', 
    justifyContent: 'center' 
  },
  mobileSocialCol: { 
    display: 'flex', 
    justifyContent: 'center' 
  },
  address_title: {
    marginBottom: 9,
    fontSize: 16,
    fontFamily: 'ProximaNovaBold, sans-serif',
    color: colors.greyType,
  },
  address_line: {
    marginBottom: 9,
    fontSize: 16,
    fontFamily: 'ProximaNovaRegular, sans-serif',
    color: colors.greyType,
  },
  copyright_row: (isMobile) => ({
    textAlign: 'left',
    margin: '15px 0',
    color: colors.greyType,
    fontSize: 14,
    fontFamily: 'ProximaNovaRegular, sans-serif',
    display: (isMobile) ? 'flex' : 'block',
    justifyContent: 'center'
  }),
  copyright_text: (isMobile) => ({
    float: 'left',
    fontSize: isMobile ? 10 : 14,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    whiteSpace: 'nowrap'
  }),
  copyLink: (isMobile) => ({
    color: '#4a4a4a',
    float: 'left',
    marginLeft: isMobile ? 8 : 25,
    fontSize: isMobile ? 10 : 14,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    whiteSpace: 'nowrap'
  }),
  language_col: {
    color: colors.greyType,
    fontSize: 14,
    fontFamily: 'ProximaNovaRegular, sans-serif',
  },
  a: {
    display: 'block',
    marginBottom: 18,
    color: colors.blue,
    fontSize: 16,
    fontFamily: 'ProximaNovaRegular',
  },
  footerImg: {
    width: 135,
    height: 40
  },
  languageSwitcher: (isMobile) => ({
    float: 'left', 
    marginLeft: isMobile ? 8 : 25
  })
};


const styles = () => defaultStyles;
export default styles;