import { Button, Dialog, Typography } from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close';

import { useStylesFavoriteEvent } from "./styles"

type RemoveConfirmationDialogProps = {
  isOpen: boolean
  onClickClose: () => void
  onClickSubmit: () => void
}

const RemoveConfirmationDialog = ({
  isOpen,
  onClickClose,
  onClickSubmit,
}: RemoveConfirmationDialogProps) => {
  const styles = useStylesFavoriteEvent()

  return (
    <Dialog open={isOpen} onClose={onClickClose}>
      <div className={styles.dialogContainer}>
        <div className={styles.dialogHeaderContainer}>
          <Typography className={styles.dialogHeaderTitle}>
            Remove Favorite?
          </Typography>

          <CloseIcon
            className={styles.dialogHeaderCloseIcon}
            onClick={onClickClose}
          />
        </div>

        <Typography>
          Would you like to Remove this Event as a Favorite?
        </Typography>

        <div className={styles.buttonsContainer}>
          <Button className={styles.outlinedButton} onClick={onClickClose}>Keep</Button>
          <Button className={styles.containedButton} onClick={onClickSubmit}>Remove</Button>
        </div>
      </div>
    </Dialog>
  )
}

export { RemoveConfirmationDialog }