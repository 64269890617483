/**
 * Converts a given name string to capital case.
 * It properly handles hyphenated names by capitalizing each part.
 *
 * @param name - The input name to be converted.
 * @returns The name in capital case.
 */
export default function toCapitalCase(name: string): string {
  if (!name) return ''
  return name
    .split(' ')
    .map((word) =>
      word
        .split('-')
        .map((part) => {
          if (part.length === 0) return part
          // capitalize the first letter and make the rest lowercase.
          return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
        })
        .join('-')
    )
    .join(' ')
}
