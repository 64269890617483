import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { InputWithFilters, SelectedFiltersItem } from "../../components/inputWithFilters/InputWithFilters";
import EventResultsBibNameSearch from "../../components/eventResultsSearch/EventResultsBibNameSearch";
import { DropDown } from "../../components/shared/Elements";
import useEventMetadata from "../../hooks/useEventMetadata";
import { formatEventDate } from "../../utils/formatDate";

type EventRosterSearchProps = {
  eventId?: number
  eventResultsFilters: {
    date: number
    course?: number
    division?: number
    split?: number
  }
  currentFilters: {
    eventId: number
    eventCourseId?: number
    divisionId?: number
  }
  events: {
    raceID: number
    raceDate: string
    eventCourses: { eventCourseID: number; courseName: string }[]
  }[]
  masterEventId: number
  pushHistory: (args: { evid?: number; ecid?: number; divisionId?: number; _page: number }) => void
}

const EventRosterSearchComponent = ({
  currentFilters,
  eventId,
  eventResultsFilters,
  events,
  masterEventId,
  pushHistory,
}: EventRosterSearchProps) => {
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState(currentFilters)

  const eventMetadata = useEventMetadata(formValues.eventId)
  const courseData = useMemo(() => 
    eventMetadata?.data.eventCourseMetadata || [],
    [eventMetadata]
  )

  const applyFilters = useCallback((values: typeof formValues) => {
    const { eventId, eventCourseId, divisionId } = values
    pushHistory({ evid: eventId, ecid: eventCourseId, divisionId: divisionId, _page: 1 })
  }, [pushHistory])


  const onClickDeleteFilter = useCallback((filterValue: string) => {
    if (filterValue === 'division') {
      applyFilters({ ...formValues, divisionId: undefined })
    }
  }, [applyFilters, formValues])

  const eventOptions = useMemo(() => {
    const sortedEvents = (events || []).sort((a, b) => {
      const first = Date.parse(a.raceDate);
      const second = Date.parse(b.raceDate);
      return first > second ? -1 : second > first ? 1 : 0;
    })

    return sortedEvents.map((event) => ({
      label: formatEventDate(event.raceDate),
      value: event.raceID
    }))
  }, [events])

  const courseOptions = useMemo(() => {
    return courseData.map(({ eventCourseId, eventCourseName }) => ({ 
      text: eventCourseName, value: eventCourseId 
    }));
  }, [courseData])

  const divisionOptions = useMemo(() => {
    const divisions = courseData.find(({ eventCourseId }) => eventCourseId === formValues.eventCourseId)?.metadata?.divisions || []

    return divisions.map((division) => ({
      text: division.name,
      value: division.id
    }))
  }, [courseData, formValues.eventCourseId])


  const selectedFilters = useMemo<SelectedFiltersItem[]>(() => {
    const filters: SelectedFiltersItem[] = []

    const selectedEvent = eventOptions.find((event) => event.value === currentFilters.eventId)
    const courseData = eventMetadata?.data.eventCourseMetadata || []
    const selectedCourse = courseData.find((course) => course.eventCourseId === currentFilters.eventCourseId)

    if (selectedEvent) {
      filters.push({ label: formatEventDate(selectedEvent.label), value: 'event' })
    }

    if (!selectedCourse) {
      return filters
    }

    filters.push({ label: selectedCourse.eventCourseName, value: 'course' })

    const selectedDivision = currentFilters.divisionId
      ? selectedCourse.metadata.divisions.find(division => division.id === currentFilters.divisionId)
      : { id: 0, name: 'Overall' }

    if (selectedDivision) {
      filters.push({
        label: selectedDivision.name,
        value: 'division',
        isRemovable: selectedDivision.id !== 0
      })
    }

    return filters
  }, [currentFilters.eventId, currentFilters.eventCourseId, currentFilters.divisionId, eventOptions, eventMetadata])

  return (
    <InputWithFilters
      selectedFilters={selectedFilters}
      onClickApply={() => applyFilters(formValues)}
      onClickDeleteFilter={onClickDeleteFilter}
      onOpenFilters={() => setFormValues(currentFilters)}
      renderInput={() => (
        <EventResultsBibNameSearch
          isMobile={false}
          masterEventId={masterEventId}
          eventId={eventId || eventResultsFilters.date}
          eventMetadata={eventMetadata}
        />
      )}
      renderFilters={() => (
        <>
          <DropDown
            id='event'
            label={t('Event')}
            value={formValues.eventId}
            disabled={false}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              const eventId = e.target.value as unknown as number
              const event = events.find((event) => event.raceID === eventId)
              
              setFormValues({
                eventId, 
                eventCourseId: event?.eventCourses[0].eventCourseID, 
                divisionId: undefined
              })
            }}
            options={eventOptions}
            className={undefined}
            inputStyle={undefined}
          />

          <DropDown
            id='course'
            label={t('Race')}
            value={formValues.eventCourseId || courseOptions[0]?.value}
            disabled={false}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setFormValues((prev) => ({ 
                ...prev, 
                eventCourseId: e.target.value as unknown as number, 
                divisionId: undefined 
              }))
            }}
            options={courseOptions}
            className={undefined}
            inputStyle={undefined}
          />

          <DropDown
            id='division'
            label={t('Division')}
            value={formValues.divisionId || divisionOptions[0]?.value}
            disabled={false}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setFormValues((prev) => ({ 
                ...prev,
                divisionId: e.target.value as unknown as number 
              }))
            }}
            options={divisionOptions}
            className={undefined}
            inputStyle={undefined}
          />
        </>
      )}
    />
  );
}

const mapStateToProps = (state: any) => ({
  eventResultsFilters: state.eventResultsFilters
});

export const EventRosterSearch = connect(mapStateToProps)(EventRosterSearchComponent)

