/**
 * Updated version of the AthletePhoto component (src/components/athlete/AthletePhoto.tsx)
 * Added TS, simplified styles, and removed unnecessary code
 */

import { Box, Typography } from "@material-ui/core"
import { useEffect, useState } from "react"

import { getInitials } from "../../shared/util"

import { useAthletePhotoStyles } from "./styles"

type AthletePhotoProps = {
  displayName?: string
  imageUrl: string | null
  isLoading?: boolean
  isMember?: boolean
  size: { mobile: number; desktop: number }
}

const AthletePhoto = ({
  displayName,
  imageUrl,
  isLoading,
  isMember = false,
  size,
}: AthletePhotoProps) => {
  const [isSkeletonActive, setIsSkeletonActive] = useState(true)
  const [isImageError, setIsImageError] = useState(false)

  const styles = useAthletePhotoStyles({ isMember, isSkeletonActive, size })

  useEffect(() => {
    if (!isLoading && !imageUrl) {
      setIsSkeletonActive(false)
    }
  }, [imageUrl, isLoading])

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.container}>
        {isSkeletonActive && <Box className={styles.skeleton} />}

        {(!!imageUrl && !isImageError) ? (
          <img
            alt="Avatar"
            className={styles.picture}
            src={imageUrl}
            onLoad={() => setIsSkeletonActive(false)}
            onError={() => setIsImageError(true)}
          />
        ) : (
          <Typography className={styles.initials}>
            {getInitials(displayName || '') || 'UA'}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export { AthletePhoto }