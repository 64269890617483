import React, { useEffect } from 'react'
import { useGetAzpEventLinkQuery, useGetResultsBibNameSearchQuery } from '../../api/reigniteApi';
import { Loading } from '../../components/shared/Loading';
import { useGetEventCoursesQuery } from '../../api/alaskaApi';

export const AzpResolver = (props: any) => {
  const { azp, azpEventId, bib, history } = props
  const { data: eventLink, error: eventLinkError, isLoading: isEventLinkLoading } = useGetAzpEventLinkQuery({
    azp,
    azpEventId,
  })

  const { data: eventCourses, error: eventCoursesError, isLoading: isEventCoursesLoading } = useGetEventCoursesQuery(+eventLink?.athlinksEventId!, {
    skip: !eventLink || eventLink.races.length === 0
  })
  eventCoursesError && console.error(eventCoursesError)

  const { data: bibResults, error: bibResultsError, isLoading: isBibResultsLoading } = useGetResultsBibNameSearchQuery({
    eventId: +eventLink?.athlinksEventId!,
    from: 0,
    limit: 10,
    term: bib
  }, {
    skip: !bib || !eventLink || eventLink.races.length === 0
  })

  useEffect(() => {
    if (!eventLink || eventLink.races.length === 0) return

    let redirectUrl = `/Event${bib ? '' : 's'}/${eventLink.athlinksEventId}`
    if (!bib) {
      // Load ERP if only Event search and Event ID found
      history.push(redirectUrl)
      return
    }

    if (isEventCoursesLoading) return
    if (!bibResults || bibResults.length === 0) return

    const eventCourseMap = eventCourses.EventCoursesDropDown.reduce((acc: Record<string, string>, curr: any) => {
      acc[curr.Value.split(':')[1]] = curr.Text
      return acc
    }, {})

    const matchingBibs = bibResults.filter(bibResult => bibResult.bib === bib)
    if (matchingBibs.length === 1) {
      // Load IRP if Bib search and 1 Bib found in Event
      redirectUrl += `/Course/${matchingBibs[0].eventCourseId}/Bib/${bib}`
      history.push(redirectUrl)
      return
    } else if (matchingBibs.length >= 1) {
      // Load ERP if Bib search and more than 1 Bib found in Event
      history.push(`/Events/${eventLink.athlinksEventId}`, {
        showModal: true,
        modalTitle: `Select Entry`,
        modalBody: [`Duplicate entries for Bib # ${bib}.`],
        modalActionButtons: matchingBibs.map(({ displayName, eventCourseId }) => ({
          text: `${displayName} - ${eventCourseMap[eventCourseId]}`, redirectUrl: `${redirectUrl}/Course/${eventCourseId}/Bib/${bib}`
        }))
      })
      return
    }
  }, [eventLink, eventCourses, bibResults, bib, history, isEventCoursesLoading])

  if (eventLinkError || (!isEventLinkLoading && (!eventLink || eventLink.races.length === 0))) {
    // Load event search if Event ID not found
    history.push(`/search/events`, {
      showModal: true,
      modalTitle: `Event Not Found`,
      modalBody: [`${azp} Event ID ${azpEventId} not found.`, 'Try Searching by Event Name.']
    })
    return null
  }

  const bibNotFound = !bibResults || bibResults.length === 0 || !bibResults.find(bibResult => bibResult.bib === bib)
  if (
    eventLink && eventLink.races.length > 0 &&
    (bibResultsError || (!isBibResultsLoading && bibNotFound))) {
    // Load ERP if Bib not found
    history.push(`/Events/${eventLink.athlinksEventId}`, {
      showModal: true,
      modalTitle: `Entry Not Found`,
      modalBody: [`Bib #${bib} not found.`, 'Try Searching by Entry Name.']
    })
    return null
  }

  return <Loading centered={true} />
}