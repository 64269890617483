import { useMemo } from 'react'
import { styles, useStyles } from './styles'
import { AthletePhoto } from '../athlete/AthletePhoto'
import { Location } from '../shared/Location'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { configs } from '../../configs'
import toCapitalCase from '../../utils/toCapitalCase'
import { useIsWithLaurel } from '../../hooks/useIsWithLaurel'

type ProfilePreviewProps = {
  url?: string
  useLink?: boolean
  Photos?: string
  RacerId?: number
  DisplayName: string
  City?: string
  StateProvAbbrev?: string
  CountryId3?: string
  Gender?: string
  Age?: number
  Bib?: string
  RaceGoals?: string
  isMobile?: boolean
  showAthletePhoto?: boolean
  claimLink?: React.ReactNode
  nameOnly?: boolean
  showGender?: boolean
  eventCourseId?: number
  athlinksEventId?: number
  thirdPartyEntryId?: number
  isLive?: boolean
  t: (key: string, options?: Record<string, any>) => string
}

export const ProfilePreview: React.FC<ProfilePreviewProps> = (props) => {
  const {
    url,
    useLink,
    Photos,
    RacerId,
    DisplayName,
    City,
    StateProvAbbrev,
    CountryId3,
    Gender,
    Age,
    Bib,
    RaceGoals,
    isMobile,
    showAthletePhoto,
    claimLink,
    nameOnly = false,
    showGender,
    eventCourseId,
    athlinksEventId,
    thirdPartyEntryId,
    isLive,
    t,
  } = props

  const isWithLaurel = useIsWithLaurel({
    eventCourseId,
    athlinksEventId,
    thirdPartyEntryId,
    isLive
  })

  const profileStyles = useStyles({ isWithLaurel })

  const hasLocation = !!(City || StateProvAbbrev || CountryId3)

  const linkClassNames = `athName ${url ? 'athName-display' : ''}`
  const link = url ? (
    <Link
      to={url}
      className={linkClassNames}
      style={styles.profileNameLink(isMobile)}
    >
      {toCapitalCase(DisplayName) || 'Unknown Racer'}
    </Link>
  ) : (
    <span className={linkClassNames}>
      {toCapitalCase(DisplayName) || 'Unknown Racer'}
    </span>
  )

  const genderAgeInfo = useMemo(() => {
    if (Age) {
      return t('{{gender}} {{age}}', { gender: Gender, age: Age })
    }

    if (showGender) {
      return t('{{gender}}', { gender: Gender })
    }

    return null
  }, [Age, Gender, showGender, t])

  return (
    <div style={styles.profileCell}>
      {!isMobile && (
        <div style={styles.profilePhotoContainer}>
          {showAthletePhoto && (
            <AthletePhoto
              isMember={!!RacerId}
              photo={Photos}
              name={DisplayName}
              width={48}
            />
          )}
          {claimLink}
        </div>
      )}
      <div style={{ ...styles.profileNameContainer(isMobile), position: 'relative' }} className='row'>
        <div className='col-starter-row'>
          <div style={styles.profileNameLink(isMobile)}>{link}</div>
        </div>

        {!nameOnly && (
          <div className={profileStyles.profileInfoContainer}>
            {isWithLaurel && (
              <img
                src={`${configs.cdnUrl}/svg/laurel_icon_small.svg`}
                alt='Laurel Wreath Left Side'
              />
            )}

            {!!genderAgeInfo && (
              <Typography className={profileStyles.profileInfoText}>
                {genderAgeInfo}
              </Typography>
            )}

            {!!Bib && (
              <>
                {!!genderAgeInfo && (
                  <div className={profileStyles.profileInfoDivider} />
                )}
                <Typography className={profileStyles.profileInfoText}>
                  #{Bib}
                </Typography>
              </>
            )}

            {hasLocation && (
              <div className={profileStyles.profileInfoDivider} />
            )}

            <Location
              city={City}
              state={StateProvAbbrev}
              country={isMobile ? '' : CountryId3}
              searchType={useLink ? 'athletes' : undefined}
              icon={null}
              className={profileStyles.profileLocation}
            />
          </div>
        )}

        {!nameOnly && RaceGoals && (
          <div className='col-12 pl-0' style={styles.goalsQuote}>
            "{RaceGoals.replace(/'\\/g, '')}"
          </div>
        )}
      </div>
    </div>
  )
}

ProfilePreview.defaultProps = {
  useLink: true,
}
