import { useMemo } from 'react'
import moment from 'moment'
import useEventMetadata from './useEventMetadata'

export function useIsEventLive(eventId: number): boolean {
  const { data: eventMetadata } = useEventMetadata(eventId)

  const timestamps = useMemo(
    () => ({
      start: eventMetadata?.eventStartDateTime?.timeInMillis,
      end: eventMetadata?.eventEndDateTime?.timeInMillis,
    }),
    [eventMetadata]
  )

  const isEventLive = useMemo(() => {
    if (!timestamps.start || !timestamps.end) {
      return false
    }

    const now = moment()
    return now.isBetween(moment(timestamps.start), moment(timestamps.end))
  }, [timestamps])

  return isEventLive
}
