import { EventResultsComponent } from './EventResultsComponent'
import { EventResultsProps } from '../../utils/types'
import { useGetRaceLeaderboardResultsQuery } from '../../api/reigniteApi'
import transformReigniteRaceLeaderboardResults from '../../utils/transformReigniteRaceLeaderboardResults'
import useSse from '../../lib/firebase/useSse'
import { ReigniteEventSseResults } from './ReigniteEventSseResults'
import { useEffect, useMemo, useState } from 'react'
import { RaceLeaderboardResults } from '../../types.results.reignite'

export function ReigniteRaceLeaderboardResults(
  props: EventResultsProps & {
    athlinksEventId: number
    divisionId?: number
    eventCourseId: number
    from: number
    isLive: boolean
    limit: number
    selectedEvent?: {
      event?: {
        ctliveId?: number
      }
    }
  }
) {
  const {
    athlinksEventId,
    divisionId,
    eventCourseId,
    from,
    isLive,
    limit,
  } = props

  const usingSse = useSse(athlinksEventId) && isLive
  const {
    currentData: data,
    isError,
    isFetching,
    refetch,
  } = useGetRaceLeaderboardResultsQuery(
    {
      eventId: athlinksEventId,
      eventCourseId,
      divisionId: divisionId || 0,
      from,
      limit,
    },
    {
      pollingInterval: isLive && !usingSse
        ? 30_000
        : undefined,
      skip: !eventCourseId,
    }
  )
  const [lastSuccessfulFetch, setLastSuccessfulFetch] = useState<RaceLeaderboardResults | null>(null)

  useEffect(() => {
    if (data && !isError) {
      setLastSuccessfulFetch(data)
    }
  }, [data, isError])

  const isLoading = isFetching && !lastSuccessfulFetch
  const mapped = useMemo(() => {
      return lastSuccessfulFetch
      && lastSuccessfulFetch?.intervals?.some(x => x.results.length)
        ? transformReigniteRaceLeaderboardResults({
          data: lastSuccessfulFetch,
          eventId: athlinksEventId,
          isLinked: !!props.selectedEvent?.event?.ctliveId,
        })
        : []
    }
    , [lastSuccessfulFetch, athlinksEventId, props.selectedEvent])

  return (
    <>
      {usingSse && <ReigniteEventSseResults
        athlinksEventId={athlinksEventId}
        divisionId={divisionId}
        eventCourseId={eventCourseId}
        refetch={refetch}
      />}
      <EventResultsComponent
        {...props}
        fetchEventResultsError={isError && !lastSuccessfulFetch}
        eventResults={{
          data: mapped,
          isLoading,
        }}
        isFetchingEventResults={isFetching}
        refetch={refetch}
      />
    </>
  )
}
