import { makeStyles, Theme } from "@material-ui/core";

const useExplanationStyles = makeStyles(({ palette }) => ({
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
  },
  dialogTitle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '32px',
    margin: '24px 0',
    textAlign: 'center',
  },
  dialogMainDescription: {
    color: 'rgba(0, 0, 0, 0.60)',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',

    '& span': {
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 700,
    }
  },
  dialogFooterDescription: {
    color: 'rgba(0, 0, 0, 0.60)',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  dialogButton: {
    borderColor: palette.brandColor,
    color: palette.brandColor,
    fontWeight: 400,
    margin: '16px 0 0 auto',
  },
  infoText: {
    color: palette.brandColor,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    textDecoration: 'underline',
  },
  infoTextWrapper: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    gap: '4px',

    '& svg': {
      color: palette.brandColor
    }
  }
}))

const useWreathWithCaptionStyles = makeStyles<Theme, { isMobileViewOnly: boolean; }>(({ breakpoints }) => ({
  wreathContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  positionNumber: ({ isMobileViewOnly }) => ({
    color: 'rgba(0, 0, 0, 0.60)',
    textAlign: 'center',
    fontFamily: 'LatoBold',
    fontSize: '24px',
    fontWeight: 900,
    lineHeight: '36px',
    margin: '0 0 4px',

    [breakpoints.up('sm')]: isMobileViewOnly ? undefined : {
      fontSize: '40px',
      lineHeight: '60px',
      margin: '0 3px 6px',
    }
  }),
  rightSideWreath: {
    transform: 'scale(-1, 1)',
  },
  ribbonContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  ribbonText: ({ isMobileViewOnly }) => ({
    color: '#fff',
    fontSize: '8px',
    fontWeight: 700,
    left: 0,
    letterSpacing: '0.3px',
    lineHeight: '20px',
    position: 'absolute',
    textAlign: 'center',
    textTransform: 'uppercase',
    top: '2.5px',
    width: '100%',

    [breakpoints.up('sm')]: isMobileViewOnly ? undefined : {
      fontSize: '12px',
      lineHeight: '34px',
      letterSpacing: '0.6px',
      top: '4px',
    }
  }),
}))

export { useExplanationStyles, useWreathWithCaptionStyles }