import { makeStyles, Theme } from "@material-ui/core/styles";

import { loadingLight } from "../../shared/styles";
import { theme } from "../../shared/theme";

const useMainStyles = makeStyles(({ breakpoints }) => ({
  container: {
    padding: '12px',

    [breakpoints.up('sm')]: {
      padding: '12px 24px 36px',
    }
  },
  title: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.15px',
  },
  titleWrapper: {
    alignItems: 'center',
    marginBottom: '16px',

    [breakpoints.up('sm')]: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
    }
  },
  eventName: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginTop: '12px',

    [breakpoints.up('sm')]: {
      marginTop: '16px',
    }
  },
  raceNameWrapper: {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
  },
  raceName: {
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    marginTop: '4px',
  },
  expandCollapseButton: {
    padding: '2px 8px',
    textTransform: 'none',
    whiteSpace: 'nowrap',
  }
}))

type SectionFilledStatus = 'full' | 'partial' | 'empty'

const useSectionStyles = makeStyles<Theme, { sectionFilledStatus: SectionFilledStatus }>(({ breakpoints, palette }) => {
  const getChipColor = (sectionFilledStatus: SectionFilledStatus) => {
    if (sectionFilledStatus === 'partial') return '#ED6C02'
    if (sectionFilledStatus === 'full') return theme.palette.brandColor
    return 'rgba(0, 0, 0, 0.38)'
  }

  return {
    accordion: {
      boxShadow: 'none',
  
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: 0,
      },
      '& .MuiAccordionDetails-root': {
        display: 'block',
        padding: '12px 0 6px',
      }
    },
    accordionSummary: {
      backgroundColor: palette.grey[100],
      minHeight: 0,
      padding: '6px 16px',
      
      '& .MuiAccordionSummary-content': {
        alignItems: 'center',
        gap: '6px',
        margin: 0,
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: 0,
      }
    },
    cardsWrapper: {
      display: 'grid',
      gap: '8px',
      gridTemplateColumns: '1fr',
  
      [breakpoints.up('sm')]: {
        gap: '24px',
        gridTemplateColumns: 'repeat(3, 1fr)',
      }
    },
    title: {
      color: 'rgba(0, 0, 0, 0.60)',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '32px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      marginRight: '6px',
    },
    chip: ({ sectionFilledStatus }) => ({
      borderColor: getChipColor(sectionFilledStatus),
      color: getChipColor(sectionFilledStatus),
    })
  }
})

const useCardStyles = makeStyles<Theme, {}>(({ breakpoints }) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    gap: '12px',
    width: '100%',
    padding: '12px',

    [breakpoints.up('sm')]: {
      display: 'block',
    }
  },
  label: {
    color: '#008CC1',
    fontSize: '16px',
    fontWeight: 600,
    display: 'none',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginTop: '6px',
    textAlign: 'center',

    [breakpoints.up('sm')]: {
      display: 'block',
    }
  },
  athleteAvatar: {
    border: '2px solid #008CC1',
    boxSizing: 'border-box',
    borderRadius: '50%',
    display: 'none',

    [breakpoints.up('sm')]: {
      display: 'block',
    }
  },
  athleteName: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.15px',

    [breakpoints.up('sm')]: {
      fontSize: '20px',
      lineHeight: '32px',
    }
  },
  athleteNameWrapper: {
    alignItems: 'center',
    display: 'flex',
    gap: '4px',

    [breakpoints.up('sm')]: {
      justifyContent: 'center',
      margin: '6px 0 4px',
    }
  },
  athleteInfo: {
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.17px',

    '& span': {
      margin: '0 4px',
    },

    [breakpoints.up('sm')]: {
      textAlign: 'center',
    }
  },
  performanceSection: {
    marginLeft: 'auto',
    flexWrap: 'wrap',
    
    [breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 0,
      marginTop: '12px',
      width: '100%',
    }
  },
  performanceStatusColumn: {
    marginBottom: '12px',

    [breakpoints.up('sm')]: {
      marginBottom: 0,
    }
  },
  hiFiTime: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#E6E6E6',
    marginTop: '10px',
    justifyContent: 'center'
  },
  skeleton: {
    ...loadingLight,
    borderRadius: '4px',
  },
  skeletonAthleteName: {
    height: '18px',
    margin: '3px 0',
    width: '180px',

    [breakpoints.up('sm')]: {
      height: '20px',
      margin: '6px 0',
    }
  },
  skeletonAthleteInfo: {
    height: '14px',
    margin: '4px 0',
    width: '130px',

    [breakpoints.up('sm')]: {
      margin: '4px auto',
    }
  },
  skeletonPerformanceValue: {
    height: '20px',
    margin: '4px auto 0',
    width: '70px',
  }
}))

const usePerformanceItemStyles = makeStyles<Theme, { chipBackground?: string; }>(() => ({
  wrapper: {
    flex: 1,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.60)',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '0.1px',
    textTransform: 'uppercase',
  },
  value: {
    color: 'rgba(0, 0, 0, 0.87)',
    textAlign: 'center',
    fontSize: '20px',
    fontFamily: 'LatoBold',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  skeletonValue: {
    height: '20px',
    margin: '4px auto 0',
    width: '70px',
  },
  chip: {
    backgroundColor: ({ chipBackground }) => chipBackground,
    color: '#fff',

    '& .MuiChip-labelSmall': {
      padding: '0 12px',
    }
  }
}))

export {
  useMainStyles,
  useSectionStyles,
  useCardStyles,
  usePerformanceItemStyles,
}