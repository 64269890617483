import { useMemo } from 'react'
import { useGetRaceAwardsQuery } from '../api/reigniteApi'

interface UseIsWithLaurelProps {
  eventCourseId?: number
  athlinksEventId?: number
  thirdPartyEntryId?: number
  isLive?: boolean
}

export function useIsWithLaurel({
  eventCourseId,
  athlinksEventId,
  thirdPartyEntryId,
  isLive
}: UseIsWithLaurelProps): boolean {
  const { data: awardsData } = useGetRaceAwardsQuery(
    {
      eventCourseId: eventCourseId ?? -1,
      eventId: athlinksEventId ?? -1,
    },
    {
      pollingInterval: isLive ? 30_000 : undefined,
      skip: !eventCourseId || !athlinksEventId,
    }
  )

  return useMemo(() => {
    if (!awardsData || !thirdPartyEntryId) return false

    return awardsData.divisions
      ?.flatMap((division) => division.awards)
      ?.some((award) => award.id === thirdPartyEntryId)
  }, [awardsData, thirdPartyEntryId])
}