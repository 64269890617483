import { Button, Typography } from '@material-ui/core'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGetRaceAwardsQuery } from '../../api/reigniteApi'
import { AwardsExplanation } from '../../components/award/AwardsExplanation'
import useEventMetadata from '../../hooks/useEventMetadata'
import { useMainStyles } from './styles'
import { AwardsFilter } from './AwardsFilter'
import { AwardsSection } from './AwardsSection'
import { TimerInfo } from '../eventResults/EventResultsComponent'
import { EventMetadata } from '../../utils/types'
import { useIsEventLive } from '../../hooks/useIsEventLive'
import { Loading } from '../../components/shared/Loading'
import ZeroState from '../../components/zeroState/ZeroState'

type EventAwardsComponentProps = {
  eventId: number
  eventName: string
  eventMetadata: EventMetadata
  events: any
  raceId: number
  divisionId?: number
  masterId: number
  timer: any
  isCTLive: boolean
  affiliateID: number
  isMobile: boolean
  t: any
}

const EventAwardsComponent = ({
  eventId,
  eventName,
  eventMetadata,
  events,
  raceId,
  divisionId,
  masterId,
  timer,
  isCTLive,
  affiliateID,
  isMobile,
  t,
}: EventAwardsComponentProps) => {
  const styles = useMainStyles()

  const [expandedSections, setExpandedSections] = useState<(string | number)[]>(
    []
  )

  const eventCourseMetadata = (eventMetadata.eventCourseMetadata || []).find(
    (x) => x.eventCourseId === raceId
  )

  const { data, isLoading, isFetching, isError } = useGetRaceAwardsQuery({
    eventCourseId: raceId,
    eventId,
    divisionId,
  })

  const divisionsWithAwards = useMemo(
    () => data?.divisions?.filter((d) => !!d.depth && !!d.publish) || [],
    [data?.divisions]
  )
  const allSectionIds = useMemo(
    () => divisionsWithAwards.map((item) => item.id),
    [divisionsWithAwards]
  )

  // expand first division with awards on page load
  useEffect(() => {
    const firstDivision = divisionsWithAwards.find(
      (item) => item.awards.length > 0
    )
    if (firstDivision) {
      setExpandedSections([firstDivision.id])
    }
  }, [divisionsWithAwards])

  const showCollapseAll =
    divisionsWithAwards.length &&
    divisionsWithAwards.length === expandedSections.length

  const handleExpand = useCallback((sectionId: string | number) => {
    setExpandedSections((prev) =>
      prev.includes(sectionId)
        ? prev.filter((id) => id !== sectionId)
        : [...prev, sectionId]
    )
  }, [])

  const handleExpandCollapseAll = useCallback(() => {
    setExpandedSections(showCollapseAll ? [] : allSectionIds)
  }, [showCollapseAll, allSectionIds])

  const isEventLive = useIsEventLive(eventId)

  return (
    <div className={styles.container}>
      <div className={styles.titleWrapper}>
        <Typography className={styles.title}>Event Awards</Typography>

        <AwardsExplanation />
      </div>

      <AwardsFilter
        currentFilter={{ eventId, raceId, divisionId }}
        events={events}
        masterId={masterId}
      />

      <Typography className={styles.eventName}>{eventName}</Typography>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          marginTop: '16px',
        }}
      >
        {(isLoading || isFetching) && (
          <div style={{ width: '100%', marginTop: 25, height: 70 }}>
            <Loading />
          </div>
        )}

        {(isError ||
          (!isLoading && !isFetching && !data?.divisions?.length)) && (
          <ZeroState
            divisionId={divisionId}
            eventId={eventId}
            masterId={masterId}
            message="We don't have official Awards for selected Division(s) in Race."
            raceId={raceId}
          />
        )}

        {!isError &&
          !isLoading &&
          !isFetching &&
          !!data?.divisions?.length &&
          eventCourseMetadata && (
            <>
              <div className={styles.raceNameWrapper}>
                <Button
                  className={styles.expandCollapseButton}
                  size='small'
                  endIcon={
                    showCollapseAll ? <UnfoldLessIcon /> : <UnfoldMoreIcon />
                  }
                  onClick={handleExpandCollapseAll}
                >
                  {showCollapseAll ? 'Collapse All' : 'Expand All'}
                </Button>
              </div>
              {data.divisions.map((item, index) => (
                <AwardsSection
                  eventId={eventId}
                  raceId={raceId}
                  masterId={masterId}
                  awards={item.awards}
                  depth={item.depth}
                  id={item.id}
                  isExpanded={expandedSections.includes(item.id)}
                  key={index}
                  onToggleExpand={handleExpand}
                  divisionName={item.name}
                  type={item.rankType}
                  eventCourseMetadata={eventCourseMetadata}
                  isEventLive={isEventLive}
                />
              ))}
            </>
          )}
        <div>
          <TimerInfo
            timer={timer}
            eventId={eventId}
            isCTLive={isCTLive}
            affiliateID={affiliateID}
            isMobile={isMobile}
            t={t}
          />
        </div>
      </div>
    </div>
  )
}

type EventAwardsProps = Omit<
  EventAwardsComponentProps,
  'divisions' | 'eventName'
> & { timer: any; isMobile: boolean; t: any }

const EventAwards = ({
  eventId,
  isMobile,
  raceId,
  t,
  timer,
  ...props
}: EventAwardsProps) => {
  const eventMetadata = useEventMetadata(eventId)

  return (
    <EventAwardsComponent
      {...props}
      eventId={eventId}
      eventName={eventMetadata.data.eventName}
      raceId={raceId}
      eventMetadata={eventMetadata.data}
      timer={timer}
      isCTLive={!!eventMetadata.data.azpEventId}
      affiliateID={+eventMetadata.data.azpEventId}
      isMobile={isMobile}
      t={t}
    />
  )
}

export { EventAwards }
