import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { configs } from '../configs'

export const alaskaApi = createApi({
  reducerPath: 'alaskaApi',
  baseQuery: retry(
    fetchBaseQuery({ baseUrl: configs.ATHLINKS_API_HOST }),
    {
      maxRetries: configs.rtkQueryMaxRetries
    }
  ),
  endpoints: (builder) => ({
    getEventCourses: builder.query<any, number>({
      query: (athlinksEventId: number) => `/Events/Race/Api/${athlinksEventId}/Course/0`,
      transformResponse: (resp: any) => resp.Result,
    }),
    getExtraData: builder.query<any, number | null>({
      query: (eventId) => eventId ? `/events/api/${eventId}/extra` : null,
    }),
  }),
})

export const { useGetEventCoursesQuery, useGetExtraDataQuery } = alaskaApi