import React from 'react';
import PropTypes from 'prop-types';
import { getInitials } from '../../shared/util';
import { athletePhotoStyles } from './styles';
import { configs } from '../../configs';

export const AthletePhoto = (
  {
    name,
    size,
    photo,
    wrapperStyle,
    containerStyle,
    width,
    isPublicProfile = true,
    isMember
  }) => {

  if (!photo) {
    return null;
  }

  const imageSizes = {
    Thumb: 24,
    Small: 50,
    AthleteRacePageCard: 86,
    Medium: 100,
    Large: 140,
    Xl: 200,
    XXL: 500
  };
  const sizePx = (width || imageSizes[size] || 100);
  const initialsSize = Math.floor(Number(sizePx) * 0.54);
  const backgroundUrl = isMember
    ? `${configs.cdnUrl}/images/default-athlete-blue.jpg`
    : `${configs.cdnUrl}/images/default-athlete-gray.jpg`;

  let imgUrl = '';
  if((typeof(photo) === 'string') && isPublicProfile) imgUrl = photo;
  else if(photo.HasImage && isPublicProfile) imgUrl = photo[size];

  return (
    <div
      className='athlete-photo'
      title={name}
      alt={name}
      style={athletePhotoStyles.athPhotoContainer(wrapperStyle, sizePx)}
    >
      <div
        style={athletePhotoStyles.athPhotoHolder(backgroundUrl, containerStyle, sizePx)}/>
      {
        !photo.HasImage &&
        <div
          style={athletePhotoStyles.athInitials(initialsSize)}>
          {getInitials(name)}
        </div>
      }
      {
        imgUrl &&
        <div
          style={athletePhotoStyles.athPhoto(imgUrl, sizePx)}/>
      }

    </div>
  );
};

AthletePhoto.propTypes = {
  isMember: PropTypes.bool,
  size: PropTypes.string,
  wrapperStyle: PropTypes.object,
  sizePx: PropTypes.number,
  name: PropTypes.string.isRequired,
  showPii: PropTypes.bool
};

AthletePhoto.defaultProps = {
  isMember: false,
  size: 'Small',
  photo: {
    HasImage: false
  },
  width: null,
  wrapperStyle: {},
  containerStyle: {},
  styles: {},
  name: '',
  showPii: true
};
