import {EventMetadataState} from '../model/eventResultsState';
import { ResultActionTypes } from '../resultsClient/types';

const eventMetadataReducer = (state = EventMetadataState, action) => {
  switch(action.type) {
    case ResultActionTypes.GET_META:
      return {
        ...state,
        errorMessage: '',
        isLoading: true,
      };
    case ResultActionTypes.GET_META_ERROR:
      return {
        ...state,
        data: {},
        errorMessage: 'Failed to get event metadata',
        isLoading: false,
      };
    case ResultActionTypes.GET_META_COMPLETE:
      return {
        ...state,
        data: action.meta,
        errorMessage: '',
        isLoading: false,
      };
    default:
      return state;
  }
}

export default eventMetadataReducer;
