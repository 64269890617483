/* * *
 * Helper Functions for Parsing various things
 * * */

const ParserUtil = {

  /*
   * Try to Parse an Int
   *
   * @param {string} str
   * @param {any} default_val
   *
   * @return {int || default_val}
   */
  tryParseInt(val, defaultVal) {
    if (typeof val === 'number') {
      return val;
    }

    if (typeof val === 'string') {
      if (val.length > 0) {
        if (!isNaN(val)) {
          return parseInt(val);
        }
      }
    }

    return defaultVal || false;
  }

};

export default ParserUtil;
