import React, { useMemo } from 'react'
import { KioskTimePace } from './KioskTimePace'
import { showPace } from '../../utils/IRPUtil'
import { convertPace, convertTime, UnitType } from '@eventops/athlinks-lib-units'
import transformReigniteEntryIntervalPace from '../../utils/transformReigniteEntryIntervalPace'
import convertPaceDistanceUnit from '../../utils/convertPaceDistanceUnit'
import { makeStyles, Theme } from '@material-ui/core'
import { colors } from '../../shared/styles'
import { IPace, TimeUnit } from '@eventops/athlinks-results-model'
import { DivisionResultMetadata, IntervalResultMetadata } from '../../types.results.reignite'
import { BracketType, CtLiveApiStatus } from '../../utils/types'

const useStyles = makeStyles<Theme, { isLightMode: boolean, isMobile: boolean }>(() => ({
  resultsEventDetails: ({ isLightMode }) => ({
    color: isLightMode ? colors.black : colors.white,
    fontSize: '0.875rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  courseName: ({ isLightMode }) => ({
    border: `1px solid ${colors.greyLine}`,
    padding: '2px 15px',
    borderRadius: 25,
    fontFamily: 'ProximaNovaBold',
    textTransform: 'uppercase',
    color: isLightMode ? colors.grey : colors.white
  }),
  resultsContentContainer: {
    justifyContent: 'center',
    margin: 0,
    width: '100%'
  },
  timePaceContainer: {
    margin: '1.2rem 0 1rem 0'
  }
}))

interface IndividualKioskResultsContentProps {
  isLightMode?: boolean
  isMobile?: boolean
  eventCourseName?: string
  result: Result
}

interface Interval {
  chipTimeInMillis: number
  gunTimeInMillis: number
  divisions: (
    {
      rank: number
      name: string
      totalAthletes: number
      totalInBracket: number
      bracketType: BracketType
    } & DivisionResultMetadata
  )[]
  mostRecentInterval: boolean
  name: string
  pace?: IPace
  intervalFull: boolean
  timeWithPenalties: { timeInMillis: number }
}

export interface Result {
  status: CtLiveApiStatus
  displayName: string
  bib: string
  eventId: number
  eventCourseId: number
  isValidEmail: boolean
  thirdPartyEntryId: number
  intervals: (Interval & IntervalResultMetadata)[]
}

export function IndividualKioskResultsContent({
  eventCourseName,
  isLightMode = false,
  isMobile = false,
  result
}: IndividualKioskResultsContentProps) {
  const styles = useStyles({ isLightMode, isMobile })

  const mostRecentInterval = result.intervals.find(x => x.mostRecentInterval) || result.intervals[0]
  const latestIntervalName = mostRecentInterval?.name || ''

  const brackets = mostRecentInterval?.divisions || []
  const overallBracket = brackets.find(x => x.name.toLowerCase() === 'overall')

  const useGunTime = overallBracket?.rankType === 'gun'
  const isFinisher = !['DNF', 'DQ'].includes(result.status)

  const pace = useMemo(() =>
    mostRecentInterval?.pace
      ? mostRecentInterval.pace
      : overallBracket && mostRecentInterval
        ? transformReigniteEntryIntervalPace({
          chipTimeInMillis: mostRecentInterval.chipTimeInMillis,
          division: overallBracket,
          gunTimeInMillis: mostRecentInterval.gunTimeInMillis,
          interval: mostRecentInterval
        }) as IPace
        : undefined,
    [mostRecentInterval, overallBracket]
  )

  const shouldShowPace = !!pace && showPace(pace) && isFinisher
  const calculatedPace = useMemo(() =>
    shouldShowPace
      ? convertPace(convertPaceDistanceUnit(pace, UnitType.DEFAULT))
      : { value: 0, label: '' },
    [shouldShowPace, pace]
  )

  const timeInMillis = useGunTime ? mostRecentInterval?.gunTimeInMillis : mostRecentInterval?.chipTimeInMillis
  const finishTime = useMemo(() =>
    timeInMillis
      ? convertTime({ timeInMillis, timeUnit: TimeUnit.HOURS }).value
      : '',
    [timeInMillis]
  )

  return (
    <div className={`row ${styles.resultsContentContainer}`}>
      <div className={styles.resultsEventInfo}>
        <div className={styles.resultsEventDetails}>
          <div className={styles.courseName}>{eventCourseName}</div>
        </div>
      </div>
      <div className={`col-12 ${styles.timePaceContainer}`}>
        <KioskTimePace
          hasPace={shouldShowPace}
          finishTime={finishTime}
          isGunTime={useGunTime}
          isLightMode={isLightMode}
          isMobile={isMobile}
          pace={calculatedPace.value}
          paceUnit={calculatedPace.label}
          ranks={brackets}
          result={{ ...result, isFinisher }}
          latestIntervalName={latestIntervalName}
        />
      </div>
    </div>
  )
}
