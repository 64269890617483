import Typography from '@material-ui/core/Typography'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  warningText: {
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
})

const AwardsUnofficialWarningBanner = () => {
  const { t } = useTranslation()
  const styles = useStyles()

  return (
    <div className={styles.warningContainer}>
      <ReportProblemOutlinedIcon />
      <Typography className={styles.warningText}>
        {t('Awards are unofficial and may change.')}
      </Typography>
    </div>
  )
}

export default AwardsUnofficialWarningBanner
