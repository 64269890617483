import { useMemo } from 'react'
import Lottie from 'lottie-react-web'

type BracketBubbleProps = {
  animation: object
  width: number
  startSegment: number
  endSegment: number
}

export function BracketBubble({ animation, width, startSegment, endSegment }: BracketBubbleProps) {
  const memoizedProps = useMemo(() => ({
    animation,
    width,
    startSegment,
    endSegment
  }), [animation, width, startSegment, endSegment])

  return (
    <Lottie
      options={{
        animationData: memoizedProps.animation,
        loop: false,
        autoplay: true
      }}
      width={memoizedProps.width}
      forceSegments={true}
      segments={[+memoizedProps.startSegment, +memoizedProps.endSegment]}
    />
  )
}
