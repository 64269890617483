import { Button, withStyles } from '@material-ui/core'

const rippleStyle = {
  margin: '0 0 16px 0',
  padding: '12px 14px',
  border: '1px solid transparent',
  borderRadius: 0,
  transition: 'background-color 0.25s ease-out, color 0.25s ease-out',
}

const StyledButton = ({classes, ...other}) => {
  const { button: buttonClass, ...rippleClasses } = classes;
  return (
    <Button
      TouchRippleProps={{ classes: rippleClasses }}
      className={buttonClass}
      {...other}
    />
  );
};

const RaisedButton = ({variant='contained', label, labelColor = '#FFF', style, labelStyle, onClick, backgroundColor, icon, disabled = false}) => {
  const theStyle = {
    button: {
      ...style,
      ...labelStyle,
      color: labelColor,
      backgroundColor,
      cursor: 'pointer',
    },
    child: {
      ...rippleStyle
    }
  };

  const TheButton = withStyles(theStyle)(StyledButton);
  return <TheButton variant={variant} startIcon={icon} disabled={disabled} onClick={onClick}>{label}</TheButton>;
};

export default RaisedButton;