import { configs } from "../configs";

export const EventResultsState = {
  errorMessage: '',
  isLoading: false,
  data: [],
  wasStopped: false,
  args: {
    fro: 0,
    limit: configs.numResultsPerPage,
  }
};

export const EventMetadataState = {
  data: {},
  error: false,
  fetching: true,
  isLoading: true,
};

export const EventResultsFiltersState = {
  date: undefined,
  course: undefined,
  division: undefined,
  split: undefined
};
