import QRCode from 'qrcode'
import EscPosEncoder from '../esc-pos-encoder';

import { isGunTime } from './ERPUtil';
import { showPace, getLatestScoredInterval, getTimeLabel } from './IRPUtil';
import { getResultsUrlForBib } from './resultsHelpers';
import { convertPace, convertTime } from '@eventops/athlinks-lib-units';
import { configs } from '../configs';
import { getDisplayLocation } from './location'
import _ from 'lodash';

const CANVAS_WIDTH = 576

const mockPrinter = {
  productId: 'TESTPRINTER',
  productName: 'TESTPRINTER',
  vendorId: 'TESTPRINTER',
  open: () => Promise.resolve(),
  claimInterface: () => Promise.resolve(),
  transferOut: () => Promise.resolve(),
}

export function constructStaticImages() {
  const athlinksLogoImg = new Image()
  athlinksLogoImg.src = `${configs.bragiUrl}/images/receipt_athlinks_logo.png`
  const cellIcon = new Image()
  cellIcon.src = `${configs.bragiUrl}/svg/cellphone.svg`
  return Promise.all([
    new Promise((resolve, reject) => {
      const canvas = document.getElementById('hiddenCanvas')
      athlinksLogoImg.onload = resolve
      athlinksLogoImg.onerror = reject
      canvas.appendChild(athlinksLogoImg)
    }).then(() => (athlinksLogoImg)),
    new Promise((resolve, reject) => {
      const canvas = document.getElementById('hiddenCanvas')
      cellIcon.onload = resolve
      cellIcon.onerror = reject
      canvas.appendChild(cellIcon)
    }).then(() => (cellIcon))
  ]).then((items) => ({ athlinksLogoImg: items[0], cellIcon: items[1] }))
}

function getDevice() {
  const vendorId = localStorage.getItem('vendorId')
  const productId = localStorage.getItem('productId')

  return navigator.usb && navigator.usb.getDevices().then((devices) => {

    const connectedDevices = configs.useMockPrinter
      ? [mockPrinter]
      : devices.filter((_) => _.productId.toString() === productId && _.vendorId.toString() === vendorId)
    if (connectedDevices.length) {
      return connectedDevices[0].open(0).then(() => connectedDevices[0]); // Begin a session.
    } else {
      throw new Error('No Valid Device Found!!!')
    }
  })
    .then((device) => device.claimInterface(0).then(() => device))
}

export async function handlePrint(result, masterEvent, intl, t) {
  console.log('handlePrint', result)
  const myNode = document.getElementById('hiddenCanvas');
  while (myNode.firstChild) {
    myNode.removeChild(myNode.firstChild);
  }

  constructStaticImages()
    .then(({ athlinksLogoImg, cellIcon }) => {
      return formatRecept(result, masterEvent, athlinksLogoImg, cellIcon, intl, t)
    })
    .then(({ IRPCanvasUrl, height }) => {
      const IRPCanvas = new Image()
      IRPCanvas.src = IRPCanvasUrl
      IRPCanvas.setAttribute('type', 'hidden')

      return new Promise((resolve, reject) => {
        const canvas = document.getElementById('hiddenCanvas')
        IRPCanvas.onload = resolve
        IRPCanvas.onerror = reject
        canvas.appendChild(IRPCanvas)
      }).then(() => ({ IRPCanvas, height }))
    })
    .then(({ IRPCanvas, height }) => {
      const encoder = new EscPosEncoder()
      const builder = encoder.initialize()
        .image(IRPCanvas, CANVAS_WIDTH, Math.ceil(height / 8.0) * 8)
        .newline()
        .newline()
        .newline()
        .raw([0x1B, 0x69])

      const res = builder.encode()
      return getDevice()
        .then((device) => device.transferOut(1, res))
        .catch((e) => console.log(e.message))
    })
    .catch((e) => console.log(e.message))
}

export async function formatRecept(result, masterEvent, athlinksLogoImg, cellIcon, intl, t) {
  const canvasWidth = CANVAS_WIDTH
  const canvasHeight = 1600
  const marginSize = 20

  const maxTextLength = canvasWidth - (marginSize * 4)
  const startHeight = 0
  const GIANT_FONT = 70
  const LARGE_FONT = 50
  const SMALL_FONT = 30
  const FONT_NAME = 'ProximaNovaRegular'
  const DIVIDER_STYLE = 'rgba(0, 0, 0, 1)'

  const splitLine = (text, context) => {
    const textParts = text.split(' ')
    const textLengths = textParts.map((_) => context.measureText(_).width)

    var total = 0
    var row = 0
    var lines = []
    for (var i = 0; i < textLengths.length; i++) {
      if ((total + textLengths[i]) < maxTextLength) {
        lines[row] = (lines[row] || '') + textParts[i] + ' '
        total += textLengths[i]
      } else {
        row++
        lines[row] = textParts[i] + ' '
        total = textLengths[i]
      }
    }
    return lines
  }

  const {
    bib = '',
    displayName = '',
    eventId = '',
    eventCourseId = '',
    entryId = ''
  } = result;
  const {
    eventRaces = [],
    masterEventID = '',
    city,
    stateProvAbbrev,
    countryID
  } = masterEvent;
  const eventIndex = eventRaces.findIndex((_) => _.raceID === eventId)
  if (eventIndex === -1) throw new Error(`no event with id ${eventId} found`)
  const resultRace = eventRaces[eventIndex]

  const { raceName, raceDate, eventCourses } = resultRace

  const nameText = raceName
  const startTimeText = intl.formatDate(
    new Date(raceDate),
    { year: 'numeric', month: 'long', day: '2-digit' }
  )
  const locationText = getDisplayLocation(city, stateProvAbbrev, countryID)
  const displayNameText = displayName
  const bibText = `BIB ${bib}`

  const courseIndex = eventCourses.findIndex((_) => _.eventCourseID === eventCourseId)

  if (courseIndex === -1) throw new Error(`no course with id ${eventCourseId} found`)
  const course = eventCourses[courseIndex]
  const courseNameText = course.courseName

  const useGunTime = isGunTime(result.brackets);
  const mostRecentInterval = getLatestScoredInterval(result.intervals);
  const isFinisher = !['DNF', 'DQ'].includes(result.entryStatus);
  const hasPace = showPace(mostRecentInterval.pace);
  const pace = hasPace
    ? convertPace(mostRecentInterval.pace, { intervalRaceType: mostRecentInterval.intervalRaceType, capitalizeLabel: false })
    : { value: '', label: '' };
  const hasTime = _.get(mostRecentInterval, 'timeWithPenalties.timeInMillis', 0) > 0;
  const finishTime = hasTime ? convertTime(_.assign({}, mostRecentInterval.timeWithPenalties, { timeUnit: 'h' })).value : '';

  const timeLabel = `FINAL ${getTimeLabel(result, useGunTime, t).toUpperCase()}`
  const paceLabel = `PACE (${pace.label.toUpperCase()})`
  const time = isFinisher && mostRecentInterval.intervalFull ? finishTime : '--'
  const paceText = isFinisher && mostRecentInterval.intervalFull ? pace.value : '--'

  const separatorHeight = 50

  const logoHeight = 75
  const logoWidth = 330

  const scanMoreLabel = 'SCAN FOR MORE'
  const cellHeight = 35
  const cellWidth = 17.5

  const canvas = document.createElement('canvas');
  canvas.width = canvasWidth
  canvas.height = canvasHeight
  const context = canvas.getContext('2d');

  // event name
  context.font = `${LARGE_FONT}px ${FONT_NAME}`
  context.fillStyle = 'black'
  const nameLines = splitLine(nameText, context)
  nameLines.forEach((line, index) => context.fillText(line, marginSize, startHeight + (index + 1) * LARGE_FONT))
  const nameEndLength = ((nameLines.length + 1) * LARGE_FONT) + startHeight

  //date
  context.font = `${SMALL_FONT}px ${FONT_NAME}`
  context.fillText(startTimeText, marginSize, nameEndLength)

  //vertical divider
  context.fillStyle = DIVIDER_STYLE;
  context.fillRect(marginSize + marginSize / 2 + (context.measureText(startTimeText).width), nameEndLength - SMALL_FONT + 2, 2, SMALL_FONT)

  //location
  context.fillStyle = 'black'
  context.fillText(locationText, marginSize + marginSize + (context.measureText(startTimeText).width), nameEndLength)
  const dateEndLength = nameEndLength + SMALL_FONT

  //separator 1
  context.fillStyle = DIVIDER_STYLE;
  context.fillRect(marginSize, dateEndLength, canvas.width - 2 * marginSize, 2)
  const separatorEndLength = dateEndLength + separatorHeight + 15

  //display name
  context.font = `${LARGE_FONT}px ${FONT_NAME}`
  context.fillStyle = 'black'
  const displayNameLines = splitLine(displayNameText, context)
  displayNameLines.forEach((line, index) => context.fillText(line, marginSize, separatorEndLength + (index * LARGE_FONT)))
  const displayEndLength = separatorEndLength + (LARGE_FONT * displayNameLines.length)

  //bib
  context.font = `${SMALL_FONT}px ${FONT_NAME}`
  context.fillStyle = 'black'
  context.fillText(bibText, marginSize, displayEndLength)
  const bibEndLength = displayEndLength + SMALL_FONT

  //second separator
  context.fillStyle = DIVIDER_STYLE;
  context.fillRect(marginSize, bibEndLength + 10, canvas.width - 2 * marginSize, 2)
  const separator2EndLength = bibEndLength + separatorHeight

  //oval around course name
  context.font = `bold ${SMALL_FONT}px ${FONT_NAME}`
  const courseNameTextWidth = context.measureText(courseNameText).width + 10
  const leftEdge = (canvasWidth - courseNameTextWidth) / 2
  const topOfRectangle = separator2EndLength - 10
  const boxHeight = SMALL_FONT * 2
  const curveSize = 10
  context.strokeStyle = DIVIDER_STYLE;

  context.beginPath();

  context.moveTo(leftEdge, topOfRectangle);
  context.lineTo(leftEdge + courseNameTextWidth, topOfRectangle);
  context.quadraticCurveTo(
    leftEdge + courseNameTextWidth + curveSize,
    topOfRectangle,
    leftEdge + courseNameTextWidth + curveSize,
    topOfRectangle + curveSize
  );

  context.lineTo(leftEdge + courseNameTextWidth + curveSize, topOfRectangle + boxHeight - marginSize + curveSize);
  context.quadraticCurveTo(
    leftEdge + courseNameTextWidth + curveSize,
    topOfRectangle + boxHeight,
    leftEdge + courseNameTextWidth,
    topOfRectangle + boxHeight
  );

  context.lineTo(leftEdge, topOfRectangle + boxHeight);
  context.quadraticCurveTo(
    leftEdge - curveSize,
    topOfRectangle + boxHeight,
    leftEdge - curveSize,
    topOfRectangle + boxHeight - curveSize
  );

  context.lineTo(leftEdge - curveSize, topOfRectangle + curveSize);
  context.quadraticCurveTo(
    leftEdge - curveSize,
    topOfRectangle,
    leftEdge,
    topOfRectangle
  );
  context.closePath();
  context.stroke();

  // course name
  context.fillStyle = 'black'
  context.fillText(courseNameText, (canvasWidth - courseNameTextWidth) / 2, topOfRectangle + SMALL_FONT + 12)

  const courseNameEndLength = topOfRectangle + boxHeight + 30

  //pace and time labels
  context.font = `bold ${SMALL_FONT}px ${FONT_NAME}`
  const timeLabeLength = context.measureText(timeLabel).width
  const paceLabeLength = context.measureText(paceLabel).width

  const timeStart = hasPace ? marginSize + 25 : (canvasWidth - timeLabeLength) / 2
  context.fillText(timeLabel, timeStart, courseNameEndLength + 15)
  const paceStart = canvasWidth - 15 - marginSize - paceLabeLength
  hasPace && context.fillText(paceLabel, paceStart, courseNameEndLength + 15)
  const timeLabelEndLength = courseNameEndLength + SMALL_FONT


  //pace and time values
  context.font = `normal ${GIANT_FONT}px ${FONT_NAME}`
  const timeLength = context.measureText(time).width
  const paceLength = context.measureText(paceText).width

  const timeOffset = (timeLength - timeLabeLength) / 2
  const paceOffset = (paceLength - paceLabeLength) / 2

  context.fillText(time, timeStart - timeOffset, timeLabelEndLength + GIANT_FONT)
  hasPace && context.fillText(paceText, paceStart - paceOffset, timeLabelEndLength + GIANT_FONT)
  const timeTextEndLength = timeLabelEndLength + GIANT_FONT

  //athlinks logo
  context.drawImage(athlinksLogoImg, (canvasWidth - logoWidth) / 2, timeTextEndLength + logoHeight, logoWidth, logoHeight)
  const logoEndLength = timeTextEndLength + 2 * logoHeight

  //qr code
  const irpUrl = `${configs.bragiUrl}${getResultsUrlForBib(eventId, eventCourseId, bib, masterEventID, entryId, null, null, ['source=print'])}`
  const qrSize = (canvasWidth - (marginSize * 2)) * 0.75
  const qrc = await QRCode.toDataURL(irpUrl)
  const qrCode = new Image()
  qrCode.src = qrc
  await document.getElementById('hiddenCanvas').appendChild(qrCode)
  context.drawImage(qrCode, (canvasWidth - qrSize) / 2, logoEndLength + 30, qrSize, qrSize)
  const qrEndLength = logoEndLength + qrSize + 30

  //scan for more
  context.font = `bold ${SMALL_FONT}px ${FONT_NAME}`
  const scanMoreLabelWidth = context.measureText(scanMoreLabel).width
  const lineLength = scanMoreLabelWidth + cellWidth + 30
  const cellXPosition = (canvasWidth - lineLength) / 2
  context.drawImage(cellIcon, cellXPosition, qrEndLength - cellHeight + 35, cellWidth, cellHeight)

  context.fillText(scanMoreLabel, cellXPosition + 30, qrEndLength + 25)
  const scanMoreLabelEndLength = qrEndLength + 25

  context.font = `${SMALL_FONT}px ${FONT_NAME}`
  const now = new Date()
  context.fillText(`Printed on: ${now.toLocaleDateString()} ${now.toLocaleTimeString()}`, marginSize, scanMoreLabelEndLength + 50)

  const destinationCanvas = document.createElement('canvas');
  destinationCanvas.width = canvasWidth
  destinationCanvas.height = scanMoreLabelEndLength + 100
  const destCtx = destinationCanvas.getContext('2d');
  destCtx.drawImage(canvas, 0, 0);

  return { IRPCanvasUrl: destinationCanvas.toDataURL(), height: destinationCanvas.height }
}

