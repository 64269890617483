import convertRaceTypeToTimeUnits from './convertRaceTypeToTimeUnits';
import convertTimeUnitsToOldTimeUnit from './convertTimeUnitsToOldTimeUnit';
import { IntervalRaceType } from '../types.results';
import { DivisionRankType } from '../types.metadata.reignite';

export default function transformReigniteEntryIntervalTime({
  chipTimeInMillis,
  gunTimeInMillis,
  raceType,
  rankType,
}: {
  chipTimeInMillis: number;
  gunTimeInMillis: number;
  raceType: IntervalRaceType;
  rankType: DivisionRankType;
}) {
  return {
    timeInMillis: rankType === 'chip'
      ? chipTimeInMillis
      : gunTimeInMillis,
    timeUnit: convertTimeUnitsToOldTimeUnit(
      convertRaceTypeToTimeUnits(raceType)
    ),
  };
}
