import {EventResultsFiltersState} from '../model/eventResultsState';
import {EventResultsFilterActionTypes} from '../actions/types';

const eventResultsFiltersReducer = (state = EventResultsFiltersState, action) => {
  switch(action.type) {
    case EventResultsFilterActionTypes.FILTER_SET:
      return {
        date: action.date,
        course: action.course,
        division: action.division,
        split: action.split
      };
    default:
      return state;
  }
};

export default eventResultsFiltersReducer;
