import React from 'react'
import { BracketRow } from './BracketRow'
import { makeStyles, Theme } from '@material-ui/core'
import { BracketType } from '../../utils/types'

type Bracket = {
  rank: number
  name: string
  totalAthletes: number
  totalInBracket: number
  bracketType: BracketType
}

type BracketInfoProps = {
  brackets: Bracket[]
  isLightMode: boolean
  isMobile: boolean
}

const useStyles = makeStyles<Theme, { isMobile: boolean }>(() => ({
  bracketRowWrap: ({ isMobile }) => ({
    minWidth: 130,
    paddingBottom: 8,
    paddingLeft: isMobile ? 5 : 12,
    paddingRight: isMobile ? 5 : 12,
  }),
  timePaceCenterContent: {
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap'
  }
}))

export function BracketInfo({ brackets, isLightMode, isMobile }: BracketInfoProps) {
  const styles = useStyles({ isMobile })

  const getBracketCount = (bracket: Bracket) => {
    const { bracketType, totalInBracket, totalAthletes } = bracket
      return bracketType === 'OPEN'
        ? totalInBracket && totalInBracket > totalAthletes ? totalInBracket : totalAthletes
        : totalInBracket && totalInBracket < totalAthletes ? totalInBracket : totalAthletes
  }

  return (
    <div className={styles.timePaceCenterContent}>
      {
        brackets.map((bracket, index) => {
          if (index === 6) return null
          return (
            bracket.rank &&
            <div
              className={styles.bracketRowWrap}
              key={bracket.name}
            >
              <BracketRow
                isLightMode={isLightMode}
                label={bracket.name}
                value={bracket.rank}
                count={getBracketCount(bracket)}
              />
            </div>
          )
        })
      }
    </div>
  )
}
