import React from 'react'
import { useTranslation } from 'react-i18next'
import { styles } from './styles'
import { getCourseDetail } from '../../utils/ERPUtil'
import IntervalResultsRowMobile from './IntervalResultsRowMobile'

type Result = {
  eventId: number
  eventCourseId: number
  bracketName: string
  thirdParySystemRaceId?: string
}

type Interval = {
  order: number
  name: string
  results: Array<{
    id: string
  }>
}

type EventResultsIntervalMobileProps = {
  masterId: number
  interval: Interval
  eventMetadata: any // Type this based on your metadata structure
  result: Result
  results: Result[]
  leaderboard?: boolean
  showSignUpBanner?: boolean
  pageNumber?: number
  pageSize?: number
}

const EventResultsIntervalMobile: React.FC<EventResultsIntervalMobileProps> = ({
  masterId,
  interval,
  eventMetadata,
  result,
  results,
}) => {
  const { t } = useTranslation()
  const { eventId, eventCourseId } = result
  const courseDetail = getCourseDetail(eventCourseId, eventMetadata)
  const { isVirtual } = courseDetail

  return (
    <div key={interval.order}>
      <div
        style={{
          ...styles.intervalTitle,
          fontSize: 12,
          marginBottom: 20,
        } as React.CSSProperties}
      >
        {!results[0].thirdParySystemRaceId
          ? t('Full Course')
          : interval.name || t('Full Course')}
      </div>
      {interval.results.map((row, idx) => (
        <IntervalResultsRowMobile
          key={idx}
          row={row}
          index={idx}
          eventId={eventId}
          eventCourseId={eventCourseId}
          masterId={masterId}
          isVirtual={isVirtual}
          bracketName={result.bracketName}
        />
      ))}
    </div>
  )
}

export default EventResultsIntervalMobile
