import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { styles } from './styles';
import { Banner, AthlinksAds } from '../shared/Banner';
import { useFirebaseConfig } from '../../lib/firebase/remote-config';
import isInteractiveAdEventBlacklisted from '../../lib/firebase/isInteractiveAdEventBlacklisted';
import isInteractiveAdEventWhitelisted from '../../lib/firebase/isInteractiveAdEventWhitelisted';

// Ad Overlay component for mobile view.
const AdOverlay = ({ isMobile, athlinksEventId, sponsorLogos, isLive}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  const firebaseConfig = useFirebaseConfig();

  useEffect(() => {
    // Initialize the ad library.
    AthlinksAds.init();

    // 1. Mobile device check: if it's not a mobile device, not showing the ad.
    if (!isMobile) {
      setIsVisible(false);
      return;
    } else {
      setIsMobileDevice(true);
    }

    // 2. AllowInteractiveAds switch: if it's false, not showing the ad.
    const allowInteractiveAds = firebaseConfig.config.useInteractiveAds?.asBoolean() ?? false;
    if (!allowInteractiveAds) {
      setIsVisible(false);
      return;
    }

    const isWhitelisted = isInteractiveAdEventWhitelisted(firebaseConfig, athlinksEventId);
    const isBlacklisted = isInteractiveAdEventBlacklisted(firebaseConfig, athlinksEventId);

    // 3. Blacklist check: if the event is blacklisted, not showing the ad.
    if (isBlacklisted) {
      setIsVisible(false);
      return;
    }

    // 4. Whitelist check: we always want to show the ad regardless of sponsors or live status.
    if (isWhitelisted) {
    } else {
      // For events that are neither whitelisted nor blacklisted, check if they have sponsors or if it's live, in that case, not showing the ad.
      if ((sponsorLogos && sponsorLogos.length > 0) || isLive) {
        setIsVisible(false);
        return;
      }
    }

    // 5. Cookie check: if the ad has been shown before, not showing the ad.
    const adShown = Cookies.get('adShown');
    if (!adShown && navigator.cookieEnabled) {
      Cookies.set('adShown', 'true', { path: '/', secure: true });
    } else {
      setIsVisible(false);
      return;
    }
  }, [isMobile, athlinksEventId, sponsorLogos, isLive, firebaseConfig]);

  const adUnits = AthlinksAds.adsForPage('MobileAdOverlay');

  if (!isMobileDevice || !isVisible) {
    return null;
  }

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <div style={styles.adOverlay}>
      <div style={styles.adContent}>
        {adUnits.map((x) => (
          <div key={x.adKey} style={styles.bannerStyle}>
            <Banner
              data-ad={x.adKey}
              adKey={x.adKey}
              divId={x.divId}
              width={320} 
              height={480}
              style={{ width: 320, height: 480, margin: '0 auto' }}
            />
          </div>
        ))}
      </div>
      <button style={styles.closeButton} onClick={handleClose}>
        Continue to Athlinks
      </button>
    </div>
  );
};

export default AdOverlay;
