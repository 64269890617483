import { Chip, Paper, Typography, useMediaQuery } from "@material-ui/core"
import { theme } from "../../shared/theme"
import { AthletePhoto } from "../../components/athlete/AthletePhoto"
import { AwardWreathWithCaption } from "../../components/award/AwardLaurelWithCaption"
import { EntryDivisionAward } from "../../types.results.reignite"
import { profileUserImageUrl } from "../../utils/profileImageUrl"
import { Link } from 'react-router-dom'
import { getResultsUrlForBib } from '../../utils/resultsHelpers'
import { useCardStyles, usePerformanceItemStyles } from "./styles"
import { UnitType } from '@eventops/athlinks-lib-units'
import {
  EventCourseMetadata,
  AwardTime
} from '../../utils/types'
import { useSelector } from 'react-redux'
import calculatePace from '../../utils/calculatePace'

const Skeleton = ({ className }: { className: string }) => {
  const styles = useCardStyles({ isPending: true })

  return (
    <div className={`${styles.skeleton} ${className}`} />
  )
}

type PerformanceItemProps = {
  className?: string
  label: string
  showLabel?: boolean
  value?: string | null
  chip?: {
    label: string
    color: string
  }
}

const PerformanceItem = ({
  className,
  label,
  showLabel = true,
  value,
  chip
}: PerformanceItemProps) => {
  const styles = usePerformanceItemStyles({ chipBackground: chip?.color })

  return (
    <div className={`${className} ${styles.wrapper}`}>
      <div className={styles.container}>
        {showLabel && (
          <Typography className={styles.label}>
            {label}
          </Typography>
        )}

        {typeof value !== 'undefined' && !value && (
          <Skeleton className={styles.skeletonValue} />
        )}

        {!!value && (
          <Typography className={styles.value}>
            {value}
          </Typography>
        )}

        {chip && (
          <Chip
            label={chip.label}
            size='small'
            className={styles.chip}
          />
        )}
      </div>
    </div>
  )
}

type AwardCardProps = {
  eventId: number
  raceId: number
  eventCourseMetadata: EventCourseMetadata
  masterId: number
  award: EntryDivisionAward
  divisionName: string
  allTimesInBracket: AwardTime[]
}

const AwardCard = ({ eventId, raceId, masterId, award, eventCourseMetadata, divisionName, allTimesInBracket }: AwardCardProps) => {
  const unitType = useSelector((state: { userPrefs: { globalUnitType: UnitType } }) => state.userPrefs.globalUnitType)

  const profPicUrl = profileUserImageUrl(award.racerId, 't', !!award.racerId, true);

  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const styles = useCardStyles({})
  const userLink = getResultsUrlForBib(eventId, raceId, award.bib, masterId)

  const calculatedPace = calculatePace({
    timeInMillis: award.timeInMillis,
    interval: eventCourseMetadata.metadata.intervals.find(x => x.isFull),
    unitType
  })

  const awardTime = allTimesInBracket.find(x => x.bib === award.bib)?.time
  const showWreathCaption = divisionName.length < 10
  const showHiFiTime = allTimesInBracket.filter(x => x.time === awardTime && x.bib !== award.bib).length
  const hiFiTime = allTimesInBracket.find(x => x.bib === award.bib)?.hiFiTime

  return (
    <Link to={userLink} style={{ textDecoration: 'none' }}>
      <Paper className={styles.container}>
        <div>
          <AwardWreathWithCaption
            caption={showWreathCaption ? divisionName : ''}
            place={award.place}
          />

          {!showWreathCaption && (
            <Typography className={styles.label}>
              {divisionName}
            </Typography>
          )}
        </div>
        <div>
          <div className={styles.athleteNameWrapper}>
            <>
              <div className={styles.athleteAvatar}>
                <AthletePhoto
                  isMember={!!award.racerId}
                  size='Thumb'
                  photo={profPicUrl}
                  name={award.displayName}
                />
              </div>

              <Typography className={styles.athleteName}>
                {award.displayName}
              </Typography>
            </>
          </div>
          <>
            <Typography className={styles.athleteInfo}>
              {award.gender} {award.age}<span>|</span>#{award.bib}
            </Typography>
            <Typography className={styles.athleteInfo}>
              {award.location?.locality}, {award.location?.region}
            </Typography>
          </>
        </div>

        <div className={styles.performanceSection}>
          {!isMobile && (
            <PerformanceItem
              label={`PACE ${calculatedPace.label}`}
              value={calculatedPace.value}
            />
          )}

          <PerformanceItem
            label='TIME'
            showLabel={!isMobile}
            value={awardTime}
          />
          {
            showHiFiTime
            ? !isMobile
              ? <div className={styles.hiFiTime}>
                  <p>High Fidelity Time:&nbsp;</p>
                  <p>{hiFiTime}</p>
                </div>
              : <div>
                  <p>{`(${hiFiTime})`}</p>
                </div>
            : <></>
          }
        </div>
      </Paper>
    </Link>
  )
}

export { AwardCard }