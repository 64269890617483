import { Button, Dialog, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { useState } from 'react';

import { configs } from '../../configs';

import { useExplanationStyles } from './styles'

const AwardsExplanation = () => {
  const styles = useExplanationStyles()
  const theme = useTheme()

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const toggleDialogOpenStatus = () => {
    setIsDialogOpen((prev) => !prev)
  }

  return (
    <div>
      <div className={styles.infoTextWrapper} onClick={toggleDialogOpenStatus}>
        <Typography className={styles.infoText}>Learn how we distribute awards.</Typography>
        <InfoIcon />
      </div>

      <Dialog fullScreen={isMobile} open={isDialogOpen}>
        <div className={styles.dialogContainer}>
          <img src={`${configs.cdnUrl}/images/awards-explanation.png`} alt='visual-explanation' />

          <Typography className={styles.dialogTitle}>One Runner = One Award</Typography>

          <Typography className={styles.dialogMainDescription}><span>Award Distribution Explained:</span> The Overall Winner gets the top prize. If you win a "higher" award, the next eligible runner gets the next highest award.</Typography>

          <Typography className={styles.dialogFooterDescription}>This system allows more runners get recognized for their achievements!</Typography>

          <Button variant='outlined' className={styles.dialogButton} onClick={toggleDialogOpenStatus}>Close</Button>
        </div>
      </Dialog>
    </div>
  )
}

export { AwardsExplanation }