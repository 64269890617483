import { Box } from "@material-ui/core"

import { useSharedStyles } from "./styles"

const SkeletonPlaceholder = () => {
  const sharedStyles = useSharedStyles()

  return (
    <Box className={sharedStyles.paper}>
      <Box className={sharedStyles.skeletonTitle} />

      <Box className={sharedStyles.skeletonContent} />
    </Box>
  )
}

export { SkeletonPlaceholder }