import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import { TextField, InputAdornment } from '@material-ui/core'
import ReactList from 'react-list';

import { configs } from '../../configs';
import { colors } from '../../shared/styles';
import { styles } from './styles';
import SearchStatus, { SearchState } from './SearchStatus';

class TypeAheadComponent extends Component {
  static propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    inputProps: PropTypes.object,
    inputStyle: PropTypes.object,
    label: PropTypes.string,

    useMaterialUi: PropTypes.bool,

    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    dropdownTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    dropdownHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    dropdownStyle: PropTypes.object,
    showDropdownOverride: PropTypes.bool,
    hideDropdownOverride: PropTypes.bool,

    errorText: PropTypes.string,

    options: PropTypes.array,
    renderOption: PropTypes.func.isRequired,
    onOptionSelected: PropTypes.func.isRequired,
    onOptionFocused: PropTypes.func,

    InputIcon: PropTypes.func,
    searching: PropTypes.bool,
    t: PropTypes.func,
    retrySearch: PropTypes.func,
    keyboardNavigation: PropTypes.bool,
    autoFocus: PropTypes.bool
  };

  static defaultProps = {
    useMaterialUi: false,
    dropdownTop: 50,
    dropdownHeight: 300,
    keyboardNavigation: false,
    label: '',
    labelColor: colors.darkGrey,
    onEmptySelection: () => {},
    disabled: false,
    searching: false,
    autoFocus: false
  };

  state = {
    showDropdown: false,
    focusedOptionIndex: -1,
    hoveredOptionIndex: -1,
    isFirstSearch: true
  };

  constructor(props) {
    super(props);
    this.dropdownRows = [];
  }

  componentDidMount() {
    if(this.props.autoFocus && this.textInput) {
      this.textInput.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.options !== nextProps.options) {
      const index = this.props.keyboardNavigation ? 0 : -1
      this.setState({
        focusedOptionIndex: index,
        hoveredOptionIndex: index
      })
    }

    const {isFirstSearch} = this.state;
    if (isFirstSearch && nextProps.searching) {
      this.setState({isFirstSearch: false});
    }

  }

  componentDidUpdate() {
    //Scroll to focused option
    const { focusedOptionIndex, hoveredOptionIndex, enterDuringSearch} = this.state;
    const { options, keyboardNavigation } = this.props;
    if (focusedOptionIndex >= 0 && hoveredOptionIndex === -1) {
      const optionNode = this.dropdownRows[focusedOptionIndex];
      const dropdownNode = this.refs['dropdown'];

      if (optionNode && dropdownNode) {
        if (optionNode.offsetTop < dropdownNode.scrollTop)
          dropdownNode.scrollTop = optionNode.offsetTop;
        else if (optionNode.offsetTop + optionNode.offsetHeight > dropdownNode.offsetHeight + dropdownNode.scrollTop)
          dropdownNode.scrollTop = optionNode.offsetTop + optionNode.offsetHeight - dropdownNode.offsetHeight
      }
    }

    if (options?.length && enterDuringSearch && keyboardNavigation) {
      this.selectOption(options[0], 0)
      this.setState({
        enterDuringSearch: false
      });
    }
  }

  renderNoResults = this.props.loadingOption || function() {
    const {
      dropdownTop,
      dropdownHeight,
      dropdownStyle,
      retrySearch,
      searching,
      errorMessage,
      geoSearch,
      t
    } = this.props;

    const { isFirstSearch } = this.state;
    const searchState =
      isFirstSearch || searching ?
        SearchState.SEARCHING
        : errorMessage ?
            SearchState.SEARCH_FAILED
          : SearchState.NO_RESULTS;

    const dropdown =
      <SearchStatus
        retrySearch={retrySearch}
        searchState={searchState}
        t={t}
      />;

    return (
      <div
        id="result-search-output"
        onMouseDown={(e) => e.preventDefault()}
        style={{
          position:'absolute',
          left:0,
          top: dropdownTop,
          right: 0,
          zIndex:3,
          height: dropdownHeight,
          textAlign: 'left',
          ...dropdownStyle
        }}>
        { dropdown }
        { geoSearch &&
          <img
            style={{position: 'absolute', bottom: 5, right: 5, width: '50%'}}
            src={`${configs.cdnUrl}/images/powered_by_google.png`}
            alt={''}
          />
        }
      </div>
    );
  };

  rowRender = (index, key) => {
    const {
      value,
      renderOption,
      options,
      t,
      type
    } = this.props;

    const {
      focusedOptionIndex,
      hoveredOptionIndex
    } = this.state;

    if(index < options.length) {
      const option = options[index];
      return (
          <div
            key={key}
            ref={(ref) => this.dropdownRows[index] = ref}
            onClick={(e) => this.handleOptionClick(e, option, index)}
            onMouseEnter={() => this.hoverOption(index)}
            onMouseLeave={() => this.unhoverOption()}
            onMouseDown={(e) => e.preventDefault()}
          >
            {
              renderOption({
                index,
                isFocused: index === focusedOptionIndex,
                isHovered: index === hoveredOptionIndex,
                option,
                t,
                type,
                value,
              })
            }
          </div>
      );
    }
  };


  render() {
    const {
      autoFocus,
      value,
      placeholder,
      inputProps,
      inputStyle,

      useMaterialUi,
      width,
      dropdownTop,
      dropdownHeight,
      dropdownStyle,

      options,

      showDropdownOverride, //when we want to force drop-down to be shown,
      hideDropdownOverride, //when we want to force drop-down to be hidden
      InputIcon,
      disabled,
      keyboardNavigation,
      geoSearch,
      style = {},
      searching,
    } = this.props;

    const {
      showDropdown,
    } = this.state;

    const shouldShowDropdown = (showDropdownOverride || showDropdown) && !hideDropdownOverride && !!value;

    const adornment = useMaterialUi && InputIcon ?
      <InputAdornment position="start">
        <InputIcon />
      </InputAdornment> : null

    const {
      disableUnderline = false,
      style: _inputStyle = {},
      ...fieldProps
    } = inputProps

    const inputElement = useMaterialUi ?
      <TextField
        autoFocus={autoFocus}
        variant={disableUnderline ? 'outlined' : 'standard'}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        spellCheck='false'
        autoComplete='off'
        autoCorrect='off'
        InputProps={{
          startAdornment: adornment,
          style: { ..._inputStyle, ...inputStyle }
        }}
        {...fieldProps}
        style={style}
      />
      :
      <input
        value={value}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        placeholder={placeholder}
        style={{width, ...inputStyle}}
        spellCheck='false'
        autoComplete='off'
        autoCorrect='off'
        ref={(el) => this.textInput = el}
        {...inputProps}
      />;
      
    const dropDown = !searching && options?.length ?
      <div
        id="suggestion-dropdown"
        ref='dropdown'
        style={styles.typeAhead.infiniteScrollStyle(dropdownTop, dropdownHeight, dropdownStyle)}
      >
        <ReactList
          itemRenderer={this.rowRender}
          length={options.length}
          type='simple'
        />
        { geoSearch &&
          <img
            style={{position: 'absolute', bottom: 5, right: 5, width: '50%'}}
            src={`${configs.cdnUrl}/images/powered_by_google.png`}
            alt={''}
          />
        }
      </div>
      :
      this.renderNoResults();

    return (
      <div
        style={styles.typeAhead.container(width)}
        onKeyDown={keyboardNavigation ? this.onKeyDown : () => {}}
      >
        { inputElement }
        {
          !useMaterialUi && InputIcon &&
          <div style={styles.typeAhead.iconStyle}>
            <InputIcon color={colors.darkBlue1} />
          </div>
        }
        { shouldShowDropdown && dropDown }
      </div>
    );
  }

  hoverOption = (i) => {
    const options = this.props.keyboardNavigation ? {
      hoveredOptionIndex: i,
      focusedOptionIndex: i
    } : {
      hoveredOptionIndex: i,
    }
    this.setState(options);
  };

  unhoverOption = () => {
    this.setState({
      hoveredOptionIndex: -1
    })
  };

  onFocus = () => {
    this.setState({
      showDropdown: true
    });
    const { onFocus } = this.props.inputProps || {};
    if (onFocus) {
      onFocus();
    }
  };

  onBlur = () => {
    this.setState({
      showDropdown: false
    });
    const { onBlur } = this.props.inputProps || {};

    if (onBlur) {
      onBlur();
    }
  };

  onChange = (e) => {
    this.setState({
      showDropdown: true
    });
    this.props.onChange(e);
  };

  handleOptionClick = (e, option, i) => {
    this.selectOption(option, i);
    this.setState({
      showDropdown: false
    });
  };

  onKeyDown = (e) => {
    switch(e.key) {
      case 'Enter':
        this.handleEnter();
        break;
      case 'Escape':
        this.handleEscape();
        break;
      case 'ArrowUp':
        e.preventDefault();
        this.handleArrow({up: true});
        break;
      case 'ArrowDown':
        e.preventDefault();
        this.handleArrow({up: false});
        break;
      default:
        break;
    }
  };

  handleEnter() {
    const { focusedOptionIndex } = this.state;
    const { searching } = this.props;
    if (!searching) {
      if (focusedOptionIndex !== -1) {
        this.selectFocusedOption()
      }
      this.setState({
        enterDuringSearch: false
      })
    } else {
        this.setState({
          enterDuringSearch: true
        })
    }
  }

  handleEscape() {
    this.setState({
      showDropdown: false
    });
  }

  selectFocusedOption() {
    const {
      options
    } = this.props;

    const { focusedOptionIndex } = this.state;
    if (!options || focusedOptionIndex < 0 || focusedOptionIndex >= options.length) {
      this.props.onEmptySelection();
      return;
    }

    this.selectOption(options[focusedOptionIndex], focusedOptionIndex);
  }

  selectOption(option, focusedOptionIndex) {
    this.setState({
      showDropdown: false,
      focusedOptionIndex,
      hoveredOptionIndex : -1
    });

    this.props.onOptionSelected(option);
    this.onBlur();
  }

  handleArrow({up}) {
    const {
      options,
      onOptionFocused
    } = this.props;

    const numOptions = (options || []).length;

    if (numOptions === 0) {
      return;
    }

    const {
      focusedOptionIndex,
      showDropdown
    } = this.state;

    if (!showDropdown) {
      this.setState({
        showDropdown: true
      });
      return;
    }

    let newIndex = (up ? focusedOptionIndex - 1 : focusedOptionIndex + 1);
    if (newIndex < -1) {
      newIndex = numOptions - 1;
    }
    newIndex = newIndex === -1 ? newIndex : newIndex % numOptions;

    this.setState({
      focusedOptionIndex: newIndex,
      hoveredOptionIndex : -1
    });

    onOptionFocused && onOptionFocused(options, newIndex);
  }
}

export const TypeAhead = withTranslation()(TypeAheadComponent);
