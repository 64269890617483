import { EventResultsComponent } from './EventResultsComponent'
import { EventResultsProps } from '../../utils/types'
import { useGetEventWideLeaderboardResultsQuery } from '../../api/reigniteApi'
import transformReigniteRaceLeaderboardResults from '../../utils/transformReigniteRaceLeaderboardResults'
import useSse from '../../lib/firebase/useSse'
import { ReigniteEventSseResults } from './ReigniteEventSseResults'
import { useEffect, useMemo, useState } from 'react'
import { RaceLeaderboardResults } from '../../types.results.reignite'

export function ReigniteEventWideLeaderboardResults(
  props: EventResultsProps & {
    athlinksEventId: number
    from: number
    isLive: boolean
    limit: number
    selectedEvent?: {
      event?: {
        ctliveId?: number
      }
    }
  }
) {
  const {
    athlinksEventId,
    from,
    isLive,
    limit,
  } = props

  const usingSse = useSse(athlinksEventId) && isLive

  const {
    currentData: data,
    isError,
    isFetching,
    refetch,
  } = useGetEventWideLeaderboardResultsQuery(
    {
      eventId: athlinksEventId,
      from,
      limit,
    },
    {
      pollingInterval: isLive && !usingSse
        ? 30_000
        : undefined,
      skip: !athlinksEventId,
    }
  )

  const [lastSuccessfulFetch, setLastSuccessfulFetch] = useState<RaceLeaderboardResults[] | null>(null)

  useEffect(() => {
    if (data && !isError) {
      setLastSuccessfulFetch(data)
    }
  }, [data, isError])

  const isLoading = isFetching && !lastSuccessfulFetch

  const mapped = useMemo(() => {
    return Array.isArray(lastSuccessfulFetch)
    && lastSuccessfulFetch.some(data =>
      data.intervals?.some(interval => interval?.results?.length)
    )
      ? lastSuccessfulFetch.flatMap(data =>
        transformReigniteRaceLeaderboardResults({
          data,
          eventId: athlinksEventId,
          isLinked: !!props.selectedEvent?.event?.ctliveId,
        })
      )
      : []
  }, [lastSuccessfulFetch, athlinksEventId, props.selectedEvent])

  return (
    <>
      {usingSse && <ReigniteEventSseResults
        athlinksEventId={athlinksEventId}
        refetch={refetch}
      />}
      <EventResultsComponent
        {...props}
        fetchEventResultsError={isError && !lastSuccessfulFetch}
        eventResults={{
          data: mapped,
          isLoading,
        }}
        isFetchingEventResults={isFetching}
        refetch={refetch}
      />
    </>
  )
}
