import {Link} from 'react-router-dom';
import {DateTime} from '../../shared/DateTime';
import {Location} from '../../shared/Location';
import ArrowRight  from 'react-icons/lib/md/keyboard-arrow-right';
import { makeStyles } from '@material-ui/core';
import { fonts } from '../../../shared/styles';

const useEventHeaderStyles = makeStyles(({ palette }) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  allResults: {
		fontSize: '12px',
		color: palette.brandColor,
    width: 75,
	},
  dateLocation: {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    color: palette.grey[500],
  },
  title: {
    ...fonts.bold,
    color: palette.grey[800],
    fontSize: '20px',
  }
}))

export const EventHeader = ({resultsUrl, event, t}) => {
  const regionArray = event.regionId ? event.regionId.split('-') : [];
  const state = regionArray.length > 1 ? regionArray[1] : '';

  const styles = useEventHeaderStyles();

  return (
    <div className={styles.container}>
      <div>
        <div>
          <div className={styles.title}>
            {event.name}
          </div>

          <div className={styles.dateLocation}>
            <DateTime
              date={event.startEpoch * 1000}
              showTime={false}
              icon={null}
              style={{
                borderRight: '1px solid lightgrey',
                paddingRight: 8,
                marginRight: 8,
                fontSize: 12,
              }}
            />

            <Location
              city={event.city}
              state={state}
              country={event.countryId}
              icon={null}
              searchType={null}
            />
          </div>
        </div>
      </div>

      <Link to={resultsUrl}>
        <div className={styles.allResults}>
          <span>
            {t('All results')}
          </span>
          <ArrowRight size={20} style={{color: '#16A9E1'}}/>
        </div>
      </Link>
    </div>
  );
};
