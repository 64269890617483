import { Box, Hidden } from "@material-ui/core"
import { useMemo } from "react"
import { useHistory } from "react-router-dom"

import {
  useGetAzpEntryEventHistoryQuery,
  useGetAzpEventEntryQuery,
  useGetAzpEventLinkQuery,
} from "../../api/reigniteApi"
import useEventMetadata from "../../hooks/useEventMetadata"
import { AzpEventEntry } from "../../types.results.reignite"
import { hasRole } from '../../utils/isLoggedIn'

import { useMainStyles } from "./styles"
import { EventCard } from "./EventCard"
import { PageContainer } from "./PageContainer"
import { AthleteCard } from "./AthleteCard"
import { RaceCard } from "./RaceCard"
import { DivisionsCard } from "./DivisionsCard"
import { EventsHistory } from "./EventsHistory"
import { useMissingData } from "./hooks/useMissingData"
import { EventSponsors } from "./EventSponsors"
import { useIsEventLive } from '../../hooks/useIsEventLive'

type AthleteRacePageProps = {
  azp: string,
  azpEntryId?: number
  azpEventId: string
  bib?: string
}

type FormattedEventEntry = AzpEventEntry & {
  bib: string | null
  firstName: string | null
  lastName: string | null
}

const AthleteRacePage = ({
  azp,
  bib,
  azpEntryId,
  azpEventId,
}: AthleteRacePageProps) => {
  const history = useHistory();
  const styles = useMainStyles()

  const { data: azpEventLinks } = useGetAzpEventLinkQuery({
    azp,
    azpEventId,
  }, {
    skip: !azp || !azpEventId,
  })

  const eventId = azpEventLinks?.athlinksEventId || 0

  const {
    data: event,
    isLoading: isLoadingEvent,
  } = useEventMetadata(eventId)

  const isEventLive = useIsEventLive(eventId)

  const {
    data: azpEntry,
    isLoading: isAzpEntryLoading,
  } = useGetAzpEventEntryQuery({
    azp,
    azpEntryId: azpEntryId ?? 0,
    azpEventId,
  }, {
    skip: !azp || !azpEventId || !azpEntryId,
    pollingInterval: isEventLive ? 30000 : 0,
  })

  const shouldRedirectToIRP = !!azpEntry?.hasResult || !hasRole('alpha-arp') // remove role req on full release

  const formattedEntry = useMemo<FormattedEventEntry | null>(() => {
    if (!azpEntry) {
      return null
    }

    if (azpEntry.private) {
      return {
        ...azpEntry,
        bib: null,
        displayName: null,
        firstName: null,
        lastName: null,
      }
    }

    const [firstName, lastName] = azpEntry.displayName.split(' ')

    return {
      ...azpEntry,
      bib: bib || azpEntry.bib,
      firstName,
      lastName,
    }
  }, [azpEntry, bib])

  const {
    data: eventsHistory,
    isLoading: isEventsHistoryLoading,
  } = useGetAzpEntryEventHistoryQuery({ azp: azp, azpEntryId: azpEntryId || 0 }, {
    skip: !azp || !azpEventId,
  })

  const raceLink = azpEventLinks?.races.find(x => x.id === azpEntry?.race?.id)
  const athlinksEventCourseId = raceLink?.athlinksEventCourseId || null
  const eventCourse = event.eventCourseMetadata?.find((item) => item.eventCourseId === athlinksEventCourseId)

  const { masterId, ctLiveRegLink, thirdPartyRegLink } = useMissingData({ eventId })
  const registrationLink = (thirdPartyRegLink || ctLiveRegLink || null) as string | null

  const redirectingParamsExist = !!(masterId && eventId && athlinksEventCourseId && azpEntry?.bib)
  if (shouldRedirectToIRP && redirectingParamsExist) {
    history.replace(`/event/${masterId}/results/Event/${eventId}/Course/${athlinksEventCourseId}/Bib/${azpEntry.bib}`)
  }

  return (
    <PageContainer
      azpEventId={azpEventId}
      eventId={shouldRedirectToIRP ? null : eventId}
      eventCourseId={athlinksEventCourseId}
      isEventLive={isEventLive}
    >
      <Box className={styles.container}>
        <Box className={styles.leftSide}>
          <AthleteCard
            entry={formattedEntry}
            isLoadingEntry={!azpEventId || isAzpEntryLoading}
          />

          <Hidden smDown>
            <EventSponsors eventId={eventId} />
          </Hidden>
        </Box>

        <Box className={styles.rightSide}>
          <EventCard
            event={event}
            isLoadingEvent={isLoadingEvent}
          />

          <RaceCard
            entryFirstName={formattedEntry?.firstName || null}
            eventCourse={eventCourse || null}
            isLoadingEventCourse={!eventCourse}
            registrationLink={event.registration?.published ? registrationLink : null}
          />

          <DivisionsCard
            azpEntry={azpEntry || null}
            eventCourse={eventCourse || null}
            eventId={eventId}
            isLoadingEntry={!azpEventId || isAzpEntryLoading}
            isLoadingEventCourse={!eventCourse}
            masterId={masterId}
          />

          <EventsHistory
            entryFirstName={formattedEntry?.firstName || null}
            eventCourseStartDateTime={eventCourse?.eventCourseStartDateTime || null}
            eventCourseName={eventCourse?.eventCourseName || null}
            eventsHistory={eventsHistory?.events || []}
            isEventsHistoryLoading={isEventsHistoryLoading || isLoadingEvent}
            isProcessedByInsights={azpEntry?.isProcessedByInsights || false}
            masterId={masterId}
          />

          <Hidden mdUp>
            <EventSponsors eventId={eventId} />
          </Hidden>
        </Box>
      </Box>
    </PageContainer>
  )
}

export { AthleteRacePage }