import { Chip, makeStyles, Theme, Typography, useMediaQuery } from "@material-ui/core"
import { fonts } from "../../../shared/styles"
import { theme } from "../../../shared/theme"
import { AwardsExplanation } from "../../award/AwardsExplanation"
import { AwardWreathWithCaption } from "../../award/AwardLaurelWithCaption"
import { DivisionAwards, EntryDivisionAward } from "../../../types.results.reignite"
import { styles as rootStyles } from "./styles"
import { useHistory } from 'react-router-dom'
import { useIsEventLive } from '../../../hooks/useIsEventLive'
import AwardsUnofficialWarningBanner from '../../../views/eventAwards/AwardsUnofficialWarningBanner'

export type IRPAward = Pick<EntryDivisionAward, 'id' | 'gender' | 'place'> & {
  division: Pick<DivisionAwards, 'id' | 'name' | 'depth' | 'rankType'>
}

type IRPAwardSectionProps = {
  awards: IRPAward[]
  eventCourseId: number
  eventId: number
  masterId: number
}

const useStyles = makeStyles<Theme, { isFullWidthAward: boolean }>(({ breakpoints }) => ({
  awardLink: {
		flex: 1,
    cursor: 'pointer',
	},
  awardContainer: ({ isFullWidthAward }) => ({
		alignItems: 'center',
		background: 'rgba(22, 169, 225, 0.10)',
		border: '1px solid #0288D1',
		borderRadius: '4px',
		display: 'flex',
		padding: '12px',

    [breakpoints.up('sm')]: isFullWidthAward ? {} : {
      flexDirection: 'column',
    }
	}),
	awardLabel: ({ isFullWidthAward }) => ({
    '& p': {
      color: '#014361',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '24px',
      letterSpacing: '0.15px',
      marginLeft: '12px',
      
      [breakpoints.up('sm')]: isFullWidthAward ? {} : {
        marginLeft: 0,
        textAlign: 'center',
      }
    },

    [breakpoints.up('sm')]: {
      margin: '8px 0 0',
    },
	}),
  awardsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',

    [breakpoints.up('sm')]: {
      flexDirection: 'row',
    }
  },
  container: {
    display: 'flex',
		flexDirection: 'column',
		gap: '8px',
  },
  rankTypeChip: {
    borderColor: '#0077b3',
    color: '#0077b3',
    fontSize: '10px',
    lineHeight: '18px',
    height: 'auto',
  },
  title: {
    color: '#424242',
    fontSize: 20,
    lineHeight: '24px',
    letterSpacing: 0.4,
    ...fonts.bold,
  },
  titleContainer: {
    [breakpoints.up('sm')]: {
      alignItems: 'center',
      display: 'flex',
		  justifyContent: 'space-between',
    }
  },
}))

const IRPAwardSection = ({
  awards,
  eventCourseId,
  eventId,
  masterId
}: IRPAwardSectionProps) => {
  console.log({ awards })
  const history = useHistory()
  
  const isFullWidthAward = awards.length === 1

  const styles = useStyles({ isFullWidthAward })
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const isEventLive = useIsEventLive(eventId)

  if (!awards.length) return null

  return (
    <div className={styles.container} style={rootStyles.cardContainer}>
      {awards.length > 1 && (
        <div className={styles.titleContainer}>
          <Typography className={styles.title}>Awards</Typography>
          <AwardsExplanation />
        </div>
      )}

      <div>
        {isEventLive && <AwardsUnofficialWarningBanner />}

        <div className={styles.awardsWrapper}>
          {awards.map((award) => {
            const rankTypeChip = <Chip
              key={`${award.division.name}-${award.division.rankType}`}
              className={styles.rankTypeChip}
              label={award.division.rankType === 'chip' ? 'Chip Time' : 'Gun Time'}
              size='small'
              variant="outlined"
            />
            
            return (
              <div
                key={award.id}
                className={styles.awardLink}
                onClick={() =>
                  history.push(
                    `/event/${masterId}/awards/Event/${eventId}/Course/${eventCourseId}/Division/${award.division.id}`
                  )
                }
              >
                <div className={styles.awardContainer}>
                  <AwardWreathWithCaption
                    caption={`${award.place}/${award.division.depth}`}
                    place={award.place}
                    size='mobile'
                  />

                  <div className={styles.awardLabel}>
                    {(isMobile || isFullWidthAward) && (
                      <>
                        <Typography>
                          Award Winner{awards.length > 1 && ' |'}{' '}
                          {award.division.name}
                        </Typography>
                        <div style={{paddingLeft: 10}}>
                          {rankTypeChip}
                        </div>
                      </> 
                    )}

                    {!isMobile && !isFullWidthAward && (
                      <>
                        <Typography>Award Winner</Typography>
                        <Typography>{award.division.name}</Typography>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          {rankTypeChip}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {awards.length === 1 && <AwardsExplanation />}
    </div>
  )
}

export { IRPAwardSection }