import { textStyles, colors } from '../../../shared/styles';

export const timeStyles = {
  title: {
    ...textStyles.sectionHead,
    paddingBottom: 12,
    borderBottom: '1px solid '+ colors.greyLine,
    maxWidth: 180,
    whiteSpace: 'nowrap',
    overflow : 'hidden',
    textOverflow: 'ellipsis'
  },
  number: {
    fontFamily: 'LatoBold',
    fontSize: 45,
    letterSpacing: -1.25,
    color: '#424242',
  },
};

export const shareStyles = {
  button: {
    position: 'relative',
    display: 'inline-block',
    backgroundColor: '#16A9E1',
    padding: '2px 7px 5px 9px',
    height: 45,
    width: 45,
    border: '2px solid #16A9E1',
    cursor: 'pointer',
    borderRadius: 2
  },
};

export const athleteCard = {
  contentContainer: {
    textAlign: 'center',
  },
  photoContainer:(isMobile) => ({
    margin: isMobile ? '0 20px 0 10px' : '30px 0 32px',
    display: 'inline-block',
    float: isMobile ? 'left' : ''
  }),
  name: {
    fontSize: 24,
    color: '#424242',
    fontFamily: 'ProximaNovaBold'
  },
  details:(isMobile) => ({
    textAlign: isMobile ? 'left' : '',
    marginBottom: isMobile ? 0 : 15,
    ...textStyles.body1Grey,
  }),
  firstDetail: {
    display: 'inline-block',
    paddingRight: 8,
    borderRight: '1px solid '+ colors.greyLine,
    marginRight: 8,
  },
  secondDetail: {
    display: 'inline-block'
  },

  bibContainer: {
    display: 'inline-block',
    marginBottom: 23,
  },
  bibTitle: {
    marginBottom: 3,
    ...textStyles.sectionHead,
  },
};

export const buttonsContainer = (isMobile) => isMobile ? {
  display: 'flex',
  marginLeft: 80,
  marginTop: 10,
} : {}

export const styles = {
  bigInfo: Object.assign({}, textStyles.subhead1Grey, {
    color: 'white',
    fontSize: '16px',
    textShadow: '0 0 6px rgba(0, 0, 0, 0.7)',
    letterSpacing: '3.84px'
  }),
  tabletBigInfo: Object.assign({}, textStyles.body1Grey, {
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '3.84px'
  }),
  container: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 5px 12px rgba(58, 86, 109, 0.3)',
    marginBottom: '24px',
    textAlign: 'center'
  },
  division: Object.assign({}, textStyles.body1Grey, {
    fontSize: '14px',
    //textTransform: 'uppercase',
    //letterSpacing: '3.84px'
  }),
  divisionNumber: {
    fontSize: '28px',
    color:'#4A4A4A',
    fontFamily: 'ProximaNovaRegular',
  },
  hero: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
  },
  info: Object.assign({}, textStyles.subhead1Grey, {
    fontFamily: 'ProximaNovaSemibold',
    color: 'white',
    fontSize: '12px',
    letterSpacing: '3.23px'
  }),
  shadowedText: {
    textShadow: '0 0 6px rgba(0, 0, 0, 0.7)'
  },
  tabletInfo: Object.assign({}, textStyles.subhead1Grey, {
    fontFamily: 'ProximaNovaSemibold',
    fontSize: '12px',
    letterSpacing: '3.23px'
  }),
  name: Object.assign({}, textStyles.subhead1Grey, {
    color: 'white',
    fontSize: '24px',
    margin: '8px auto',
    letterSpacing: '3.84px',
    textShadow: '0 0 6px rgba(0, 0, 0, 0.5)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  tabletName: Object.assign({}, textStyles.subhead1Grey, {
    color: '#000',
    fontSize: '24px',
    margin: '8px auto',
    letterSpacing: '3.84px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }),
  overlay: {
    boxSizing: 'border-box',
    background: 'rgba(0, 0, 0, 0.3)',
    padding: '24px'
  },
  profPicContainer: {
    display:'inline-block',
    border: '3px solid #16A9E1',
    borderRadius: '50%',
    height: 92,
    width: 92,
    padding:2
  },
  profPic: {
    borderRadius: '50%',
    height: '100%',
    width: '100%',
  },
  nonMemberProfPicContainer: {
    display:'inline-block',
    border: '3px solid #666',
    borderRadius: '50%',
    height: 92,
    width: 92,
    padding:4
  },
  nonMemberProfPic: {
    borderRadius: '50%',
    height: '100%',
    width: '100%',
  },
  miniHeading: {
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#4A4A4A',
    fontFamily: 'ProximaNovaSemiBold',
    borderBottom: '1px solid lightgrey',
    paddingBottom: 6,
    minWidth: 100 //for IE
  },
  time: {
    color: 'white',
    fontSize: '64px',
    fontFamily: 'ProximaNovaExtraCondensedExtrabold',
    textShadow: '0 0 6px rgba(0, 0, 0, 0.5)',
  },
  nameAndAge: {
    display:'inline-block',
    height:17,
    fontSize: '11px',
    lineHeight: '17px',
    color: '#AAAAAA'
  },
  bib: {
    fontSize: '9px',
    fontFamily: 'ProximaNovaRegular',
    fontWeight: 'bold'
  },
  tabletTime: {
    fontSize: '56px',
    fontFamily: 'ProximaNovaExtraCondensedExtrabold'
  },
  title: Object.assign({}, textStyles.raceTitleDarkDesktop, {
    color: 'white',
    cursor: 'pointer',
    margin: '8px auto',
    textShadow: '0 0 6px rgba(0, 0, 0, 0.5)',
  }),
  mobileTitle: Object.assign({}, textStyles.raceTitleDarkMobile, {
    fontFamily: 'ProximaNovaSemibold',
    fontSize: '24px',
    letterSpacing: '-0.36px',
    cursor: 'pointer',
    margin: '8px auto',
    color: 'white',
    textShadow: '0 0 6px rgba(0, 0, 0, 0.5)'
  }),
};
