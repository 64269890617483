import { useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { theme } from '../../shared/theme';
import { LiveRaceHeader } from '../shared/LiveRaceHeader';
import { EventType } from '../../utils/types';

import { useStyles } from './styles';
import { EventDetails, EventDetailsProps } from './EventDetails';
import { EventHeaderTabMenu, EventHeaderTabMenuProps } from './EventHeaderTabMenu';
import { HeroImage, HeroImageProps } from './HeroImage';

type PickedHeroImageProps = Pick<HeroImageProps, 'ctliveEventId'
  | 'isCustomHeroImage'
  | 'eventType'>;

type PickedEventDetailsProps = Pick<EventDetailsProps, 'actionButton'
  | 'eventLogoUrl'
  | 'masterName'>;

type PickedEventHeaderTabMenuProps = Pick<EventHeaderTabMenuProps, 'isRosterTabAvailable' | 'raceId'>;

type LiveRaceHeaderProps = {
  isLiveRaceHeaderVisible: boolean
  liveEventURL: string
}

type EventHeroProps = PickedHeroImageProps
  & PickedEventDetailsProps
  & PickedEventHeaderTabMenuProps
  & LiveRaceHeaderProps
  & {
  eventId: number;
  eventType: EventType;
  isLoading?: boolean;
  masterId: number;
}

export const EventHero = ({
  eventId,
  isLoading = false,
  masterId,
  // HeroImage
  ctliveEventId,
  eventType,
  isCustomHeroImage,
  // LiveRaceHeaderProps
  isLiveRaceHeaderVisible,
  liveEventURL,
  // EventDetails
  actionButton,
  eventLogoUrl,
  masterName,
  // EventHeaderTabMenu
  isRosterTabAvailable,
  raceId,
}: EventHeroProps) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles({});

  return (
    <>
      <HeroImage
        ctliveEventId={ctliveEventId}
        isCustomHeroImage={isCustomHeroImage}
        isLoading={isLoading}
        eventType={eventType}
      />

      <div className={styles.stickyContainer}>
        {isLiveRaceHeaderVisible &&
          <LiveRaceHeader
            liveEventURL={liveEventURL}
            isMobile={isMobile}
            isProfile={false}
            isMe={false}
            athlete={null}
            containerStyling={null}
            t={t}
          />
        }

        <EventDetails
          actionButton={actionButton}
          eventId={eventId}
          eventLogoUrl={eventLogoUrl}
          excludeSearch={true}
          isLoading={isLoading}
          master={{} as any}
          masterName={masterName}
          type='eventHeader'
        />

        <EventHeaderTabMenu
          eventId={eventId}
          isLoading={isLoading}
          isRosterTabAvailable={isRosterTabAvailable}
          masterId={masterId}
          raceId={raceId}
        />
      </div>
    </>
  );
};
