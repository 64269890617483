import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { configs } from '../configs'
import { extractSearchParams } from '../utils/extractSearchParams'
import {
  RaceLeaderboardResults,
  RbiResults,
  BibNameSearchResult,
  IrpResult,
  AzpEventLink,
  AzpEventEntry,
  RaceAwards,
  AzpEntryEventHistoryItem,
  AzpId,
  FavoriteMasterEventIds,
} from '../types.results.reignite'
import { RosterEntry } from '../types.rosters'
import { EventMetadata, MasterEventMetadata } from '../types.metadata.reignite'

export const reigniteApi = createApi({
  reducerPath: 'reigniteApi',
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: configs.athleteApiUrl,
      prepareHeaders: (headers) => {
        const token = (window as any).kc?.token;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
      },
    }),
    {
      maxRetries: configs.rtkQueryMaxRetries
    }
  ),
  tagTypes: ['Favorites'],
  endpoints: (builder) => ({
    getEventMetadata: builder.query<EventMetadata, number>({
      query: (
        athlinksEventId,
      ) => `/event/${athlinksEventId}/metadata`
    }),
    getMasterEventMetadata: builder.query<MasterEventMetadata, number>({
      query: (masterEventId) => `/master/${masterEventId}/metadata`
    }),
    getEventWideLeaderboardResults: builder.query<RaceLeaderboardResults[], {
      eventCourseId?: number
      eventId: number
      from: number
      limit: number
    }>({
      query: ({
        eventId,
        ...params
      }) => `/event/${eventId}/results?${extractSearchParams(params)}`
    }),
    getIndividualResult: builder.query<IrpResult, {
      bib?: string | number
      entryId?: AzpId
      eventCourseId: number
      eventId: number
    }>({
      query: ({
        bib,
        entryId,
        eventCourseId,
        eventId,
      }) => bib
          ? `/event/${eventId}/race/${eventCourseId}/bib/${bib}/result`
          : `/event/${eventId}/race/${eventCourseId}/entry/${entryId}/result`
    }),
    getRaceAwards: builder.query<RaceAwards, {
      eventCourseId: number
      eventId: number
      divisionId?: number
    }>({
      query: ({
        eventCourseId,
        eventId,
        divisionId
      }) => divisionId
        ? `/event/${eventId}/race/${eventCourseId}/division/${divisionId}/awards`
        : `/event/${eventId}/race/${eventCourseId}/awards`
    }),
    getRaceLeaderboardResults: builder.query<RaceLeaderboardResults, {
      divisionId?: number
      eventCourseId: number
      eventId: number
      from: number
      limit: number
    }>({
      query: ({
        divisionId,
        eventCourseId,
        eventId,
        ...params
      }) => divisionId
          ? `/event/${eventId}/race/${eventCourseId}/division/${divisionId}/results?${extractSearchParams(params)}`
          : `/event/${eventId}/race/${eventCourseId}/results?${extractSearchParams(params)}`
    }),
    getRaceDivisionIntervalResults: builder.query<RbiResults, {
      divisionId: number
      eventCourseId: number
      eventId: number
      intervalId: number
      from: number
      limit: number
    }>({
      query: ({
        divisionId,
        eventCourseId,
        eventId,
        intervalId,
        ...params
      }) => `/event/${eventId}/race/${eventCourseId}/division/${divisionId}/interval/${intervalId}/results?${extractSearchParams(params)}`
    }),
    getResultsBibNameSearch: builder.query<BibNameSearchResult[], {
      eventId: number
      from: number
      limit: number
      term?: string
    }>({
      query: ({
        eventId,
        ...params
      }) => `/event/${eventId}/results/search?${extractSearchParams(params)}`
    }),
    getRoster: builder.query<{
      page: number
      pageSize: number
      results: RosterEntry[]
      total: number
    }, {
      eventCourseId: number
      divisionId?: number
      page: number
      pageSize: number
      term?: string
    }>({
      query: ({
        eventCourseId,
        divisionId,
        ...params
      }: {
        eventCourseId: number
        divisionId?: number
        page: number
        pageSize: number
        term?: string
      }) => divisionId
        ? `/race/${eventCourseId}/division/${divisionId}/roster?${extractSearchParams(params)}`
        : `/race/${eventCourseId}/roster?${extractSearchParams(params)}`,
    }),
    getAzpEventLink: builder.query<AzpEventLink, {
      azp: string
      azpEventId: AzpId
    }>({
      query: ({
        azp,
        azpEventId,
      }) => `/azp/${azp}/event/${azpEventId}/link`
    }),
    getAzpEventEntry: builder.query<AzpEventEntry, {
      azp: string
      azpEventId: AzpId
      azpEntryId: AzpId
    }>({
      query: ({
        azp,
        azpEventId,
        azpEntryId,
      }) => `/azp/${azp}/event/${azpEventId}/entry/${azpEntryId}`
    }),
    getAzpEntryEventHistory: builder.query<{ events: AzpEntryEventHistoryItem[] }, {
      azp: string
      azpEntryId: string | number
    }>({
      query: ({
        azp,
        azpEntryId,
      }) => `/azp/${azp}/entry/${azpEntryId}/event-history`
    }),
    getFavorites: builder.query<FavoriteMasterEventIds, void>({
      query: () => '/favorites',
      transformResponse: (response: { masterEventId: number }[]) => {
        return response.map(item => item.masterEventId);},
      providesTags: ['Favorites'],
    }),
    addFavorite: builder.mutation<void, number>({
      query: (
        masterEventId
      ) => ({
        url: `/favorites/master/${masterEventId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Favorites'],
      async onQueryStarted(masterEventId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          reigniteApi.util.updateQueryData(
            'getFavorites',
            undefined,
            (draft) => {
              if (!draft.includes(masterEventId)) {
                draft.push(masterEventId)
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    removeFavorite: builder.mutation<void, number>({
      query: (
        masterEventId
      ) => ({
        url: `/favorites/master/${masterEventId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Favorites'],
      async onQueryStarted(masterEventId, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          reigniteApi.util.updateQueryData(
            'getFavorites',
            undefined,
            (draft) => {
              const index = draft.indexOf(masterEventId)
              if (index !== -1) draft.splice(index, 1)
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
  }),
})

export const {
  useGetRaceAwardsQuery,
  useGetEventMetadataQuery,
  useGetMasterEventMetadataQuery,
  useGetEventWideLeaderboardResultsQuery,
  useGetIndividualResultQuery,
  useGetRaceDivisionIntervalResultsQuery,
  useGetRaceLeaderboardResultsQuery,
  useGetResultsBibNameSearchQuery,
  useGetRosterQuery,
  useGetAzpEventLinkQuery,
  useGetAzpEventEntryQuery,
  useGetAzpEntryEventHistoryQuery,
  useGetFavoritesQuery,
  useAddFavoriteMutation,
  useRemoveFavoriteMutation,
} = reigniteApi