import { makeStyles } from "@material-ui/core";

import { colors, fonts } from "../../shared/styles";

const actionButtonLinkBase = {
  padding: '8px 16px', 
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.4px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
} as const;

const useStyles = makeStyles(({ palette }) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    gap: '8px',
  },
  registerButton: {
    ...actionButtonLinkBase,
    backgroundColor: palette.brandColor,
    border: 'none',
    borderRadius: '2px',
    color: colors.white,
    fontWeight: 600,
    lineHeight: '16px',
    textAlign: 'center',
  },
  learnMoreButton: {
    ...actionButtonLinkBase,
    border: `1px solid ${palette.grey[400]}`,
    borderRadius: '4px',
    color: palette.grey[600],
    transition: 'all 0.2s ease',

    '&:hover': {
      borderColor: palette.common.black,
      color: palette.common.black,
    },
  },
  buttonNote: {
    fontSize: '12px',
    lineHeight: '14px',
    textWrap: 'nowrap',
    textTransform: 'none',
    ...fonts.default,
  },
}))

export { useStyles }