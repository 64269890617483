import { useGetEventMetadataQuery as useGetReigniteEventMetadataQuery } from '../api/reigniteApi'
import { useGetEventMetadataQuery as useGetResultsEventMetadataQuery } from '../api/resultsApi'
import useReigniteMetadata from '../lib/firebase/useReigniteMetadata'
import { IntervalRaceType } from '../types.results'
import { EventMetadata, IntervalMetadata } from '../utils/types'

export default function useEventMetadata(athlinksEventId: number | null) {
  const { isFetchingFirebaseConfig, isReigniteMetadataAvailable } = useReigniteMetadata()

  const DEFAULT_EVENT_METADATA = {
    eventStartDateTime: 1538336496000, // Sunday, September 30, 2018 7:41:36 PM
  } as unknown as EventMetadata
  const {
    data: resultsMetadata = DEFAULT_EVENT_METADATA,
    isError: isResultsError,
    isFetching: isResultsFetching,
  } = useGetResultsEventMetadataQuery(athlinksEventId ?? -1, {
    skip: !athlinksEventId || isFetchingFirebaseConfig || isReigniteMetadataAvailable,
  })

  const {
    data: reigniteMetadata,
    isError: isReigniteError,
    isFetching: isReigniteFetching,
  } = useGetReigniteEventMetadataQuery(athlinksEventId ?? -1, {
    skip: !athlinksEventId ||  isFetchingFirebaseConfig ||!isReigniteMetadataAvailable,
  })

  if (isFetchingFirebaseConfig) {
    return {
      data: {} as EventMetadata,
      error: false,
      fetching: true,
      isLoading: true,
    }
  }

  const data: EventMetadata = isReigniteMetadataAvailable
    ? !reigniteMetadata
      ? DEFAULT_EVENT_METADATA
      : {
        azp: reigniteMetadata.azp,
        azpEventId: reigniteMetadata.azpEventId,
        eventCourseMetadata: reigniteMetadata.races.filter(x => !x.hidden).map(race => ({
          courseId: 0,
          distance: race.distance.meters,
          distanceUnit: race.distance.units,
          eventCourseEndDateTime: {
            timeInMillis: race.end.epoch,
            timeZone: race.end.timeZone,
          },
          eventCourseId: race.id,
          eventCourseName: race.name,
          eventCourseStartDateTime: {
            timeInMillis: race.start.epoch,
            timeZone: race.start.timeZone,
          },
          isVirtual: race.virtual,
          maxAllowedTicks: 0,
          metadata: {
            divisions: race.divisions.map(division => ({
              id: division.id as number,
              name: division.name,
              awards: division.awards,
            })),
            intervals: race.intervals.map<IntervalMetadata>((interval, displayOrder) => ({
              displayOrder,
              distance: interval.distance.meters,
              distanceUnit: interval.distance.units,
              intervalType: interval.type,
              isFull: Math.ceil(interval.distance.meters) === Math.ceil(race.distance.meters),
              id: interval.id as number,
              name: interval.name,
              paceUnits: interval.paceUnits
            }))
          },
          minAllowedTicks: 0,
          raceType: race.type as IntervalRaceType,
        })),
        eventEndDateTime: {
          timeInMillis: reigniteMetadata.end.epoch,
          timeZone: reigniteMetadata.end.timeZone,
        },
        eventId: reigniteMetadata.id,
        eventName: reigniteMetadata.name,
        registration: reigniteMetadata.registration,
        eventStartDateTime: {
          timeInMillis: reigniteMetadata.start.epoch,
          timeZone: reigniteMetadata.start.timeZone,
        },
        eventStatus: -1,
        eventType: reigniteMetadata.eventType,
        location: reigniteMetadata.location,
        logoUrl: reigniteMetadata.logoUrl,
      }
    : resultsMetadata

  const error = isReigniteMetadataAvailable
    ? isReigniteError
    : isResultsError

  const fetching = isReigniteMetadataAvailable
    ? isReigniteFetching
    : isResultsFetching

  return {
    data,
    error,
    fetching,
    isLoading: fetching,
  }
}
