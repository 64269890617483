import { configs } from '../configs'

export class KeycloakEvent extends Event {
  data = null
  constructor(msg, data) {
    super(msg)
    this.data = data
  }
}

export default function initKeycloak() {
  if (window.kc) return window.kc;
  if (!window.Keycloak) {
    throw new Error('keycloak.js is not yet loaded')
  }

  const keycloakConfig = {
    'realm': configs.keycloakRealm,
    'auth-server-url': configs.keycloakUrl + '/auth',
    'url': configs.keycloakUrl + '/auth',
    'resource': configs.keycloakClientId,
    'clientId': configs.keycloakClientId,
    'ssl-required': 'external',
    'public-client': true,
    'verify-token-audience': true,
    'use-resource-role-mappings': true,
    'confidential-port': 0,
  };

  const kc = new window.Keycloak(keycloakConfig);
  kc.onAuthSuccess = function (x) {
    var event = new KeycloakEvent('keycloakStateUpdate');
    event.data = x;
    window.dispatchEvent(event);
  };
  kc.onAuthRefreshSuccess = function (x) {
    var event = new KeycloakEvent('keycloakStateUpdate', x);
    window.dispatchEvent(event);
  };
  kc.onAuthLogout = function (x) {
    var event = new KeycloakEvent('keycloakStateUpdate', x);
    window.dispatchEvent(event);
  };
  kc.onAuthError = function (x) {
    var event = new KeycloakEvent('keycloakInitError', x);
    window.dispatchEvent(event);
  }

  window.kc = kc

  window.addEventListener('load', () => {
    window.kc.init({
      onLoad: 'check-sso',
      promiseType: 'native',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    })
      .then((x) => {
        kc.initialized = true;
        var event = new KeycloakEvent('keycloakStateUpdate', x);
        window.dispatchEvent(event);
      })
      .catch((e) => {
        kc.initialized = true;
        var event = new KeycloakEvent('keycloakInitError', e);
        window.dispatchEvent(event);
      });
  })

  return window.kc
}