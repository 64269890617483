const getNumberWithSuffix = (num: number) => {
  if (typeof num !== 'number' || !Number.isInteger(num)) {
    throw new Error('Input must be an integer.');
  }

  const lastDigit = num % 10;
  const lastTwoDigits = num % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
    return `${num}th`;
  }

  switch (lastDigit) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
}

export { getNumberWithSuffix }
