import React from 'react'
import { getTimeLabel } from '../../utils/IRPUtil'
import { BracketInfo } from './BracketInfo'
import { makeStyles, Theme } from '@material-ui/core'
import { colors, fonts } from '../../shared/styles'
import { useTranslation } from 'react-i18next'
import { BracketType, CtLiveApiStatus } from '../../utils/types'
import { Result } from './IndividualKioskResultsContent'
import isOnCourse from '../../utils/isOnCourse'
import hasNoResult from '../../utils/hasNoResult'

type Bracket = {
  rank: number
  name: string
  totalAthletes: number
  totalInBracket: number
  bracketType: BracketType
}

type KioskTimePaceProps = {
  finishTime: string
  hasPace: boolean
  isLightMode: boolean
  isGunTime: boolean
  isMobile: boolean
  pace: string | number
  paceUnit: string
  ranks: Bracket[]
  result: Result & { isFinisher:  boolean }
  latestIntervalName: string
}

const useStyles = makeStyles<Theme, { isLightMode: boolean,  isMobile: boolean }>(() => ({
  notFinisher: ({ isLightMode }) => ({
    ...fonts.default,
    fontSize: 45,
    lineHeight: 1.2,
    textTransform: 'uppercase',
    color: isLightMode ? colors.darkType : colors.white
  }),
  timePaceNumbers: ({ isMobile, isLightMode }) => ({
    fontFamily: 'LatoBold',
    fontSize: isMobile ? 64 : 70,
    lineHeight: 1.2,
    color: isLightMode ? colors.darkType : colors.white
  }),
  timePaceHeader: ({ isLightMode }) => ({
    color: isLightMode ? colors.grey : colors.lightBlue9,
    textTransform: 'uppercase',
    fontFamily: 'ProximaNovaBold',
    fontSize: 14,
    lineHeight: '1rem',
    letterSpacing: 0.5,
    marginBottom: 0,
    textAlign: 'center',
    fontWeight: 'bold',
    maxWidth: 'auto',
    marginTop: 0
  }),
  timePaceItemContainer: {
    textAlign: 'center'
  },
  timePaceCenterContent: {
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap'
  },
  onCourse: {
    margin: '30px',
    fontSize: '40px',
    display: 'inline-block',
    backgroundColor: '#ffaa0d',
    color: 'white',
    padding: '5px 40px',
    borderRadius: 40
  },
  noResult: {
    margin: '30px',
    fontSize: '40px',
    display: 'inline-block',
    backgroundColor: '#787777',
    color: 'white',
    padding: '5px 40px',
    borderRadius: 40
  }
}))

export const NotFinisher = ({ status, t } : { status: CtLiveApiStatus, t:any }) => {
  switch (status) {
    case 'DNF':
      return <span>{t('did not finish')}</span>
    case 'DQ':
    default:
      return <span>{t('disqualified')}</span>
  }
}

export function KioskTimePace({
  finishTime,
  hasPace,
  isLightMode,
  isGunTime,
  isMobile,
  pace,
  paceUnit,
  ranks,
  result,
  latestIntervalName
}: KioskTimePaceProps) {
  const styles = useStyles({ isLightMode, isMobile })
  const { t } = useTranslation()
  const onCourse = isOnCourse(result)
  const noResult = hasNoResult(result)
  return (
    (
      <div>
        <div className={`row ${styles.timePaceCenterContent}`}>
          <div className='col-sm-12 col-md-8 col-lg-7'>
            {
              !result.isFinisher &&
                <div className={styles.notFinisher}>
                  <NotFinisher
                    status={result.status}
                    t={t}
                  />
                </div>
            }
            {
              result.isFinisher && !noResult &&
                <div className='row mb-4'>
                  <div className={`${hasPace ? 'col-7' : 'col-md-12'} ${styles.timePaceItemContainer}`}>
                    <h4 className={styles.timePaceHeader}>
                      {`${getTimeLabel(result, isGunTime, t)} ${latestIntervalName}`}
                    </h4>
                    <div className={styles.timePaceNumbers}>
                      {finishTime}
                    </div>
                  </div>
                  {
                    hasPace && <div className={`col-5 ${styles.timePaceItemContainer}`}>
                      <h4 className={styles.timePaceHeader}>
                        {t('Pace {{unit}}', { unit: paceUnit })}
                      </h4>
                      <div className={styles.timePaceNumbers}>
                        {pace}
                      </div>
                    </div>
                  }
                </div>
            }
          </div>
        </div>
        {
          result.isFinisher &&
            <div className={`row ${styles.timePaceCenterContent}`}>
              <div
                className='col-12'
                style={{ marginTop: -16, padding: 0 }}>
                {
                  noResult
                    ? <div style={{ textAlign: 'center' }}>
                        <div className={styles.noResult}>{t('No Result')}</div>
                      </div>
                    : onCourse
                      ? <div style={{ textAlign: 'center' }}>
                          <div className={styles.onCourse}>{t('On Course')}</div>
                        </div>
                      : <BracketInfo
                          isLightMode={isLightMode}
                          brackets={ranks}
                          isMobile={isMobile}
                        />
                }
              </div>
            </div>
        }
      </div>
    )
  )
}
