import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {ClaimModal, UnclaimModal, propTypes} from '../modal/Claim';
import {LogInOrSignUp} from '../modal/LogInOrSignUp';
import {getTokenRacerId} from '../../utils/isLoggedIn';
import { trackGoogleEvent } from '../../utils/googleEvents';
import { claimResult } from './styles';
import { ClaimStatus } from '../../data/UnclaimedSearchStreams';

class ClaimResultComponent extends Component {
  static propTypes = {
    claimOverride: PropTypes.bool,
    raceResultToggled: PropTypes.func,
    disabled: PropTypes.bool,
    t: PropTypes.func
  };

  static defaultProps = {
    claimOverride: false,
    isStickyButton: false,
    disabled: false,
  };

  state = {
    open: false,
    showButton: true
  };

  /**
   * Open modal handler
   */
  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  /**
   * Close modal handler
   */
  handleClose = () => {
    this.setState({
      open: false
    });

    if(this.props.closeHandler) {
      this.props.closeHandler();
    }
  };

  /**
   * Tourch tap handler. Open sign in modal if user is not signed in
   */
  onTouchTap = () => {
    trackGoogleEvent(
      'Claim',
      'Click',
      this.state.isClaimed ? 'Unclaim Result' : 'Claim Result'
    );
    this.props.isLoggedIn
      ? this.handleOpen()
      : this.props.modal.handleTouchTap();

    if(this.props.clickHandler) {
      this.props.clickHandler();
    }
  };

  /**
   * Actions taken after (un)claim
   * @param isClaimed
   * @param isMergeRequest
   */
  afterClaim = (isClaimed, isMergeRequest) => {
    this.setState({
        open: false,
        showButton: !this.props.isStickyButton
      },
      () => {
        if(this.props.onClaimCallback) {
          this.props.onClaimCallback(isClaimed, isMergeRequest)
        }
        if(this.props.componentCallback) {
          this.props.componentCallback(isClaimed)
        }
      });
  };

  /**
   * Check if claim/unclaim button can be clicked.
   * Button cannot be clicked if result is claimed by different person
   * @return {boolean}
   */
  canClick = () => {
    const {
      racerId,
      claimStatus,
      isClaimed,
      alreadyClaimedInCourse,
      isLoggedIn
    } = this.props;

    const myRacerId = getTokenRacerId();
    const isMine = racerId === myRacerId;

    return isMine ||
      (!isLoggedIn && !isClaimed) ||
      (!alreadyClaimedInCourse && !isMine && !isClaimed) ||
      claimStatus === ClaimStatus.inReview ||
      claimStatus === ClaimStatus.pending
  };

  getClaimButtonLabel = () => {
    const {
      claimOverride,
      resultOnERP,
      isStickyButton,
      isUnclaimedSearch,
      claimStatus,
      isClaimed,
      t
    } = this.props;

    if(claimStatus === 'ClaimInReview' || claimStatus === 'ClaimPendingSync' || claimOverride) {
      return t('Claimed');
    }
    else if(resultOnERP) {
      return t('Claim');
    }
    else if(isClaimed) {
      return t('Claimed');
    }
    else if(isStickyButton) {
      return t('Claim this result');
    }
    else if(isUnclaimedSearch) {
      return t('Claim');
    }

    return t('Claim Result');
  };

  /**
   * Builds claim/unclaim button
   * @return {XML}
   */
  renderButton = () => {
    const {
      resultOnERP,
      isStickyButton,
      disabled,
      claimStatus,
      isClaimed,
      claimOverride
    } = this.props;

    const canClick = this.canClick();
    const claimed = claimStatus ?
      (claimStatus === 'Claimed' || claimStatus === 'ClaimInReview' || claimStatus === 'ClaimPendingSync') :
      isClaimed;
    const style = resultOnERP ? claimResult.onErpButton(canClick, claimStatus)
      : claimResult.defaultClaim(canClick, isStickyButton, disabled, claimed || claimOverride);

    return (
      <button
        style={style}
        disabled={!canClick}
        className={`claimed-${claimed}`}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          e.preventDefault();
          if(this.props.onClick && canClick) this.props.onClick();
          return canClick ? this.onTouchTap() : () => {}}
        }
      >
        {this.getClaimButtonLabel()}
      </button>
    );
  };

  /**
   * Renders claim modal
   * @return {XML}
   */
  renderClaimModal = () => {
    return (
      <ClaimModal
        {...this.props}
        open={this.state.open}
        handleClose={() => {
          if(this.props.actionsDone) this.props.actionsDone()
          this.handleClose()
        }}
        afterClaim={() => this.afterClaim(true)}
      />
    );
  };


  /**
   * Renders unclaim modal
   * @return {XML}
   */
  renderUnclaimModal = () =>
    <UnclaimModal
      {...this.props}
      open={this.state.open}
      handleClose={this.handleClose}
      afterUnclaim={() => this.afterClaim(false)}
    />;

  render() {
    const {
      claimStatus,
      isClaimed,
      claimOverride
    } = this.props;

    const {
      open
    } = this.state;

    if(!open && this.state.showButton) {
      return this.renderButton();
    }

    const canUnclaim = claimOverride || (!claimStatus && isClaimed) ||
      claimStatus === 'Claimed' ||
      claimStatus === 'ClaimInReview' ||
      claimStatus === 'ClaimPendingSync';

    return canUnclaim ? this.renderUnclaimModal() : this.renderClaimModal();
  }
}

export const ClaimResult = withTranslation()(ClaimResultComponent);

export const ClaimResultButton = (props) =>
  <LogInOrSignUp control='claimResult' firstSlideType='claim-results' {...props} />;

ClaimResultButton.propTypes = propTypes;
