import { Typography, useMediaQuery } from "@material-ui/core"

import { configs } from "../../configs"
import { theme } from "../../shared/theme"

import { useWreathWithCaptionStyles } from "./styles"

type AwardWreathWithCaptionProps = {
  caption: string
  place: number | null
  size?: 'mobile' | 'desktop'
}

const AwardWreathWithCaption = ({
  caption,
  place,
  size,
}: AwardWreathWithCaptionProps) => {
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const isMobileView = size ? size === 'mobile' : isMobile

  const styles = useWreathWithCaptionStyles({
    isMobileViewOnly: size === 'mobile',
  })

  return (
    <div>
      <div className={styles.wreathContainer}>
        <img
          src={`${configs.cdnUrl}/svg/laurel_wreath_left${isMobileView ? '_mobile' : ''}.svg`}
          alt='Laurel Wreath Left Side'
        />

        <Typography className={styles.positionNumber}>
          { place }
        </Typography>

        <img
          src={`${configs.cdnUrl}/svg/laurel_wreath_left${isMobileView ? '_mobile' : ''}.svg`}
          alt='Laurel Wreath Right Side'
          className={styles.rightSideWreath}
        />
      </div>

      <div className={styles.ribbonContainer}>
        <img
          src={`${configs.cdnUrl}/svg/label_ribbon${isMobileView ? '_mobile' : ''}.svg`}
          alt='Label Ribbon'
        />

        <Typography className={styles.ribbonText}>
          {caption}
        </Typography>
      </div>
    </div>
  )
}

export { AwardWreathWithCaption }