import { configs } from '../configs';

const fbAppId = `app_id=${configs.fbAppId}`;

const shareContent = (url, isMobile) => {
  const shareUrl = `https://www.facebook.com/dialog/feed?${fbAppId}&display=popup&link=${url}`
  const winOpts = isMobile ? '' : 'height=600px,width=555px';

  window.open(shareUrl, 'Facebook', winOpts);
};

export default shareContent;
