import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { HeroImage } from '../../heroImage/HeroImage';
import { styles } from './styles';
//Note: components/shared/Carousel clones slides, so avoid doing anything stateful here

export class CuratedEventCard extends Component {
  static propTypes = {
    event: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
  };

  render() {
    const {
      event,
      isActive,
      idx,
      isMobile
    } = this.props;

    const { curatedEventCard } = styles;

    return (
      <div style={curatedEventCard.content(isMobile)}>
        <div style={curatedEventCard.heroContainer(isActive)}
             id={`${event.name} ${isActive ? 'active' : 'not-active'}`}
        >
          <HeroImage
            heroHeight='100%'
            idx={idx}
            type={event.eventType}
            ctliveEventId={event.ctliveId}
            isCustomHeroImage={event.isPublished && event.hasHeroImage}
            isCuratedEvent={true}
          />
        </div>

        {!isActive && !isMobile &&
        <div>
          <div style={curatedEventCard.event}>
            <div style={curatedEventCard.city}>
              {event.city}{/*{', '}{event.region}*/}
            </div>
          </div>
          <div style={curatedEventCard.lightInfo}>
            {event.name}
          </div>
        </div>
        }

      </div>
    );
  }
}
