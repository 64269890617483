import { About } from './About';
import { Advertise } from './Advertise';
import { CCPA } from './CCPA';
import { Privacy } from './Privacy';
import { Terms } from './Terms';
import { Accessibility } from './Accessibility';
import { GDPR } from './GDPR';
import { MHMD } from './MHMD'

const DocTypes = {
  about: About,
  advertise: Advertise,
  ccpa: CCPA,
  mhmd: MHMD,
  privacy: Privacy,
  terms: Terms,
  accessibility: Accessibility,
  gdpr: GDPR
};

export default DocTypes;