import { Box, Typography } from "@material-ui/core"
import moment from "moment-timezone"

import { getDisplayLocation } from "../../utils/location"
import { EventLocationMetadata, EventMetadata } from "../../utils/types"

import { useEventCardStyles, useSharedStyles } from "./styles"
import { SkeletonPlaceholder } from "./SkeletonPlaceholder"

type EventCardProps = {
  event: EventMetadata | null
  isLoadingEvent: boolean
}

const parseAndFormatLocation = (location: EventLocationMetadata | null) => {
  const address = location?.address

  if (!address) {
    return null
  }

  const countryCode = address?.regionId?.split('-')?.[0] || 'USA'

  const [city, state, country] = getDisplayLocation(address?.city, address?.region, countryCode)?.split(', ')

  return `${city}, ${state} ${country}`
}

const EventCard = ({ event, isLoadingEvent }: EventCardProps) => {
  const styles = useEventCardStyles()
  const sharedStyles = useSharedStyles()

  if (isLoadingEvent) {
    return <SkeletonPlaceholder />
  }

  if (!event || !event.eventName) {
    return null
  }

  const location = parseAndFormatLocation(event.location)

  const startDate = moment(event.eventStartDateTime.timeInMillis).tz(event.eventStartDateTime.timeZone)
  const endDate = moment(event.eventEndDateTime.timeInMillis).tz(event.eventEndDateTime.timeZone)

  return (
    <Box className={sharedStyles.paper}>
      <Typography className={`${sharedStyles.title} ${styles.title}`}>
        {event.eventName}
      </Typography>

      <Box className={styles.detailsContainer}>
        <Typography className={styles.dateDetails}>
          {startDate.format('MMMM DD, YYYY')}{endDate.diff(startDate, 'days') > 0 && ` to ${endDate.format('MMMM DD, YYYY')}`}
        </Typography>

        {!!location && (
          <>
            <Typography className={`${styles.dateDetails} ${styles.detailsDivider}`}>|</Typography>

            <Typography className={styles.locationDetails}>{location}</Typography>
          </>
        )}
      </Box>
    </Box>
  )
}

export { EventCard }