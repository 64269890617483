import React, { useState, useEffect } from 'react'
import LightDarkBubble from '../../json/bubble_lightdark.json'
import { BracketBubble } from './BracketBubble'
import { makeStyles, Theme } from '@material-ui/core'
import { colors } from '../../shared/styles'

type BracketRowProps = {
  label: string
  value: number
  count: number
  isLightMode: boolean
}

const useStyles = makeStyles<Theme, { isLightMode: boolean }>(() => ({
  timePaceHeader: ({ isLightMode }) => ({
    color: isLightMode ? colors.grey : colors.lightBlue9,
    textTransform: 'uppercase',
    fontFamily: 'ProximaNovaBold',
    fontSize: 14,
    lineHeight: '1rem',
    letterSpacing: 0.5,
    marginBottom: 0,
    textAlign: 'center',
    fontWeight: 'bold',
    maxWidth: 110,
    marginTop: 0
  }),
  timePaceRank: ({ isLightMode }) => ({
    color: isLightMode ? colors.darkType : colors.white,
    fontSize: 28,
    letterSpacing: -.5,
    fontFamily: 'LatoBold',
    lineHeight: 1.2
  }),
  timePaceRankTotal: ({ isLightMode }) => ({
    color: isLightMode ? colors.grey : colors.lightBlue9,
    fontFamily: 'LatoBold',
    lineHeight: 1.2,
    fontSize: 16
  }),
  timePaceItemContainer: {
    textAlign: 'center'
  },
  timePaceWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  paceRankWrap: {
    transform: `translate(-50%, -50%)`,
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 7
  }
}))

const calculateEndSegment = (value: number, count: number) => {
  const endValue = Number((100 - ((100 * value) / count)).toFixed(1))
  if (value === 1) return 100
  /*
    If endSegment is < 1 but not 0 -> [0, 0.78] Lottie won't display at all
    So setting it to minimal value of 2 for displaying minimal count as in mobile app
  */
  if (endValue < 1 && endValue !== 0) return 2
  /*
    When direction is set to 1,
    [0,0] Lottie will render full animation, so setting it
    to 1 to get needed result
  */
  if (endValue === 0) return 1

  return endValue
}

export function BracketRow({ value, count, label, isLightMode } : BracketRowProps) {

  const styles = useStyles({isLightMode})
  const [endSegment, setEndSegment] = useState(0)
  const startSegment = 0

  useEffect(() => {
    const newEndSegment = calculateEndSegment(value, count)
    setEndSegment(newEndSegment)
  }, [value, count])

  return (
    <div className={styles.timePaceWrap}>
      <div style={{ position: 'relative' }}>
        <BracketBubble
          animation={LightDarkBubble}
          width={130}
          startSegment={startSegment}
          endSegment={endSegment}
        />
        <div className={styles.paceRankWrap}>
          <span className={styles.timePaceRank}>{value.toLocaleString() || '--'}</span>
          <span className={styles.timePaceRankTotal}>{count.toLocaleString()}</span>
        </div>
      </div>
      <h4 className={styles.timePaceHeader}>
        <span>{label.toLowerCase()}</span>
      </h4>
    </div>
  )
}
